/* import __COLOCATED_TEMPLATE__ from './d-navigation-item.hbs'; */
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { makeArray } from "discourse-common/lib/helpers";
import TEMPLATE from "./d-navigation-item.hbs";
import { setComponentTemplate } from "@ember/component";
export default class DNavigationItem extends Component {
  static #_ = dt7948.g(this.prototype, "router", [service]);
  #router = (dt7948.i(this, "router"), void 0);
  get ariaCurrent() {
    // when there are multiple levels of navigation
    // we want the active parent to get `aria-current="page"`
    // and the active child to get `aria-current="location"`
    if (this.args.ariaCurrentContext === "parentNav" && this.router.currentRouteName !== this.args.route &&
    // not the current route
    this.router.currentRoute.parent.name.includes(this.args.route) // but is the current parent route
    ) {
      return "page";
    }
    if (this.router.currentRouteName !== this.args.route) {
      return null;
    }
    if (this.args.ariaCurrentContext === "subNav") {
      return "location";
    } else {
      return "page";
    }
  }
  get models() {
    return makeArray(this.args.models || this.args.model);
  }
}
setComponentTemplate(TEMPLATE, DNavigationItem);