/* import __COLOCATED_TEMPLATE__ from './ai-summary-skeleton.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { cancel } from "@ember/runloop";
import discourseLater from "discourse-common/lib/later";
import TEMPLATE from "./ai-summary-skeleton.hbs";
import { setComponentTemplate } from "@ember/component";
class Block {
  static #_ = dt7948.g(this.prototype, "show", [tracked], function () {
    return false;
  });
  #show = (dt7948.i(this, "show"), void 0);
  static #_2 = dt7948.g(this.prototype, "shown", [tracked], function () {
    return false;
  });
  #shown = (dt7948.i(this, "shown"), void 0);
  static #_3 = dt7948.g(this.prototype, "blinking", [tracked], function () {
    return false;
  });
  #blinking = (dt7948.i(this, "blinking"), void 0);
  constructor() {
    let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    this.show = args.show ?? false;
    this.shown = args.shown ?? false;
  }
}
const BLOCKS_SIZE = 20; // changing this requires to change css accordingly

export default class AiSummarySkeleton extends Component {
  blocks = [...Array.from({
    length: BLOCKS_SIZE
  }, () => new Block())];
  #nextBlockBlinkingTimer;
  #blockBlinkingTimer;
  #blockShownTimer;
  setupAnimation() {
    this.blocks.firstObject.show = true;
    this.blocks.firstObject.shown = true;
  }
  static #_ = dt7948.n(this.prototype, "setupAnimation", [action]);
  onBlinking(block) {
    if (!block.blinking) {
      return;
    }
    block.show = false;
    this.#nextBlockBlinkingTimer = discourseLater(this, () => {
      this.#nextBlock(block).blinking = true;
    }, 250);
    this.#blockBlinkingTimer = discourseLater(this, () => {
      block.blinking = false;
    }, 500);
  }
  static #_2 = dt7948.n(this.prototype, "onBlinking", [action]);
  onShowing(block) {
    if (!block.show) {
      return;
    }
    this.#blockShownTimer = discourseLater(this, () => {
      this.#nextBlock(block).show = true;
      this.#nextBlock(block).shown = true;
      if (this.blocks.lastObject === block) {
        this.blocks.firstObject.blinking = true;
      }
    }, 250);
  }
  static #_3 = dt7948.n(this.prototype, "onShowing", [action]);
  teardownAnimation() {
    cancel(this.#blockShownTimer);
    cancel(this.#nextBlockBlinkingTimer);
    cancel(this.#blockBlinkingTimer);
  }
  static #_4 = dt7948.n(this.prototype, "teardownAnimation", [action]);
  #nextBlock(currentBlock) {
    if (currentBlock === this.blocks.lastObject) {
      return this.blocks.firstObject;
    } else {
      return this.blocks.objectAt(this.blocks.indexOf(currentBlock) + 1);
    }
  }
}
setComponentTemplate(TEMPLATE, AiSummarySkeleton);