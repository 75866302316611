/* import __COLOCATED_TEMPLATE__ from './search-menu-panel.hbs'; */
import Component from "@glimmer/component";
import { service } from "@ember/service";
import TEMPLATE from "./search-menu-panel.hbs";
import { setComponentTemplate } from "@ember/component";
export default class SearchMenuPanel extends Component {
  static #_ = dt7948.g(this.prototype, "site", [service]);
  #site = (dt7948.i(this, "site"), void 0);
  get animationClass() {
    return this.site.mobileView || this.site.narrowDesktopView ? "slide-in" : "drop-down";
  }
}
setComponentTemplate(TEMPLATE, SearchMenuPanel);