/* import __COLOCATED_TEMPLATE__ from './activation-edit.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { extractError } from "discourse/lib/ajax-error";
import { changeEmail } from "discourse/lib/user-activation";
import ActivationResent from "./activation-resent";
import TEMPLATE from "./activation-edit.hbs";
import { setComponentTemplate } from "@ember/component";
export default class ActivationEdit extends Component {
  static #_ = dt7948.g(this.prototype, "login", [service]);
  #login = (dt7948.i(this, "login"), void 0);
  static #_2 = dt7948.g(this.prototype, "modal", [service]);
  #modal = (dt7948.i(this, "modal"), void 0);
  static #_3 = dt7948.g(this.prototype, "newEmail", [tracked], function () {
    return this.args.model.newEmail;
  });
  #newEmail = (dt7948.i(this, "newEmail"), void 0);
  static #_4 = dt7948.g(this.prototype, "flash", [tracked]);
  #flash = (dt7948.i(this, "flash"), void 0);
  get submitDisabled() {
    return this.newEmail === this.args.model.currentEmail;
  }
  async changeEmail() {
    try {
      await changeEmail({
        username: this.login?.loginName,
        password: this.login?.loginPassword,
        email: this.newEmail
      });
      this.modal.show(ActivationResent, {
        model: {
          currentEmail: this.newEmail
        }
      });
    } catch (e) {
      this.flash = extractError(e);
    }
  }
  static #_5 = dt7948.n(this.prototype, "changeEmail", [action]);
  updateNewEmail(email) {
    this.newEmail = email;
  }
  static #_6 = dt7948.n(this.prototype, "updateNewEmail", [action]);
}
setComponentTemplate(TEMPLATE, ActivationEdit);