/* import __COLOCATED_TEMPLATE__ from './dismiss-notification-confirmation.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import TEMPLATE from "./dismiss-notification-confirmation.hbs";
import { setComponentTemplate } from "@ember/component";
export default class DismissNotificationConfirmation extends Component {
  dismiss() {
    this.args.model?.dismissNotifications?.();
    this.args.closeModal();
  }
  static #_ = dt7948.n(this.prototype, "dismiss", [action]);
}
setComponentTemplate(TEMPLATE, DismissNotificationConfirmation);