/* import __COLOCATED_TEMPLATE__ from './reviewable-queued-post.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import RawEmailModal from "discourse/components/modal/raw-email";
import TEMPLATE from "./reviewable-queued-post.hbs";
import { setComponentTemplate } from "@ember/component";
export default class ReviewableQueuedPost extends Component {
  static #_ = dt7948.g(this.prototype, "modal", [service]);
  #modal = (dt7948.i(this, "modal"), void 0);
  static #_2 = dt7948.g(this.prototype, "isCollapsed", [tracked], function () {
    return false;
  });
  #isCollapsed = (dt7948.i(this, "isCollapsed"), void 0);
  static #_3 = dt7948.g(this.prototype, "isLongPost", [tracked], function () {
    return false;
  });
  #isLongPost = (dt7948.i(this, "isLongPost"), void 0);
  static #_4 = dt7948.g(this.prototype, "postBodyHeight", [tracked], function () {
    return 0;
  });
  #postBodyHeight = (dt7948.i(this, "postBodyHeight"), void 0);
  maxPostHeight = 300;
  showRawEmail(event) {
    event?.preventDefault();
    this.modal.show(RawEmailModal, {
      model: {
        rawEmail: this.args.reviewable.payload.raw_email
      }
    });
  }
  static #_5 = dt7948.n(this.prototype, "showRawEmail", [action]);
  toggleContent() {
    this.isCollapsed = !this.isCollapsed;
  }
  static #_6 = dt7948.n(this.prototype, "toggleContent", [action]);
  get collapseButtonProps() {
    if (this.isCollapsed) {
      return {
        label: "review.show_more",
        icon: "chevron-down"
      };
    }
    return {
      label: "review.show_less",
      icon: "chevron-up"
    };
  }
  setPostBodyHeight(offsetHeight) {
    this.postBodyHeight = offsetHeight;
    if (this.postBodyHeight > this.maxPostHeight) {
      this.isCollapsed = true;
      this.isLongPost = true;
    } else {
      this.isCollapsed = false;
      this.isLongPost = false;
    }
  }
  static #_7 = dt7948.n(this.prototype, "setPostBodyHeight", [action]);
}
setComponentTemplate(TEMPLATE, ReviewableQueuedPost);