import EmberObject from "@ember/object";
import { and } from "@ember/object/computed";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default EmberObject.extend(dt7948.p({
  sortable: null,
  ariaPressed: and("sortable", "isSorting"),
  localizedName() {
    if (this.forceName) {
      return this.forceName;
    }
    return this.name ? I18n.t(this.name) : "";
  },
  sortIcon() {
    const isAscending = this.parent.ascending || this.parent.context?.ascending;
    const asc = isAscending ? "up" : "down";
    return `chevron-${asc}`;
  },
  isSorting() {
    return this.sortable && (this.parent.order === this.order || this.parent.context?.order === this.order);
  },
  className() {
    const name = [];
    if (this.order) {
      name.push(this.order);
    }
    if (this.sortable) {
      name.push("sortable");
      if (this.isSorting) {
        name.push("sorting");
      }
    }
    if (this.number) {
      name.push("num");
    }
    return name.join(" ");
  },
  ariaSort() {
    if (this.isSorting) {
      return this.parent.ascending ? "ascending" : "descending";
    } else {
      return false;
    }
  }
}, [["method", "localizedName", [discourseComputed]], ["method", "sortIcon", [discourseComputed]], ["method", "isSorting", [discourseComputed]], ["method", "className", [discourseComputed]], ["method", "ariaSort", [discourseComputed]]]));