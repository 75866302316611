import Component from "@glimmer/component";
import { hash } from "@ember/helper";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { service } from "@ember/service";
import PluginOutlet from "discourse/components/plugin-outlet";
import concatClass from "discourse/helpers/concat-class";
import { wantsNewWindow } from "discourse/lib/intercept-click";
import DiscourseURL from "discourse/lib/url";
import icon from "discourse-common/helpers/d-icon";
import getURL from "discourse-common/lib/get-url";
import Logo from "./logo";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class HomeLogo extends Component {
  static #_ = dt7948.g(this.prototype, "session", [service]);
  #session = (dt7948.i(this, "session"), void 0);
  static #_2 = dt7948.g(this.prototype, "site", [service]);
  #site = (dt7948.i(this, "site"), void 0);
  static #_3 = dt7948.g(this.prototype, "siteSettings", [service]);
  #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
  href = getURL("/");
  darkModeAvailable = this.session.darkModeAvailable;
  get showMobileLogo() {
    return this.site.mobileView && this.logoResolver("mobile_logo").length > 0;
  }
  get logoUrl() {
    return this.logoResolver("logo");
  }
  get logoUrlDark() {
    return this.logoResolver("logo", {
      dark: this.darkModeAvailable
    });
  }
  get logoSmallUrl() {
    return this.logoResolver("logo_small");
  }
  get logoSmallUrlDark() {
    return this.logoResolver("logo_small", {
      dark: this.darkModeAvailable
    });
  }
  get mobileLogoUrl() {
    return this.logoResolver("mobile_logo");
  }
  get mobileLogoUrlDark() {
    return this.logoResolver("mobile_logo", {
      dark: this.darkModeAvailable
    });
  }
  logoResolver(name1) {
    let opts1 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    // get alternative logos for browser dark dark mode switching
    if (opts1.dark) {
      return this.siteSettings[`site_${name1}_dark_url`];
    }
    // try dark logos first when color scheme is dark
    // this is independent of browser dark mode
    // hence the fallback to normal logos
    if (this.session.defaultColorSchemeIsDark) {
      return this.siteSettings[`site_${name1}_dark_url`] || this.siteSettings[`site_${name1}_url`] || "";
    }
    return this.siteSettings[`site_${name1}_url`] || "";
  }
  click(e1) {
    if (wantsNewWindow(e1)) {
      return false;
    }
    e1.preventDefault();
    DiscourseURL.routeToTag(e1.target.closest("a"));
  }
  static #_4 = dt7948.n(this.prototype, "click", [action]);
  static #_5 = setComponentTemplate(createTemplateFactory(
  /*
    
      <PluginOutlet @name="home-logo" @outletArgs={{hash minimized=@minimized}}>
        <div class={{concatClass (if @minimized "title--minimized") "title"}}>
          <a href={{this.href}} {{on "click" this.click}}>
            {{#if @minimized}}
              {{#if this.logoSmallUrl}}
                <Logo
                  @key="logo-small"
                  @url={{this.logoSmallUrl}}
                  @title={{this.siteSettings.title}}
                  @darkUrl={{this.logoSmallUrlDark}}
                />
              {{else}}
                {{icon "home"}}
              {{/if}}
            {{else if this.showMobileLogo}}
              <Logo
                @key="logo-mobile"
                @url={{this.mobileLogoUrl}}
                @title={{this.siteSettings.title}}
                @darkUrl={{this.mobileLogoUrlDark}}
              />
            {{else if this.logoUrl}}
              <Logo
                @key="logo-big"
                @url={{this.logoUrl}}
                @title={{this.siteSettings.title}}
                @darkUrl={{this.logoUrlDark}}
              />
            {{else}}
              <h1 id="site-text-logo" class="text-logo">
                {{this.siteSettings.title}}
              </h1>
            {{/if}}
          </a>
        </div>
      </PluginOutlet>
    
  */
  {
    "id": "ipnvCza2",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@name\",\"@outletArgs\"],[\"home-logo\",[28,[32,1],null,[[\"minimized\"],[[30,1]]]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[15,0,[28,[32,2],[[52,[30,1],\"title--minimized\"],\"title\"],null]],[12],[1,\"\\n        \"],[11,3],[16,6,[30,0,[\"href\"]]],[4,[32,3],[\"click\",[30,0,[\"click\"]]],null],[12],[1,\"\\n\"],[41,[30,1],[[[41,[30,0,[\"logoSmallUrl\"]],[[[1,\"              \"],[8,[32,4],null,[[\"@key\",\"@url\",\"@title\",\"@darkUrl\"],[\"logo-small\",[30,0,[\"logoSmallUrl\"]],[30,0,[\"siteSettings\",\"title\"]],[30,0,[\"logoSmallUrlDark\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"              \"],[1,[28,[32,5],[\"home\"],null]],[1,\"\\n\"]],[]]]],[]],[[[41,[30,0,[\"showMobileLogo\"]],[[[1,\"            \"],[8,[32,4],null,[[\"@key\",\"@url\",\"@title\",\"@darkUrl\"],[\"logo-mobile\",[30,0,[\"mobileLogoUrl\"]],[30,0,[\"siteSettings\",\"title\"]],[30,0,[\"mobileLogoUrlDark\"]]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"logoUrl\"]],[[[1,\"            \"],[8,[32,4],null,[[\"@key\",\"@url\",\"@title\",\"@darkUrl\"],[\"logo-big\",[30,0,[\"logoUrl\"]],[30,0,[\"siteSettings\",\"title\"]],[30,0,[\"logoUrlDark\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"            \"],[10,\"h1\"],[14,1,\"site-text-logo\"],[14,0,\"text-logo\"],[12],[1,\"\\n              \"],[1,[30,0,[\"siteSettings\",\"title\"]]],[1,\"\\n            \"],[13],[1,\"\\n          \"]],[]]]],[]]]],[]]],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@minimized\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/home-logo.js",
    "scope": () => [PluginOutlet, hash, concatClass, on, Logo, icon],
    "isStrictMode": true
  }), this);
}