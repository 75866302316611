/* import __COLOCATED_TEMPLATE__ from './search-menu.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { cancel } from "@ember/runloop";
import { service } from "@ember/service";
import { Promise } from "rsvp";
import { popupAjaxError } from "discourse/lib/ajax-error";
import { CANCELLED_STATUS } from "discourse/lib/autocomplete";
import { search as searchCategoryTag } from "discourse/lib/category-tag-search";
import { isValidSearchTerm, searchForTerm, updateRecentSearches } from "discourse/lib/search";
import DiscourseURL from "discourse/lib/url";
import userSearch from "discourse/lib/user-search";
import discourseDebounce from "discourse-common/lib/debounce";
import getURL from "discourse-common/lib/get-url";
import { bind } from "discourse-common/utils/decorators";
import TEMPLATE from "./search-menu.hbs";
import { setComponentTemplate } from "@ember/component";
const CATEGORY_SLUG_REGEXP = /(\#[a-zA-Z0-9\-:]*)$/gi;
const USERNAME_REGEXP = /(\@[a-zA-Z0-9\-\_]*)$/gi;
const SUGGESTIONS_REGEXP = /(in:|status:|order:|:)([a-zA-Z]*)$/gi;
export const SEARCH_INPUT_ID = "search-term";
export const MODIFIER_REGEXP = /.*(\#|\@|:).*$/gi;
export const DEFAULT_TYPE_FILTER = "exclude_topics";
export function focusSearchInput() {
  document.getElementById(SEARCH_INPUT_ID).focus();
}
export default class SearchMenu extends Component {
  static #_ = dt7948.g(this.prototype, "search", [service]);
  #search = (dt7948.i(this, "search"), void 0);
  static #_2 = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_3 = dt7948.g(this.prototype, "siteSettings", [service]);
  #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
  static #_4 = dt7948.g(this.prototype, "appEvents", [service]);
  #appEvents = (dt7948.i(this, "appEvents"), void 0);
  static #_5 = dt7948.g(this.prototype, "loading", [tracked], function () {
    return false;
  });
  #loading = (dt7948.i(this, "loading"), void 0);
  static #_6 = dt7948.g(this.prototype, "inPMInboxContext", [tracked], function () {
    return this.search.searchContext?.type === "private_messages";
  });
  #inPMInboxContext = (dt7948.i(this, "inPMInboxContext"), void 0);
  static #_7 = dt7948.g(this.prototype, "typeFilter", [tracked], function () {
    return DEFAULT_TYPE_FILTER;
  });
  #typeFilter = (dt7948.i(this, "typeFilter"), void 0);
  static #_8 = dt7948.g(this.prototype, "suggestionKeyword", [tracked], function () {
    return false;
  });
  #suggestionKeyword = (dt7948.i(this, "suggestionKeyword"), void 0);
  static #_9 = dt7948.g(this.prototype, "suggestionResults", [tracked], function () {
    return [];
  });
  #suggestionResults = (dt7948.i(this, "suggestionResults"), void 0);
  static #_10 = dt7948.g(this.prototype, "invalidTerm", [tracked], function () {
    return false;
  });
  #invalidTerm = (dt7948.i(this, "invalidTerm"), void 0);
  static #_11 = dt7948.g(this.prototype, "menuPanelOpen", [tracked], function () {
    return false;
  });
  #menuPanelOpen = (dt7948.i(this, "menuPanelOpen"), void 0);
  _debouncer = null;
  _activeSearch = null;
  setupEventListeners() {
    // We only need to register click events when the search menu is rendered outside of the header.
    // The header handles clicking outside.
    if (!this.args.inlineResults) {
      document.addEventListener("mousedown", this.onDocumentPress);
      document.addEventListener("touchend", this.onDocumentPress);
    }
  }
  static #_12 = dt7948.n(this.prototype, "setupEventListeners", [bind]);
  willDestroy() {
    if (!this.args.inlineResults) {
      document.removeEventListener("mousedown", this.onDocumentPress);
      document.removeEventListener("touchend", this.onDocumentPress);
    }
    super.willDestroy(...arguments);
  }
  onDocumentPress(event) {
    if (!this.menuPanelOpen) {
      return;
    }
    if (!event.target.closest(".search-menu-container.menu-panel-results")) {
      this.close();
    }
  }
  static #_13 = dt7948.n(this.prototype, "onDocumentPress", [bind]);
  get classNames() {
    const classes = ["search-menu-container"];
    if (!this.args.inlineResults) {
      classes.push("menu-panel-results");
    }
    if (this.loading) {
      classes.push("loading");
    }
    return classes.join(" ");
  }
  get includesTopics() {
    return !!this.search.results?.topics?.length || this.typeFilter !== DEFAULT_TYPE_FILTER;
  }
  get searchContext() {
    if (this.search.inTopicContext || this.inPMInboxContext) {
      return this.search.searchContext;
    }
    return false;
  }
  close() {
    if (this.args?.onClose) {
      return this.args.onClose();
    }

    // We want to blur the search input when in stand-alone mode
    // so that when we focus on the search input again, the menu panel pops up
    document.getElementById(SEARCH_INPUT_ID)?.blur();
    this.menuPanelOpen = false;
  }
  static #_14 = dt7948.n(this.prototype, "close", [action]);
  open() {
    this.menuPanelOpen = true;
  }
  static #_15 = dt7948.n(this.prototype, "open", [action]);
  fullSearchUrl(opts) {
    let url = "/search";
    let params = new URLSearchParams();
    if (this.search.activeGlobalSearchTerm) {
      let q = this.search.activeGlobalSearchTerm;
      if (this.searchContext?.type === "topic") {
        q += ` topic:${this.searchContext.id}`;
      } else if (this.searchContext?.type === "private_messages") {
        q += " in:messages";
      }
      params.set("q", q);
    }
    if (opts?.expanded) {
      params.set("expanded", "true");
    }
    if (params.toString() !== "") {
      url = `${url}?${params}`;
    }
    return getURL(url);
  }
  static #_16 = dt7948.n(this.prototype, "fullSearchUrl", [bind]);
  openAdvancedSearch() {
    this.fullSearch();
    this.close();
  }
  static #_17 = dt7948.n(this.prototype, "openAdvancedSearch", [action]);
  get displayMenuPanelResults() {
    if (this.args.inlineResults) {
      return false;
    }
    return this.menuPanelOpen;
  }
  clearSearch(e) {
    e.stopPropagation();
    e.preventDefault();
    this.search.activeGlobalSearchTerm = "";
    focusSearchInput();
    this.triggerSearch();
  }
  static #_18 = dt7948.n(this.prototype, "clearSearch", [bind]);
  searchTermChanged(term) {
    let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    this.typeFilter = opts.searchTopics ? null : DEFAULT_TYPE_FILTER;
    if (opts.setTopicContext) {
      this.search.inTopicContext = true;
    }
    this.search.activeGlobalSearchTerm = term;
    this.triggerSearch();
  }
  static #_19 = dt7948.n(this.prototype, "searchTermChanged", [action]);
  fullSearch() {
    this.loading = false;
    const url = this.fullSearchUrl();
    if (url) {
      DiscourseURL.routeTo(url);
    }
  }
  static #_20 = dt7948.n(this.prototype, "fullSearch", [action]);
  updateTypeFilter(value) {
    this.typeFilter = value;
  }
  static #_21 = dt7948.n(this.prototype, "updateTypeFilter", [action]);
  clearPMInboxContext() {
    this.inPMInboxContext = false;
  }
  static #_22 = dt7948.n(this.prototype, "clearPMInboxContext", [action]);
  clearTopicContext() {
    this.search.inTopicContext = false;
  }

  // for cancelling debounced search
  static #_23 = dt7948.n(this.prototype, "clearTopicContext", [action]);
  cancel() {
    if (this._activeSearch) {
      this._activeSearch.abort();
      this._activeSearch = null;
    }
  }
  async perform() {
    this.cancel();
    const matchSuggestions = this.matchesSuggestions();
    if (matchSuggestions) {
      this.search.noResults = true;
      this.search.results = {};
      this.loading = false;
      this.suggestionResults = [];
      if (matchSuggestions.type === "category") {
        const categorySearchTerm = matchSuggestions.categoriesMatch[0].replace("#", "");
        const categoryTagSearch = searchCategoryTag(categorySearchTerm, this.siteSettings);
        Promise.resolve(categoryTagSearch).then(results => {
          if (results !== CANCELLED_STATUS) {
            this.suggestionResults = results;
            this.suggestionKeyword = "#";
          }
        });
      } else if (matchSuggestions.type === "username") {
        const userSearchTerm = matchSuggestions.usernamesMatch[0].replace("@", "");
        const opts = {
          includeGroups: true,
          limit: 6
        };
        if (userSearchTerm.length > 0) {
          opts.term = userSearchTerm;
        } else {
          opts.lastSeenUsers = true;
        }
        userSearch(opts).then(result => {
          if (result?.users?.length > 0) {
            this.suggestionResults = result.users;
            this.suggestionKeyword = "@";
          } else {
            this.search.noResults = true;
            this.suggestionKeyword = false;
          }
        });
      } else {
        this.suggestionKeyword = matchSuggestions[0];
      }
      return;
    }
    this.suggestionKeyword = false;
    if (!this.search.activeGlobalSearchTerm) {
      this.search.noResults = false;
      this.search.results = {};
      this.loading = false;
      this.invalidTerm = false;
    } else if (!isValidSearchTerm(this.search.activeGlobalSearchTerm, this.siteSettings)) {
      this.search.noResults = true;
      this.search.results = {};
      this.loading = false;
      this.invalidTerm = true;
    } else {
      this.loading = true;
      this.invalidTerm = false;
      this._activeSearch = searchForTerm(this.search.activeGlobalSearchTerm, {
        typeFilter: this.typeFilter,
        fullSearchUrl: this.fullSearchUrl,
        searchContext: this.searchContext
      });
      this._activeSearch.then(results => {
        // we ensure the current search term is the one used
        // when starting the query
        if (results) {
          if (this.searchContext) {
            this.appEvents.trigger("post-stream:refresh", {
              force: true
            });
          }
          this.search.noResults = results.resultTypes.length === 0;
          this.search.results = results;
        }
      }).catch(popupAjaxError).finally(() => {
        this.loading = false;
      });
    }
  }
  matchesSuggestions() {
    if (this.search.activeGlobalSearchTerm === undefined || this.includesTopics) {
      return false;
    }
    const term = this.search.activeGlobalSearchTerm.trim();
    const categoriesMatch = term.match(CATEGORY_SLUG_REGEXP);
    if (categoriesMatch) {
      return {
        type: "category",
        categoriesMatch
      };
    }
    const usernamesMatch = term.match(USERNAME_REGEXP);
    if (usernamesMatch) {
      return {
        type: "username",
        usernamesMatch
      };
    }
    const suggestionsMatch = term.match(SUGGESTIONS_REGEXP);
    if (suggestionsMatch) {
      return suggestionsMatch;
    }
    return false;
  }
  triggerSearch() {
    this.search.noResults = false;
    if (this.includesTopics) {
      if (this.search.contextType === "topic") {
        this.search.highlightTerm = this.search.activeGlobalSearchTerm;
      }
      this.loading = true;
      cancel(this._debouncer);
      this.perform();
      if (this.currentUser) {
        updateRecentSearches(this.currentUser, this.search.activeGlobalSearchTerm);
      }
    } else {
      this.loading = false;
      if (!this.search.inTopicContext) {
        this._debouncer = discourseDebounce(this, this.perform, 400);
      }
    }
  }
  static #_24 = dt7948.n(this.prototype, "triggerSearch", [action]);
}
setComponentTemplate(TEMPLATE, SearchMenu);