/* import __COLOCATED_TEMPLATE__ from './jump-to-post.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import TEMPLATE from "./jump-to-post.hbs";
import { setComponentTemplate } from "@ember/component";
export default class JumpToPost extends Component {
  static #_ = dt7948.g(this.prototype, "postNumber", [tracked]);
  #postNumber = (dt7948.i(this, "postNumber"), void 0);
  static #_2 = dt7948.g(this.prototype, "postDate", [tracked]);
  #postDate = (dt7948.i(this, "postDate"), void 0);
  get filteredPostsCount() {
    return this.args.model.topic.postStream.filteredPostsCount;
  }
  _jumpToIndex(postsCounts, postNumber) {
    const where = Math.min(postsCounts, Math.max(1, parseInt(postNumber, 10)));
    this.args.model.jumpToIndex(where);
    this.args.closeModal();
  }
  _jumpToDate(date) {
    this.args.model.jumpToDate(date);
    this.args.closeModal();
  }
  jump() {
    if (this.postNumber) {
      this._jumpToIndex(this.filteredPostsCount, this.postNumber);
    } else if (this.postDate) {
      this._jumpToDate(this.postDate);
    }
  }
  static #_3 = dt7948.n(this.prototype, "jump", [action]);
}
setComponentTemplate(TEMPLATE, JumpToPost);