import Component from "@glimmer/component";
import I18n from "discourse-i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class TimeGap extends Component {
  get description() {
    const daysSince1 = this.args.daysSince;
    if (daysSince1 < 30) {
      return I18n.t("dates.later.x_days", {
        count: daysSince1
      });
    } else if (daysSince1 < 365) {
      const gapMonths1 = Math.round(daysSince1 / 30);
      return I18n.t("dates.later.x_months", {
        count: gapMonths1
      });
    } else {
      const gapYears1 = Math.round(daysSince1 / 365);
      return I18n.t("dates.later.x_years", {
        count: gapYears1
      });
    }
  }
  static #_ = setComponentTemplate(createTemplateFactory(
  /*
    
      <div class="topic-avatar"></div>
      <div class="small-action-desc timegap">
        {{this.description}}
      </div>
    
  */
  {
    "id": "BCAhebOI",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"topic-avatar\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"small-action-desc timegap\"],[12],[1,\"\\n      \"],[1,[30,0,[\"description\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/time-gap.js",
    "isStrictMode": true
  }), this);
}