/* import __COLOCATED_TEMPLATE__ from './composer-container.hbs'; */
import Component from "@glimmer/component";
import { service } from "@ember/service";
import TEMPLATE from "./composer-container.hbs";
import { setComponentTemplate } from "@ember/component";
export default class ComposerContainer extends Component {
  static #_ = dt7948.g(this.prototype, "composer", [service]);
  #composer = (dt7948.i(this, "composer"), void 0);
  static #_2 = dt7948.g(this.prototype, "site", [service]);
  #site = (dt7948.i(this, "site"), void 0);
}
setComponentTemplate(TEMPLATE, ComposerContainer);