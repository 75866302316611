/* import __COLOCATED_TEMPLATE__ from './delete-topic-confirm.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import I18n from "discourse-i18n";

// Modal that displays confirmation text when user deletes a topic
// The modal will display only if the topic exceeds a certain amount of views
import TEMPLATE from "./delete-topic-confirm.hbs";
import { setComponentTemplate } from "@ember/component";
export default class DeleteTopicConfirm extends Component {
  static #_ = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_2 = dt7948.g(this.prototype, "siteSettings", [service]);
  #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
  static #_3 = dt7948.g(this.prototype, "deletingTopic", [tracked], function () {
    return false;
  });
  #deletingTopic = (dt7948.i(this, "deletingTopic"), void 0);
  static #_4 = dt7948.g(this.prototype, "flash", [tracked]);
  #flash = (dt7948.i(this, "flash"), void 0);
  async deleteTopic() {
    try {
      this.deletingTopic = true;
      await this.args.model.topic.destroy(this.currentUser);
      this.args.closeModal();
    } catch {
      this.flash = I18n.t("post.controls.delete_topic_error");
      this.deletingTopic = false;
    }
  }
  static #_5 = dt7948.n(this.prototype, "deleteTopic", [action]);
}
setComponentTemplate(TEMPLATE, DeleteTopicConfirm);