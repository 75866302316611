import { equal, or } from "@ember/object/computed";
import { service } from "@ember/service";
import { userPath } from "discourse/lib/url";
import { postUrl } from "discourse/lib/utilities";
import RestModel from "discourse/models/rest";
import UserActionGroup from "discourse/models/user-action-group";
import discourseComputed from "discourse-common/utils/decorators";
import Category from "./category";
const UserActionTypes = {
  likes_given: 1,
  likes_received: 2,
  bookmarks: 3,
  topics: 4,
  posts: 5,
  replies: 6,
  mentions: 7,
  quotes: 9,
  edits: 11,
  messages_sent: 12,
  messages_received: 13,
  links: 17
};
const InvertedActionTypes = {};
Object.keys(UserActionTypes).forEach(k => InvertedActionTypes[k] = UserActionTypes[k]);
export default class UserAction extends RestModel {
  static TYPES = UserActionTypes;
  static TYPES_INVERTED = InvertedActionTypes;
  static TO_COLLAPSE = [UserActionTypes.likes_given, UserActionTypes.likes_received, UserActionTypes.edits, UserActionTypes.bookmarks];
  static TO_SHOW = [UserActionTypes.likes_given, UserActionTypes.likes_received, UserActionTypes.edits, UserActionTypes.bookmarks, UserActionTypes.messages_sent, UserActionTypes.messages_received];
  static collapseStream(stream) {
    const uniq = {};
    const collapsed = [];
    let pos = 0;
    stream.forEach(item => {
      const key = "" + item.topic_id + "-" + item.post_number;
      const found = uniq[key];
      if (found === void 0) {
        let current;
        if (UserAction.TO_COLLAPSE.includes(item.action_type)) {
          current = UserAction.create(item);
          item.switchToActing();
          current.addChild(item);
        } else {
          current = item;
        }
        uniq[key] = pos;
        collapsed[pos] = current;
        pos += 1;
      } else {
        if (UserAction.TO_COLLAPSE.includes(item.action_type)) {
          item.switchToActing();
          collapsed[found].addChild(item);
        } else {
          collapsed[found].setProperties(item.getProperties("action_type", "description"));
        }
      }
    });
    return collapsed;
  }
  static #_ = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_2 = dt7948.g(this.prototype, "presentName", [or("name", "username")]);
  #presentName = (dt7948.i(this, "presentName"), void 0);
  static #_3 = dt7948.g(this.prototype, "targetDisplayName", [or("target_name", "target_username")]);
  #targetDisplayName = (dt7948.i(this, "targetDisplayName"), void 0);
  static #_4 = dt7948.g(this.prototype, "actingDisplayName", [or("acting_name", "acting_username")]);
  #actingDisplayName = (dt7948.i(this, "actingDisplayName"), void 0);
  static #_5 = dt7948.g(this.prototype, "replyType", [equal("action_type", UserActionTypes.replies)]);
  #replyType = (dt7948.i(this, "replyType"), void 0);
  static #_6 = dt7948.g(this.prototype, "postType", [equal("action_type", UserActionTypes.posts)]);
  #postType = (dt7948.i(this, "postType"), void 0);
  static #_7 = dt7948.g(this.prototype, "topicType", [equal("action_type", UserActionTypes.topics)]);
  #topicType = (dt7948.i(this, "topicType"), void 0);
  static #_8 = dt7948.g(this.prototype, "bookmarkType", [equal("action_type", UserActionTypes.bookmarks)]);
  #bookmarkType = (dt7948.i(this, "bookmarkType"), void 0);
  static #_9 = dt7948.g(this.prototype, "messageSentType", [equal("action_type", UserActionTypes.messages_sent)]);
  #messageSentType = (dt7948.i(this, "messageSentType"), void 0);
  static #_10 = dt7948.g(this.prototype, "messageReceivedType", [equal("action_type", UserActionTypes.messages_received)]);
  #messageReceivedType = (dt7948.i(this, "messageReceivedType"), void 0);
  static #_11 = dt7948.g(this.prototype, "mentionType", [equal("action_type", UserActionTypes.mentions)]);
  #mentionType = (dt7948.i(this, "mentionType"), void 0);
  static #_12 = dt7948.g(this.prototype, "isPM", [or("messageSentType", "messageReceivedType")]);
  #isPM = (dt7948.i(this, "isPM"), void 0);
  static #_13 = dt7948.g(this.prototype, "postReplyType", [or("postType", "replyType")]);
  #postReplyType = (dt7948.i(this, "postReplyType"), void 0);
  category() {
    return Category.findById(this.category_id);
  }
  static #_14 = dt7948.n(this.prototype, "category", [discourseComputed("category_id")]);
  descriptionKey(action) {
    if (action === null || UserAction.TO_SHOW.includes(action)) {
      if (this.isPM) {
        return this.sameUser ? "sent_by_you" : "sent_by_user";
      } else {
        return this.sameUser ? "posted_by_you" : "posted_by_user";
      }
    }
    if (this.topicType) {
      return this.sameUser ? "you_posted_topic" : "user_posted_topic";
    }
    if (this.postReplyType) {
      if (this.reply_to_post_number) {
        return this.sameUser ? "you_replied_to_post" : "user_replied_to_post";
      } else {
        return this.sameUser ? "you_replied_to_topic" : "user_replied_to_topic";
      }
    }
    if (this.mentionType) {
      if (this.sameUser) {
        return "you_mentioned_user";
      } else {
        return this.targetUser ? "user_mentioned_you" : "user_mentioned_user";
      }
    }
  }
  static #_15 = dt7948.n(this.prototype, "descriptionKey", [discourseComputed("action_type")]);
  sameUser(username) {
    return username === this.currentUser?.get("username");
  }
  static #_16 = dt7948.n(this.prototype, "sameUser", [discourseComputed("username")]);
  targetUser(targetUsername) {
    return targetUsername === this.currentUser?.get("username");
  }
  static #_17 = dt7948.n(this.prototype, "targetUser", [discourseComputed("target_username")]);
  targetUserUrl(username) {
    return userPath(username);
  }
  static #_18 = dt7948.n(this.prototype, "targetUserUrl", [discourseComputed("target_username")]);
  usernameLower(username) {
    return username.toLowerCase();
  }
  static #_19 = dt7948.n(this.prototype, "usernameLower", [discourseComputed("username")]);
  userUrl(usernameLower) {
    return userPath(usernameLower);
  }
  static #_20 = dt7948.n(this.prototype, "userUrl", [discourseComputed("usernameLower")]);
  postUrl() {
    return postUrl(this.slug, this.topic_id, this.post_number);
  }
  static #_21 = dt7948.n(this.prototype, "postUrl", [discourseComputed()]);
  replyUrl() {
    return postUrl(this.slug, this.topic_id, this.reply_to_post_number);
  }
  static #_22 = dt7948.n(this.prototype, "replyUrl", [discourseComputed()]);
  addChild(action) {
    let groups = this.childGroups;
    if (!groups) {
      groups = {
        likes: UserActionGroup.create({
          icon: "heart"
        }),
        stars: UserActionGroup.create({
          icon: "star"
        }),
        edits: UserActionGroup.create({
          icon: "pencil-alt"
        }),
        bookmarks: UserActionGroup.create({
          icon: "bookmark"
        })
      };
    }
    this.set("childGroups", groups);
    const bucket = function () {
      switch (action.action_type) {
        case UserActionTypes.likes_given:
        case UserActionTypes.likes_received:
          return "likes";
        case UserActionTypes.edits:
          return "edits";
        case UserActionTypes.bookmarks:
          return "bookmarks";
      }
    }();
    const current = groups[bucket];
    if (current) {
      current.push(action);
    }
  }
  children() {
    const g = this.childGroups;
    let rval = [];
    if (g) {
      rval = [g.likes, g.stars, g.edits, g.bookmarks].filter(function (i) {
        return i.get("items") && i.get("items").length > 0;
      });
    }
    return rval;
  }
  static #_23 = dt7948.n(this.prototype, "children", [discourseComputed("childGroups", "childGroups.likes.items", "childGroups.likes.items.[]", "childGroups.stars.items", "childGroups.stars.items.[]", "childGroups.edits.items", "childGroups.edits.items.[]", "childGroups.bookmarks.items", "childGroups.bookmarks.items.[]")]);
  switchToActing() {
    this.setProperties({
      username: this.acting_username,
      name: this.actingDisplayName
    });
  }
}