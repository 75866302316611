import { action } from "@ember/object";
import { service } from "@ember/service";
import { resetCachedTopicList } from "discourse/lib/cached-topic-list";
import DiscourseRoute from "discourse/routes/discourse";

/**
  The parent route for all discovery routes.
**/
export default class DiscoveryRoute extends DiscourseRoute {
  static #_ = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_2 = dt7948.g(this.prototype, "router", [service]);
  #router = (dt7948.i(this, "router"), void 0);
  static #_3 = dt7948.g(this.prototype, "session", [service]);
  #session = (dt7948.i(this, "session"), void 0);
  static #_4 = dt7948.g(this.prototype, "site", [service]);
  #site = (dt7948.i(this, "site"), void 0);
  queryParams = {
    filter: {
      refreshModel: true
    }
  };
  redirect() {
    return this.redirectIfLoginRequired();
  }
  beforeModel(transition) {
    const url = transition.intent.url;
    let matches;
    if ((url === "/" || url === "/latest" || url === "/categories") && !transition.targetName.includes("discovery.top") && this.currentUser?.get("user_option.should_be_redirected_to_top")) {
      this.currentUser?.get("user_option.should_be_redirected_to_top", false);
      const period = this.currentUser?.get("user_option.redirected_to_top.period") || "all";
      this.router.replaceWith("discovery.top", {
        queryParams: {
          period
        }
      });
    } else if (url && (matches = url.match(/top\/(.*)$/))) {
      if (this.site.periods.includes(matches[1])) {
        this.router.replaceWith("discovery.top", {
          queryParams: {
            period: matches[1]
          }
        });
      }
    }
  }

  // clear a pinned topic
  clearPin(topic) {
    topic.clearPin();
  }
  static #_5 = dt7948.n(this.prototype, "clearPin", [action]);
  refresh() {
    resetCachedTopicList(this.session);
    super.refresh();
  }
  triggerRefresh() {
    this.refresh();
  }
  static #_6 = dt7948.n(this.prototype, "triggerRefresh", [action]);
}