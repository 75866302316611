/* import __COLOCATED_TEMPLATE__ from './suggested-topics.hbs'; */
import Component from "@glimmer/component";
import { action, computed } from "@ember/object";
import { service } from "@ember/service";
import I18n from "discourse-i18n";
import TEMPLATE from "./suggested-topics.hbs";
import { setComponentTemplate } from "@ember/component";
export default class SuggestedTopics extends Component {
  static #_ = dt7948.g(this.prototype, "moreTopicsPreferenceTracking", [service]);
  #moreTopicsPreferenceTracking = (dt7948.i(this, "moreTopicsPreferenceTracking"), void 0);
  static #_2 = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  listId = "suggested-topics";
  get suggestedTitleLabel() {
    const href = this.currentUser && this.currentUser.pmPath(this.args.topic);
    if (this.args.topic.isPrivateMessage && href) {
      return "suggested_topics.pm_title";
    } else {
      return "suggested_topics.title";
    }
  }
  get hidden() {
    return this.moreTopicsPreferenceTracking.selectedTab !== this.listId;
  }
  static #_3 = dt7948.n(this.prototype, "hidden", [computed("moreTopicsPreferenceTracking.selectedTab")]);
  registerList() {
    this.moreTopicsPreferenceTracking.registerTopicList({
      name: I18n.t("suggested_topics.pill"),
      id: this.listId
    });
  }
  static #_4 = dt7948.n(this.prototype, "registerList", [action]);
  removeList() {
    this.moreTopicsPreferenceTracking.removeTopicList(this.listId);
  }
  static #_5 = dt7948.n(this.prototype, "removeList", [action]);
}
setComponentTemplate(TEMPLATE, SuggestedTopics);