/* import __COLOCATED_TEMPLATE__ from './grant-badge.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { extractError } from "discourse/lib/ajax-error";
import { grantableBadges, isBadgeGrantable } from "discourse/lib/grant-badge-utils";
import Badge from "discourse/models/badge";
import UserBadge from "discourse/models/user-badge";
import getURL from "discourse-common/lib/get-url";
import I18n from "discourse-i18n";
import TEMPLATE from "./grant-badge.hbs";
import { setComponentTemplate } from "@ember/component";
export default class GrantBadgeModal extends Component {
  static #_ = dt7948.g(this.prototype, "loading", [tracked], function () {
    return true;
  });
  #loading = (dt7948.i(this, "loading"), void 0);
  static #_2 = dt7948.g(this.prototype, "saving", [tracked], function () {
    return false;
  });
  #saving = (dt7948.i(this, "saving"), void 0);
  static #_3 = dt7948.g(this.prototype, "selectedBadgeId", [tracked], function () {
    return null;
  });
  #selectedBadgeId = (dt7948.i(this, "selectedBadgeId"), void 0);
  static #_4 = dt7948.g(this.prototype, "flash", [tracked], function () {
    return null;
  });
  #flash = (dt7948.i(this, "flash"), void 0);
  static #_5 = dt7948.g(this.prototype, "flashType", [tracked], function () {
    return null;
  });
  #flashType = (dt7948.i(this, "flashType"), void 0);
  static #_6 = dt7948.g(this.prototype, "allBadges", [tracked], function () {
    return [];
  });
  #allBadges = (dt7948.i(this, "allBadges"), void 0);
  static #_7 = dt7948.g(this.prototype, "userBadges", [tracked], function () {
    return [];
  });
  #userBadges = (dt7948.i(this, "userBadges"), void 0);
  static #_8 = dt7948.g(this.prototype, "availableBadges", [tracked], function () {
    return [];
  });
  #availableBadges = (dt7948.i(this, "availableBadges"), void 0);
  get noAvailableBadges() {
    !this.availableBadges.length;
  }
  get post() {
    return this.args.model.selectedPost;
  }
  get buttonDisabled() {
    return this.saving || !isBadgeGrantable(this.selectedBadgeId, this.availableBadges);
  }
  #updateAvailableBadges() {
    this.availableBadges = grantableBadges(this.allBadges, this.userBadges);
  }
  async loadBadges() {
    this.loading = true;
    try {
      this.allBadges = await Badge.findAll();
      this.userBadges = await UserBadge.findByUsername(this.post.username);
      this.#updateAvailableBadges();
    } catch (e) {
      this.flash = extractError(e);
      this.flashType = "error";
    } finally {
      this.loading = false;
    }
  }
  static #_9 = dt7948.n(this.prototype, "loadBadges", [action]);
  async performGrantBadge() {
    try {
      this.saving = true;
      const username = this.post.username;
      const newBadge = await UserBadge.grant(this.selectedBadgeId, username, getURL(this.post.url));
      this.userBadges.pushObject(newBadge);
      this.#updateAvailableBadges();
      this.selectedBadgeId = null;
      this.flash = I18n.t("badges.successfully_granted", {
        username,
        badge: newBadge.get("badge.name")
      });
      this.flashType = "success";
    } catch (e) {
      this.flash = extractError(e);
      this.flashType = "error";
    } finally {
      this.saving = false;
    }
  }
  static #_10 = dt7948.n(this.prototype, "performGrantBadge", [action]);
}
setComponentTemplate(TEMPLATE, GrantBadgeModal);