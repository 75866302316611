/* import __COLOCATED_TEMPLATE__ from './categories-section.hbs'; */
import "@glimmer/tracking";
let cached = esc(require('@glimmer/tracking')).cached;
import { action } from "@ember/object";
import { service } from "@ember/service";
import SidebarCommonCategoriesSection from "discourse/components/sidebar/common/categories-section";
import SidebarEditNavigationMenuCategoriesModal from "discourse/components/sidebar/edit-navigation-menu/categories-modal";
import { hasDefaultSidebarCategories } from "discourse/lib/sidebar/helpers";
import Category from "discourse/models/category";
import { debounce } from "discourse-common/utils/decorators";
import TEMPLATE from "./categories-section.hbs";
import esc from "../../../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";
import { setComponentTemplate } from "@ember/component";
export const REFRESH_COUNTS_APP_EVENT_NAME = "sidebar:refresh-categories-section-counts";
export default class SidebarUserCategoriesSection extends SidebarCommonCategoriesSection {
  static #_ = dt7948.g(this.prototype, "appEvents", [service]);
  #appEvents = (dt7948.i(this, "appEvents"), void 0);
  static #_2 = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_3 = dt7948.g(this.prototype, "modal", [service]);
  #modal = (dt7948.i(this, "modal"), void 0);
  static #_4 = dt7948.g(this.prototype, "router", [service]);
  #router = (dt7948.i(this, "router"), void 0);
  constructor() {
    super(...arguments);
    this.callbackId = this.topicTrackingState.onStateChange(() => {
      this._refreshCounts();
    });
    this.appEvents.on(REFRESH_COUNTS_APP_EVENT_NAME, this, this._refreshCounts);
  }
  willDestroy() {
    super.willDestroy(...arguments);
    this.topicTrackingState.offStateChange(this.callbackId);
    this.appEvents.off(REFRESH_COUNTS_APP_EVENT_NAME, this, this._refreshCounts);
  }

  // TopicTrackingState changes or plugins can trigger this function so we debounce to ensure we're not refreshing
  // unnecessarily.
  _refreshCounts() {
    this.sectionLinks.forEach(sectionLink => {
      sectionLink.refreshCounts();
    });
  }
  static #_5 = dt7948.n(this.prototype, "_refreshCounts", [debounce(300)]);
  get categories() {
    if (this.currentUser.sidebarCategoryIds?.length > 0) {
      return Category.findByIds(this.currentUser.sidebarCategoryIds);
    } else {
      return this.topSiteCategories;
    }
  }
  static #_6 = dt7948.n(this.prototype, "categories", [cached]);
  get shouldDisplayDefaultConfig() {
    return this.currentUser.admin && !this.hasDefaultSidebarCategories;
  }
  get hasDefaultSidebarCategories() {
    return hasDefaultSidebarCategories(this.siteSettings);
  }
  showModal() {
    this.modal.show(SidebarEditNavigationMenuCategoriesModal);
  }
  static #_7 = dt7948.n(this.prototype, "showModal", [action]);
}
setComponentTemplate(TEMPLATE, SidebarUserCategoriesSection);