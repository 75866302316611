/* import __COLOCATED_TEMPLATE__ from './api-sections.hbs'; */
import Component from "@glimmer/component";
import { service } from "@ember/service";
import TEMPLATE from "./api-sections.hbs";
import { setComponentTemplate } from "@ember/component";
export default class SidebarApiSections extends Component {
  static #_ = dt7948.g(this.prototype, "sidebarState", [service]);
  #sidebarState = (dt7948.i(this, "sidebarState"), void 0);
  get sections() {
    if (this.sidebarState.combinedMode) {
      return this.sidebarState.panels.filter(panel => !panel.hidden).map(panel => panel.sections).flat();
    } else {
      return this.sidebarState.currentPanel.sections;
    }
  }
}
setComponentTemplate(TEMPLATE, SidebarApiSections);