/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { bind } from "discourse-common/utils/decorators";
import TEMPLATE from "./sidebar.hbs";
import { setComponentTemplate } from "@ember/component";
export default class Sidebar extends Component {
  static #_ = dt7948.g(this.prototype, "appEvents", [service]);
  #appEvents = (dt7948.i(this, "appEvents"), void 0);
  static #_2 = dt7948.g(this.prototype, "site", [service]);
  #site = (dt7948.i(this, "site"), void 0);
  static #_3 = dt7948.g(this.prototype, "siteSettings", [service]);
  #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
  static #_4 = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_5 = dt7948.g(this.prototype, "sidebarState", [service]);
  #sidebarState = (dt7948.i(this, "sidebarState"), void 0);
  constructor() {
    super(...arguments);
    if (this.site.mobileView) {
      document.addEventListener("click", this.collapseSidebar);
    }
  }
  get showSwitchPanelButtonsOnTop() {
    return this.siteSettings.default_sidebar_switch_panel_position === "top";
  }
  get switchPanelButtons() {
    if (!this.sidebarState.displaySwitchPanelButtons || this.sidebarState.panels.length === 1 || !this.currentUser) {
      return [];
    }
    return this.sidebarState.panels.filter(panel => panel !== this.sidebarState.currentPanel && !panel.hidden);
  }
  collapseSidebar(event) {
    let shouldCollapseSidebar = false;
    const isClickWithinSidebar = event.composedPath().some(element => {
      if (element?.className !== "sidebar-section-header-caret" && ["A", "BUTTON"].includes(element.nodeName)) {
        shouldCollapseSidebar = true;
        return true;
      }
      return element.className && element.className === "sidebar-wrapper";
    });
    if (shouldCollapseSidebar || !isClickWithinSidebar) {
      this.args.toggleSidebar();
    }
  }
  static #_6 = dt7948.n(this.prototype, "collapseSidebar", [bind]);
  willDestroy() {
    super.willDestroy(...arguments);
    if (this.site.mobileView) {
      document.removeEventListener("click", this.collapseSidebar);
    }
  }
}
setComponentTemplate(TEMPLATE, Sidebar);