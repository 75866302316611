/* import __COLOCATED_TEMPLATE__ from './items-list.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DiscourseURL from "discourse/lib/url";
import TEMPLATE from "./items-list.hbs";
import { setComponentTemplate } from "@ember/component";
export default class UserMenuItemsList extends Component {
  static #_ = dt7948.g(this.prototype, "session", [service]);
  #session = (dt7948.i(this, "session"), void 0);
  static #_2 = dt7948.g(this.prototype, "loading", [tracked], function () {
    return false;
  });
  #loading = (dt7948.i(this, "loading"), void 0);
  static #_3 = dt7948.g(this.prototype, "items", [tracked], function () {
    return [];
  });
  #items = (dt7948.i(this, "items"), void 0);
  constructor() {
    super(...arguments);
    this.#load();
  }
  get itemsCacheKey() {}
  get showAllHref() {}
  get showAllTitle() {}
  get showDismiss() {
    return false;
  }
  get dismissTitle() {}
  get emptyStateComponent() {
    return "user-menu/items-list-empty-state";
  }
  get renderDismissConfirmation() {
    return false;
  }
  async fetchItems() {
    throw new Error(`the fetchItems method must be implemented in ${this.constructor.name}`);
  }
  async refreshList() {
    await this.#load();
  }
  async #load() {
    const cached = this.#getCachedItems();
    if (cached?.length) {
      this.items = cached;
    } else {
      this.loading = true;
    }
    try {
      const items = await this.fetchItems();
      this.#setCachedItems(items);
      this.items = items;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(`an error occurred when loading items for ${this.constructor.name}`, err);
    } finally {
      this.loading = false;
    }
  }
  #getCachedItems() {
    const key = this.itemsCacheKey;
    if (key) {
      return this.session[`user-menu-items:${key}`];
    }
  }
  #setCachedItems(newItems) {
    const key = this.itemsCacheKey;
    if (key) {
      this.session.set(`user-menu-items:${key}`, newItems);
    }
  }
  dismissButtonClick() {
    throw new Error(`dismissButtonClick must be implemented in ${this.constructor.name}.`);
  }
  static #_4 = dt7948.n(this.prototype, "dismissButtonClick", [action]);
  showAll() {
    DiscourseURL.routeTo(this.showAllHref);
    this.args.closeUserMenu();
  }
  static #_5 = dt7948.n(this.prototype, "showAll", [action]);
}
setComponentTemplate(TEMPLATE, UserMenuItemsList);