import Component from "@glimmer/component";
import { concat, hash } from "@ember/helper";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { eq } from "truth-helpers";
import avatar from "discourse/helpers/bound-avatar-template";
import icon from "discourse-common/helpers/d-icon";
import getURL from "discourse-common/lib/get-url";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class Participant extends Component {
  static #_ = dt7948.g(this.prototype, "appEvents", [service]);
  #appEvents = (dt7948.i(this, "appEvents"), void 0);
  get url() {
    return this.args.type === "user" ? this.args.user.path : getURL(`/g/${this.args.username}`);
  }
  click(e1) {
    this.appEvents.trigger(`topic-header:trigger-${this.args.type}-card`, this.args.username, e1.target);
    e1.preventDefault();
  }
  static #_2 = dt7948.n(this.prototype, "click", [action]);
  static #_3 = setComponentTemplate(createTemplateFactory(
  /*
    
      <span class={{concat "trigger-" @type "-card"}}>
        <a
          class="icon"
          {{on "click" this.click}}
          href={{this.url}}
          data-auto-route="true"
          title={{@username}}
        >
          {{#if (eq @type "user")}}
            {{avatar @user.avatar_template "tiny" (hash title=@username)}}
          {{else}}
            <span>
              {{icon "users"}}
              {{@username}}
            </span>
          {{/if}}
        </a>
      </span>
    
  */
  {
    "id": "vUEnQtNr",
    "block": "[[[1,\"\\n    \"],[10,1],[15,0,[28,[32,0],[\"trigger-\",[30,1],\"-card\"],null]],[12],[1,\"\\n      \"],[11,3],[24,0,\"icon\"],[16,6,[30,0,[\"url\"]]],[24,\"data-auto-route\",\"true\"],[16,\"title\",[30,2]],[4,[32,1],[\"click\",[30,0,[\"click\"]]],null],[12],[1,\"\\n\"],[41,[28,[32,2],[[30,1],\"user\"],null],[[[1,\"          \"],[1,[28,[32,3],[[30,3,[\"avatar_template\"]],\"tiny\",[28,[32,4],null,[[\"title\"],[[30,2]]]]],null]],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,1],[12],[1,\"\\n            \"],[1,[28,[32,5],[\"users\"],null]],[1,\"\\n            \"],[1,[30,2]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@type\",\"@username\",\"@user\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/topic/participant.js",
    "scope": () => [concat, on, eq, avatar, hash, icon],
    "isStrictMode": true
  }), this);
}