import Component from "@glimmer/component";
import { action } from "@ember/object";
import { htmlSafe } from "@ember/template";
import icon from "discourse-common/helpers/d-icon";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class FormTemplateFieldMultiSelect extends Component {
  isSelected(option1) {
    return this.args.value?.includes(option1);
  }
  static #_ = dt7948.n(this.prototype, "isSelected", [action]);
  static #_2 = setComponentTemplate(createTemplateFactory(
  /*
    
      <div
        data-field-type="multi-select"
        class="control-group form-template-field"
      >
        {{#if @attributes.label}}
          <label class="form-template-field__label">
            {{@attributes.label}}
            {{#if @validations.required}}
              {{icon "asterisk" class="form-template-field__required-indicator"}}
            {{/if}}
          </label>
        {{/if}}
  
        {{#if @attributes.description}}
          <span class="form-template-field__description">
            {{htmlSafe @attributes.description}}
          </span>
        {{/if}}
  
        {{! TODO(@keegan): Update implementation to use <MultiSelect/> instead }}
        {{! Current using <select multiple> as it integrates easily with FormData (will update in v2) }}
        <select
          name={{@id}}
          required={{if @validations.required "required" ""}}
          multiple="multiple"
          class="form-template-field__multi-select"
        >
          {{#if @attributes.none_label}}
            <option
              class="form-template-field__multi-select-placeholder"
              value=""
              disabled
              hidden
            >{{@attributes.none_label}}</option>
          {{/if}}
          {{#each @choices as |choice|}}
            <option
              value={{choice}}
              selected={{this.isSelected choice}}
            >{{choice}}</option>
          {{/each}}
        </select>
      </div>
    
  */
  {
    "id": "2zc7iWEa",
    "block": "[[[1,\"\\n    \"],[10,0],[14,\"data-field-type\",\"multi-select\"],[14,0,\"control-group form-template-field\"],[12],[1,\"\\n\"],[41,[30,1,[\"label\"]],[[[1,\"        \"],[10,\"label\"],[14,0,\"form-template-field__label\"],[12],[1,\"\\n          \"],[1,[30,1,[\"label\"]]],[1,\"\\n\"],[41,[30,2,[\"required\"]],[[[1,\"            \"],[1,[28,[32,0],[\"asterisk\"],[[\"class\"],[\"form-template-field__required-indicator\"]]]],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,1,[\"description\"]],[[[1,\"        \"],[10,1],[14,0,\"form-template-field__description\"],[12],[1,\"\\n          \"],[1,[28,[32,1],[[30,1,[\"description\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[1,\"      \"],[10,\"select\"],[15,3,[30,3]],[15,\"required\",[52,[30,2,[\"required\"]],\"required\",\"\"]],[14,\"multiple\",\"multiple\"],[14,0,\"form-template-field__multi-select\"],[12],[1,\"\\n\"],[41,[30,1,[\"none_label\"]],[[[1,\"          \"],[10,\"option\"],[14,0,\"form-template-field__multi-select-placeholder\"],[14,2,\"\"],[14,\"disabled\",\"\"],[14,\"hidden\",\"\"],[12],[1,[30,1,[\"none_label\"]]],[13],[1,\"\\n\"]],[]],null],[42,[28,[31,2],[[28,[31,2],[[30,4]],null]],null],null,[[[1,\"          \"],[10,\"option\"],[15,2,[30,5]],[15,\"selected\",[28,[30,0,[\"isSelected\"]],[[30,5]],null]],[12],[1,[30,5]],[13],[1,\"\\n\"]],[5]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@attributes\",\"@validations\",\"@id\",\"@choices\",\"choice\"],false,[\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/form-template-field/multi-select.js",
    "scope": () => [icon, htmlSafe],
    "isStrictMode": true
  }), this);
}