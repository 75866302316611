import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
let cached = esc(require('@glimmer/tracking')).cached;
import { action } from "@ember/object";
import { cancel, debounce } from "@ember/runloop";
import { service } from "@ember/service";
import { modifier } from "ember-modifier";
import PostTextSelectionToolbar from "discourse/components/post-text-selection-toolbar";
import isElementInViewport from "discourse/lib/is-element-in-viewport";
import toMarkdown from "discourse/lib/to-markdown";
import { selectedNode, selectedRange, selectedText } from "discourse/lib/utilities";
import virtualElementFromTextRange from "discourse/lib/virtual-element-from-text-range";
import { INPUT_DELAY } from "discourse-common/config/environment";
import discourseDebounce from "discourse-common/lib/debounce";
import { bind } from "discourse-common/utils/decorators";
import escapeRegExp from "discourse-common/utils/escape-regexp";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import esc from "../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";
function getQuoteTitle(element1) {
  const titleEl1 = element1.querySelector(".title");
  if (!titleEl1) {
    return;
  }
  const titleLink1 = titleEl1.querySelector("a:not(.back)");
  if (titleLink1) {
    return titleLink1.textContent.trim();
  }
  return titleEl1.textContent.trim().replace(/:$/, "");
}
export default class PostTextSelection extends Component {
  static #_ = dt7948.g(this.prototype, "appEvents", [service]);
  #appEvents = (dt7948.i(this, "appEvents"), void 0);
  static #_2 = dt7948.g(this.prototype, "capabilities", [service]);
  #capabilities = (dt7948.i(this, "capabilities"), void 0);
  static #_3 = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_4 = dt7948.g(this.prototype, "site", [service]);
  #site = (dt7948.i(this, "site"), void 0);
  static #_5 = dt7948.g(this.prototype, "siteSettings", [service]);
  #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
  static #_6 = dt7948.g(this.prototype, "menu", [service]);
  #menu = (dt7948.i(this, "menu"), void 0);
  static #_7 = dt7948.g(this.prototype, "isSelecting", [tracked], function () {
    return false;
  });
  #isSelecting = (dt7948.i(this, "isSelecting"), void 0);
  prevSelectedText;
  runLoopHandlers = modifier(() => {
    return () => {
      cancel(this.selectionChangeHandler);
    };
  });
  documentListeners = modifier(() => {
    document.addEventListener("mousedown", this.mousedown, {
      passive: true
    });
    document.addEventListener("mouseup", this.mouseup, {
      passive: true
    });
    document.addEventListener("selectionchange", this.onSelectionChanged);
    return () => {
      document.removeEventListener("mousedown", this.mousedown);
      document.removeEventListener("mouseup", this.mouseup);
      document.removeEventListener("selectionchange", this.onSelectionChanged);
    };
  });
  appEventsListeners = modifier(() => {
    this.appEvents.on("topic:current-post-scrolled", this, "handleTopicScroll");
    this.appEvents.on("quote-button:quote", this, "insertQuote");
    return () => {
      this.appEvents.off("topic:current-post-scrolled", this, "handleTopicScroll");
      this.appEvents.off("quote-button:quote", this, "insertQuote");
    };
  });
  willDestroy() {
    super.willDestroy(...arguments);
    cancel(this.debouncedSelectionChanged);
    this.menuInstance?.close();
  }
  async hideToolbar() {
    this.args.quoteState.clear();
    await this.menuInstance?.close();
  }
  static #_8 = dt7948.n(this.prototype, "hideToolbar", [bind]);
  async selectionChanged() {
    let options1 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    if (this.isSelecting) {
      return;
    }
    const _selectedText1 = selectedText();
    const selection1 = window.getSelection();
    if (selection1.isCollapsed || _selectedText1 === "") {
      if (!this.menuInstance?.expanded) {
        this.args.quoteState.clear();
      }
      return;
    }
    // avoid hard loops in quote selection unconditionally
    // this can happen if you triple click text in firefox
    // it's also generally unecessary work to go
    // through this if the selection hasn't changed
    if (!options1.force && this.menuInstance?.expanded && this.prevSelectedText === _selectedText1) {
      return;
    }
    this.prevSelectedText = _selectedText1;
    // ensure we selected content inside 1 post *only*
    let postId1;
    for (let r1 = 0; r1 < selection1.rangeCount; r1++) {
      const range1 = selection1.getRangeAt(r1);
      const selectionStart1 = range1.startContainer.nodeType === Node.ELEMENT_NODE ? range1.startContainer : range1.startContainer.parentElement;
      const ancestor1 = range1.commonAncestorContainer.nodeType === Node.ELEMENT_NODE ? range1.commonAncestorContainer : range1.commonAncestorContainer.parentElement;
      if (!selectionStart1.closest(".cooked")) {
        return await this.hideToolbar();
      }
      postId1 ||= ancestor1.closest(".boxed, .reply")?.dataset?.postId;
      if (!ancestor1.closest(".contents") || !postId1) {
        return await this.hideToolbar();
      }
    }
    const _selectedElement1 = selectedNode().nodeType === Node.ELEMENT_NODE ? selectedNode() : selectedNode().parentElement;
    const cooked1 = _selectedElement1.querySelector(".cooked") || _selectedElement1.closest(".cooked");
    // computing markdown takes a lot of time on long posts
    // this code attempts to compute it only when we can't fast track
    let opts1 = {
      full: selectedRange().startOffset > 0 ? false : _selectedText1 === toMarkdown(cooked1.innerHTML)
    };
    for (let element1 = _selectedElement1; element1 && element1.tagName !== "ARTICLE"; element1 = element1.parentElement) {
      if (element1.tagName === "ASIDE" && element1.classList.contains("quote")) {
        opts1.username = element1.dataset.username || getQuoteTitle(element1);
        opts1.post = element1.dataset.post;
        opts1.topic = element1.dataset.topic;
        break;
      }
    }
    const quoteState1 = this.args.quoteState;
    quoteState1.selected(postId1, _selectedText1, opts1);
    let supportsFastEdit1 = this.canEditPost;
    if (this.canEditPost) {
      const regexp1 = new RegExp(escapeRegExp(quoteState1.buffer), "gi");
      const matches1 = cooked1.innerHTML.match(regexp1);
      if (quoteState1.buffer.length === 0 || quoteState1.buffer.includes("|") ||
      // tables are too complex
      quoteState1.buffer.match(/\n/g) ||
      // linebreaks are too complex
      matches1?.length > 1 // duplicates are too complex
      ) {
        supportsFastEdit1 = false;
      } else if (matches1?.length === 1) {
        supportsFastEdit1 = true;
      }
    }
    let offset1 = 3;
    if (this.shouldRenderUnder) {
      // on mobile, we ideally want to show the toolbar at the end of the selection
      offset1 = 20;
      if (!isElementInViewport(selectedRange().startContainer.parentNode) || !isElementInViewport(selectedRange().endContainer.parentNode)) {
        // we force a higher offset in two cases:
        // - the start of the selection is not in viewport, in this case on iOS for example
        //   the native menu will be shown at the bottom of the screen, right after text selection
        //   so we need more space
        // - the end of the selection is not in viewport, in this case our menu will be shown at the top
        //   of the screen, so we need more space to avoid overlapping with the native menu
        offset1 = 70;
      }
    }
    const menuOptions1 = {
      identifier: "post-text-selection-toolbar",
      component: PostTextSelectionToolbar,
      inline: true,
      placement: this.shouldRenderUnder ? "bottom-start" : "top-start",
      fallbackPlacements: this.shouldRenderUnder ? ["bottom-end", "top-start"] : ["bottom-start"],
      offset: offset1,
      trapTab: false,
      closeOnScroll: false,
      data: {
        canEditPost: this.canEditPost,
        canCopyQuote: this.canCopyQuote,
        editPost: this.args.editPost,
        supportsFastEdit: supportsFastEdit1,
        topic: this.args.topic,
        quoteState: quoteState1,
        insertQuote: this.insertQuote,
        buildQuote: this.buildQuote,
        hideToolbar: this.hideToolbar
      }
    };
    await this.menuInstance?.destroy();
    this.menuInstance = await this.menu.show(virtualElementFromTextRange(), menuOptions1);
  }
  onSelectionChanged() {
    if (this.isSelecting) {
      return;
    }
    const {
      isIOS: isIOS1,
      isWinphone: isWinphone1,
      isAndroid: isAndroid1
    } = this.capabilities;
    const wait1 = isIOS1 || isWinphone1 || isAndroid1 ? INPUT_DELAY : 25;
    this.selectionChangeHandler = discourseDebounce(this, this.selectionChanged, wait1);
  }
  static #_9 = dt7948.n(this.prototype, "onSelectionChanged", [bind]);
  mousedown() {
    this.isSelecting = true;
  }
  static #_10 = dt7948.n(this.prototype, "mousedown", [bind]);
  mouseup() {
    this.isSelecting = false;
    this.onSelectionChanged();
  }
  static #_11 = dt7948.n(this.prototype, "mouseup", [bind]);
  get post() {
    return this.args.topic.postStream.findLoadedPost(this.args.quoteState.postId);
  }
  get canEditPost() {
    return this.siteSettings.enable_fast_edit && this.post?.can_edit;
  }
  get canCopyQuote() {
    return this.siteSettings.enable_quote_copy && this.currentUser?.get("user_option.enable_quoting");
  }
  // on Desktop, shows the bar at the beginning of the selection
  // on Mobile, shows the bar at the end of the selection
  get shouldRenderUnder() {
    const {
      isIOS: isIOS1,
      isAndroid: isAndroid1,
      isOpera: isOpera1
    } = this.capabilities;
    return this.site.isMobileDevice || isIOS1 || isAndroid1 || isOpera1;
  }
  static #_12 = dt7948.n(this.prototype, "shouldRenderUnder", [cached]);
  handleTopicScroll() {
    if (this.site.mobileView) {
      this.debouncedSelectionChanged = debounce(this, this.selectionChanged, {
        force: true
      }, 250, false);
    }
  }
  static #_13 = dt7948.n(this.prototype, "handleTopicScroll", [action]);
  async insertQuote() {
    await this.args.selectText();
    await this.hideToolbar();
  }
  static #_14 = dt7948.n(this.prototype, "insertQuote", [action]);
  async buildQuote() {
    return await this.args.buildQuoteMarkdown();
  }
  static #_15 = dt7948.n(this.prototype, "buildQuote", [action]);
  static #_16 = setComponentTemplate(createTemplateFactory(
  /*
    
      <div
        {{this.documentListeners}}
        {{this.appEventsListeners}}
        {{this.runLoopHandlers}}
      ></div>
    
  */
  {
    "id": "JaetaXsH",
    "block": "[[[1,\"\\n    \"],[11,0],[4,[30,0,[\"documentListeners\"]],null,null],[4,[30,0,[\"appEventsListeners\"]],null,null],[4,[30,0,[\"runLoopHandlers\"]],null,null],[12],[13],[1,\"\\n  \"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/post-text-selection.js",
    "isStrictMode": true
  }), this);
}