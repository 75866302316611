/* import __COLOCATED_TEMPLATE__ from './delete-themes-confirm.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import TEMPLATE from "./delete-themes-confirm.hbs";
import { setComponentTemplate } from "@ember/component";
export default class DeleteThemesConfirmComponent extends Component {
  delete() {
    ajax(`/admin/themes/bulk_destroy.json`, {
      type: "DELETE",
      data: {
        theme_ids: this.args.model.selectedThemesOrComponents.mapBy("id")
      }
    }).then(() => {
      this.args.model.refreshAfterDelete();
      this.args.closeModal();
    }).catch(popupAjaxError);
  }
  static #_ = dt7948.n(this.prototype, "delete", [action]);
}
setComponentTemplate(TEMPLATE, DeleteThemesConfirmComponent);