import { tracked } from "@glimmer/tracking";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import { popupAjaxError } from "discourse/lib/ajax-error";
import I18n from "discourse-i18n";
export default class extends Controller {
  static #_ = dt7948.g(this.prototype, "saved", [tracked], function () {
    return false;
  });
  #saved = (dt7948.i(this, "saved"), void 0);
  subpageTitle = I18n.t("user.preferences_nav.navigation_menu");
  saveAttrNames = ["sidebar_link_to_filtered_list", "sidebar_show_count_of_new_items"];
  save() {
    const initialSidebarLinkToFilteredList = this.model.sidebarLinkToFilteredList;
    const initialSidebarShowCountOfNewItems = this.model.sidebarShowCountOfNewItems;
    this.model.set("user_option.sidebar_link_to_filtered_list", this.newSidebarLinkToFilteredList);
    this.model.set("user_option.sidebar_show_count_of_new_items", this.newSidebarShowCountOfNewItems);
    this.model.save(this.saveAttrNames).then(() => {
      this.saved = true;
    }).catch(error => {
      this.model.set("user_option.sidebar_link_to_filtered_list", initialSidebarLinkToFilteredList);
      this.model.set("user_option.sidebar_show_count_of_new_items", initialSidebarShowCountOfNewItems);
      popupAjaxError(error);
    });
  }
  static #_2 = dt7948.n(this.prototype, "save", [action]);
}