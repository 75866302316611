import { tracked } from "@glimmer/tracking";
import Controller from "@ember/controller";
import { action, computed } from "@ember/object";
import { service } from "@ember/service";
import { popupAjaxError } from "discourse/lib/ajax-error";
import { NotificationLevels } from "discourse/lib/notification-levels";
import I18n from "discourse-i18n";
export default class extends Controller {
  static #_ = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_2 = dt7948.g(this.prototype, "siteSettings", [service]);
  #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
  static #_3 = dt7948.g(this.prototype, "saved", [tracked], function () {
    return false;
  });
  #saved = (dt7948.i(this, "saved"), void 0);
  likeNotificationFrequencies = [{
    name: I18n.t("user.like_notification_frequency.always"),
    value: 0
  }, {
    name: I18n.t("user.like_notification_frequency.first_time_and_daily"),
    value: 1
  }, {
    name: I18n.t("user.like_notification_frequency.first_time"),
    value: 2
  }, {
    name: I18n.t("user.like_notification_frequency.never"),
    value: 3
  }];
  autoTrackDurations = [{
    name: I18n.t("user.auto_track_options.never"),
    value: -1
  }, {
    name: I18n.t("user.auto_track_options.immediately"),
    value: 0
  }, {
    name: I18n.t("user.auto_track_options.after_30_seconds"),
    value: 30000
  }, {
    name: I18n.t("user.auto_track_options.after_1_minute"),
    value: 60000
  }, {
    name: I18n.t("user.auto_track_options.after_2_minutes"),
    value: 120000
  }, {
    name: I18n.t("user.auto_track_options.after_3_minutes"),
    value: 180000
  }, {
    name: I18n.t("user.auto_track_options.after_4_minutes"),
    value: 240000
  }, {
    name: I18n.t("user.auto_track_options.after_5_minutes"),
    value: 300000
  }, {
    name: I18n.t("user.auto_track_options.after_10_minutes"),
    value: 600000
  }];
  notificationLevelsForReplying = [{
    name: I18n.t("topic.notifications.watching.title"),
    value: NotificationLevels.WATCHING
  }, {
    name: I18n.t("topic.notifications.tracking.title"),
    value: NotificationLevels.TRACKING
  }, {
    name: I18n.t("topic.notifications.regular.title"),
    value: NotificationLevels.REGULAR
  }];
  considerNewTopicOptions = [{
    name: I18n.t("user.new_topic_duration.not_viewed"),
    value: -1
  }, {
    name: I18n.t("user.new_topic_duration.after_1_day"),
    value: 60 * 24
  }, {
    name: I18n.t("user.new_topic_duration.after_2_days"),
    value: 60 * 48
  }, {
    name: I18n.t("user.new_topic_duration.after_1_week"),
    value: 7 * 60 * 24
  }, {
    name: I18n.t("user.new_topic_duration.after_2_weeks"),
    value: 2 * 7 * 60 * 24
  }, {
    name: I18n.t("user.new_topic_duration.last_here"),
    value: -2
  }];
  get canSee() {
    return this.currentUser.id === this.model.id;
  }
  get selectedTags() {
    return [].concat(this.model.watched_tags, this.model.watching_first_post_tags, this.model.tracked_tags, this.model.muted_tags).filter(t => t);
  }
  static #_4 = dt7948.n(this.prototype, "selectedTags", [computed("model.watched_tags.[]", "model.watching_first_post_tags.[]", "model.tracked_tags.[]", "model.muted_tags.[]")]);
  get showMutePrecedenceSetting() {
    const show = this.model.watchedCategories?.length > 0 && this.model.muted_tags?.length > 0 || this.model.watched_tags?.length > 0 && this.model.mutedCategories?.length > 0;
    if (show && this.model.user_option.watched_precedence_over_muted === null) {
      this.model.user_option.watched_precedence_over_muted = this.siteSettings.watched_precedence_over_muted;
    }
    return show;
  }
  static #_5 = dt7948.n(this.prototype, "showMutePrecedenceSetting", [computed("model.watchedCategories", "model.mutedCategories", "model.watched_tags.[]", "model.muted_tags.[]")]);
  get selectedCategories() {
    return [].concat(this.model.watchedCategories, this.model.watchedFirstPostCategories, this.model.trackedCategories, this.siteSettings.mute_all_categories_by_default ? this.model.regularCategories : this.model.mutedCategories).filter(Boolean);
  }
  static #_6 = dt7948.n(this.prototype, "selectedCategories", [computed("model.watchedCategories", "model.watchedFirstPostCategories", "model.trackedCategories", "model.mutedCategories", "model.regularCategories", "siteSettings.mute_all_categories_by_default")]);
  get hideMutedTags() {
    return this.siteSettings.remove_muted_tags_from_latest !== "never";
  }
  static #_7 = dt7948.n(this.prototype, "hideMutedTags", [computed("siteSettings.remove_muted_tags_from_latest")]);
  get canSave() {
    return this.canSee || this.currentUser.admin;
  }
  get saveAttrNames() {
    const attrs = ["new_topic_duration_minutes", "auto_track_topics_after_msecs", "notification_level_when_replying", this.siteSettings.mute_all_categories_by_default ? "regular_category_ids" : "muted_category_ids", "watched_category_ids", "tracked_category_ids", "watched_first_post_category_ids", "watched_precedence_over_muted", "topics_unread_when_closed"];
    if (this.siteSettings.tagging_enabled) {
      attrs.push("muted_tags", "tracked_tags", "watched_tags", "watching_first_post_tags");
    }
    return attrs;
  }
  static #_8 = dt7948.n(this.prototype, "saveAttrNames", [computed("siteSettings.tagging_enabled", "siteSettings.mute_all_categories_by_default")]);
  save() {
    this.saved = false;
    return this.model.save(this.saveAttrNames).then(() => {
      this.saved = true;
    }).catch(popupAjaxError);
  }
  static #_9 = dt7948.n(this.prototype, "save", [action]);
}