/* import __COLOCATED_TEMPLATE__ from './profile-tab-content.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DoNotDisturbModal from "discourse/components/modal/do-not-disturb";
import UserStatusModal from "discourse/components/modal/user-status";
import { ajax } from "discourse/lib/ajax";
import DoNotDisturb from "discourse/lib/do-not-disturb";
import { userPath } from "discourse/lib/url";
import TEMPLATE from "./profile-tab-content.hbs";
import { setComponentTemplate } from "@ember/component";
const _extraItems = [];
export function addUserMenuProfileTabItem(item) {
  _extraItems.push(item);
}
export function resetUserMenuProfileTabItems() {
  _extraItems.clear();
}
export default class UserMenuProfileTabContent extends Component {
  static #_ = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_2 = dt7948.g(this.prototype, "siteSettings", [service]);
  #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
  static #_3 = dt7948.g(this.prototype, "userStatus", [service]);
  #userStatus = (dt7948.i(this, "userStatus"), void 0);
  static #_4 = dt7948.g(this.prototype, "modal", [service]);
  #modal = (dt7948.i(this, "modal"), void 0);
  saving = false;
  get showToggleAnonymousButton() {
    return this.currentUser.can_post_anonymously || this.currentUser.is_anonymous;
  }
  get isInDoNotDisturb() {
    return !!this.#doNotDisturbUntilDate;
  }
  get doNotDisturbDateTime() {
    return this.#doNotDisturbUntilDate.getTime();
  }
  get showDoNotDisturbEndDate() {
    return !DoNotDisturb.isEternal(this.currentUser.get("do_not_disturb_until"));
  }
  get extraItems() {
    return _extraItems;
  }
  get #doNotDisturbUntilDate() {
    if (!this.currentUser.get("do_not_disturb_until")) {
      return;
    }
    const date = new Date(this.currentUser.get("do_not_disturb_until"));
    if (date < new Date()) {
      return;
    }
    return date;
  }
  doNotDisturbClick() {
    if (this.saving) {
      return;
    }
    this.saving = true;
    if (this.currentUser.do_not_disturb_until) {
      return this.currentUser.leaveDoNotDisturb().finally(() => {
        this.saving = false;
      });
    } else {
      this.saving = false;
      this.args.closeUserMenu();
      this.modal.show(DoNotDisturbModal);
    }
  }
  static #_5 = dt7948.n(this.prototype, "doNotDisturbClick", [action]);
  setUserStatusClick() {
    this.args.closeUserMenu();
    this.modal.show(UserStatusModal, {
      model: {
        status: this.currentUser.status,
        pauseNotifications: this.currentUser.isInDoNotDisturb(),
        saveAction: (status, pauseNotifications) => this.userStatus.set(status, pauseNotifications),
        deleteAction: () => this.userStatus.clear()
      }
    });
  }
  static #_6 = dt7948.n(this.prototype, "setUserStatusClick", [action]);
  async toggleAnonymous() {
    await ajax(userPath("toggle-anon"), {
      type: "POST"
    });
    window.location.reload();
  }
  static #_7 = dt7948.n(this.prototype, "toggleAnonymous", [action]);
}
setComponentTemplate(TEMPLATE, UserMenuProfileTabContent);