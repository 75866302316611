/* import __COLOCATED_TEMPLATE__ from './more-link.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DiscourseURL from "discourse/lib/url";
import TEMPLATE from "./more-link.hbs";
import { setComponentTemplate } from "@ember/component";
export default class MoreLink extends Component {
  static #_ = dt7948.g(this.prototype, "search", [service]);
  #search = (dt7948.i(this, "search"), void 0);
  get topicResults() {
    const topicResults = this.args.resultTypes.filter(resultType => resultType.type === "topic");
    return topicResults[0];
  }
  get moreUrl() {
    return this.topicResults.moreUrl && this.topicResults.moreUrl();
  }
  transitionToMoreUrl(event) {
    event.preventDefault();
    this.args.closeSearchMenu();
    DiscourseURL.routeTo(this.moreUrl);
    return false;
  }
  static #_2 = dt7948.n(this.prototype, "transitionToMoreUrl", [action]);
  moreOfType(type) {
    this.args.updateTypeFilter(type);
    this.args.triggerSearch();
    this.args.closeSearchMenu();
  }
  static #_3 = dt7948.n(this.prototype, "moreOfType", [action]);
  onKeyup(e) {
    if (e.key === "Escape") {
      this.args.closeSearchMenu();
      e.preventDefault();
      return false;
    }
    this.search.handleArrowUpOrDown(e);
  }
  static #_4 = dt7948.n(this.prototype, "onKeyup", [action]);
}
setComponentTemplate(TEMPLATE, MoreLink);