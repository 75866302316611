import Helper from "@ember/component/helper";
import { scheduleOnce } from "@ember/runloop";
import { service } from "@ember/service";
export default class HideApplicationSidebar extends Helper {
  static #_ = dt7948.g(this.prototype, "sidebarState", [service]);
  #sidebarState = (dt7948.i(this, "sidebarState"), void 0);
  constructor() {
    super(...arguments);
    scheduleOnce("afterRender", this, this.registerHider);
  }
  registerHider() {
    this.sidebarState.registerHider(this);
  }
  compute() {}
}