/* import __COLOCATED_TEMPLATE__ from './change-tags.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import TEMPLATE from "./change-tags.hbs";
import { setComponentTemplate } from "@ember/component";
export default class ChangeTags extends Component {
  static #_ = dt7948.g(this.prototype, "tags", [tracked], function () {
    return [];
  });
  #tags = (dt7948.i(this, "tags"), void 0);
}
setComponentTemplate(TEMPLATE, ChangeTags);