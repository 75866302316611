/* import __COLOCATED_TEMPLATE__ from './bookmark.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { and, notEmpty } from "@ember/object/computed";
import { service } from "@ember/service";
import ItsATrap from "@discourse/itsatrap";
import { Promise } from "rsvp";
import { CLOSE_INITIATED_BY_CLICK_OUTSIDE } from "discourse/components/d-modal";
import { extractError } from "discourse/lib/ajax-error";
import { formattedReminderTime } from "discourse/lib/bookmark";
import KeyboardShortcuts from "discourse/lib/keyboard-shortcuts";
import { sanitize } from "discourse/lib/text";
import { defaultTimeShortcuts, TIME_SHORTCUT_TYPES } from "discourse/lib/time-shortcut";
import { now, parseCustomDatetime, startOfDay } from "discourse/lib/time-utils";
import { AUTO_DELETE_PREFERENCES } from "discourse/models/bookmark";
import discourseLater from "discourse-common/lib/later";
import I18n from "discourse-i18n";
import TEMPLATE from "./bookmark.hbs";
import { setComponentTemplate } from "@ember/component";
const BOOKMARK_BINDINGS = {
  enter: {
    handler: "saveAndClose"
  },
  "d d": {
    handler: "delete"
  }
};
export default class BookmarkModal extends Component {
  static #_ = dt7948.g(this.prototype, "dialog", [service]);
  #dialog = (dt7948.i(this, "dialog"), void 0);
  static #_2 = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_3 = dt7948.g(this.prototype, "site", [service]);
  #site = (dt7948.i(this, "site"), void 0);
  static #_4 = dt7948.g(this.prototype, "bookmarkApi", [service]);
  #bookmarkApi = (dt7948.i(this, "bookmarkApi"), void 0);
  static #_5 = dt7948.g(this.prototype, "postDetectedLocalDate", [tracked], function () {
    return null;
  });
  #postDetectedLocalDate = (dt7948.i(this, "postDetectedLocalDate"), void 0);
  static #_6 = dt7948.g(this.prototype, "postDetectedLocalTime", [tracked], function () {
    return null;
  });
  #postDetectedLocalTime = (dt7948.i(this, "postDetectedLocalTime"), void 0);
  static #_7 = dt7948.g(this.prototype, "postDetectedLocalTimezone", [tracked], function () {
    return null;
  });
  #postDetectedLocalTimezone = (dt7948.i(this, "postDetectedLocalTimezone"), void 0);
  static #_8 = dt7948.g(this.prototype, "prefilledDatetime", [tracked], function () {
    return null;
  });
  #prefilledDatetime = (dt7948.i(this, "prefilledDatetime"), void 0);
  static #_9 = dt7948.g(this.prototype, "flash", [tracked], function () {
    return null;
  });
  #flash = (dt7948.i(this, "flash"), void 0);
  static #_10 = dt7948.g(this.prototype, "userTimezone", [tracked], function () {
    return this.currentUser.user_option.timezone;
  });
  #userTimezone = (dt7948.i(this, "userTimezone"), void 0);
  static #_11 = dt7948.g(this.prototype, "showOptions", [tracked], function () {
    return this.args.model.bookmark.id ? true : false;
  });
  #showOptions = (dt7948.i(this, "showOptions"), void 0);
  static #_12 = dt7948.g(this.prototype, "userHasTimezoneSet", [notEmpty("userTimezone")]);
  #userHasTimezoneSet = (dt7948.i(this, "userHasTimezoneSet"), void 0);
  static #_13 = dt7948.g(this.prototype, "showDelete", [notEmpty("bookmark.id")]);
  #showDelete = (dt7948.i(this, "showDelete"), void 0);
  static #_14 = dt7948.g(this.prototype, "editingExistingBookmark", [notEmpty("bookmark.id")]);
  #editingExistingBookmark = (dt7948.i(this, "editingExistingBookmark"), void 0);
  static #_15 = dt7948.g(this.prototype, "existingBookmarkHasReminder", [and("bookmark.id", "bookmark.reminderAt")]);
  #existingBookmarkHasReminder = (dt7948.i(this, "existingBookmarkHasReminder"), void 0);
  static #_16 = dt7948.g(this.prototype, "_closeWithoutSaving", [tracked], function () {
    return false;
  });
  #_closeWithoutSaving = (dt7948.i(this, "_closeWithoutSaving"), void 0);
  static #_17 = dt7948.g(this.prototype, "_savingBookmarkManually", [tracked], function () {
    return false;
  });
  #_savingBookmarkManually = (dt7948.i(this, "_savingBookmarkManually"), void 0);
  static #_18 = dt7948.g(this.prototype, "_saving", [tracked], function () {
    return false;
  });
  #_saving = (dt7948.i(this, "_saving"), void 0);
  static #_19 = dt7948.g(this.prototype, "_deleting", [tracked], function () {
    return false;
  });
  #_deleting = (dt7948.i(this, "_deleting"), void 0);
  _itsatrap = new ItsATrap();
  get bookmark() {
    return this.args.model.bookmark;
  }
  get modalTitle() {
    return I18n.t(this.bookmark.id ? "bookmarks.edit" : "bookmarks.create");
  }
  get autoDeletePreferences() {
    return Object.keys(AUTO_DELETE_PREFERENCES).map(key => {
      return {
        id: AUTO_DELETE_PREFERENCES[key],
        name: I18n.t(`bookmarks.auto_delete_preference.${key.toLowerCase()}`)
      };
    });
  }
  get showExistingReminderAt() {
    return this.bookmark.reminderAt && Date.parse(this.bookmark.reminderAt) > new Date().getTime();
  }
  get existingReminderAtFormatted() {
    return formattedReminderTime(this.bookmark.reminderAt, this.userTimezone);
  }
  get timeOptions() {
    const options = defaultTimeShortcuts(this.userTimezone);
    if (this.showPostLocalDate) {
      options.push({
        icon: "globe-americas",
        id: TIME_SHORTCUT_TYPES.POST_LOCAL_DATE,
        label: "time_shortcut.post_local_date",
        time: this.#parsedPostLocalDateTime(),
        timeFormatKey: "dates.long_no_year",
        hidden: false
      });
    }
    return options;
  }
  get showPostLocalDate() {
    if (!this.postDetectedLocalTime || !this.postDetectedLocalDate) {
      return false;
    }
    if (this.#parsedPostLocalDateTime() < now(this.userTimezone)) {
      return false;
    }
    return true;
  }
  get hiddenTimeShortcutOptions() {
    if (this.editingExistingBookmark && !this.existingBookmarkHasReminder) {
      return [TIME_SHORTCUT_TYPES.NONE];
    }
    return [];
  }
  get customTimeShortcutLabels() {
    const labels = {};
    if (this.existingBookmarkHasReminder) {
      labels[TIME_SHORTCUT_TYPES.NONE] = "bookmarks.remove_reminder_keep_bookmark";
    }
    return labels;
  }
  willDestroy() {
    super.willDestroy(...arguments);
    this._itsatrap?.destroy();
    this._itsatrap = null;
    KeyboardShortcuts.unpause();
  }
  didInsert() {
    discourseLater(() => {
      if (this.site.isMobileDevice) {
        document.getElementById("bookmark-name").blur();
      }
    });
    if (!this.args.model.bookmark.id) {
      document.getElementById("tap_tile_none").classList.add("active");
    }
    this.#bindKeyboardShortcuts();
    this.#initializeExistingBookmarkData();
    this.#loadPostLocalDates();
  }
  static #_20 = dt7948.n(this.prototype, "didInsert", [action]);
  saveAndClose() {
    this.flash = null;
    if (this._saving || this._deleting) {
      return;
    }
    this._saving = true;
    this._savingBookmarkManually = true;
    return this.#saveBookmark().then(() => this.args.closeModal()).catch(error => this.#handleSaveError(error)).finally(() => {
      this._saving = false;
    });
  }
  static #_21 = dt7948.n(this.prototype, "saveAndClose", [action]);
  toggleShowOptions() {
    this.showOptions = !this.showOptions;
  }
  static #_22 = dt7948.n(this.prototype, "toggleShowOptions", [action]);
  onTimeSelected(type, time) {
    this.bookmark.selectedReminderType = type;
    this.bookmark.selectedDatetime = time;
    this.bookmark.reminderAt = time;

    // If the type is custom, we need to wait for the user to click save, as
    // they could still be adjusting the date and time
    if (![TIME_SHORTCUT_TYPES.CUSTOM, TIME_SHORTCUT_TYPES.RELATIVE].includes(type)) {
      return this.saveAndClose();
    }
  }
  static #_23 = dt7948.n(this.prototype, "onTimeSelected", [action]);
  closingModal(closeModalArgs) {
    // If the user clicks outside the modal we save automatically for them,
    // as long as they are not already saving manually or deleting the bookmark.
    if (closeModalArgs.initiatedBy === CLOSE_INITIATED_BY_CLICK_OUTSIDE && !this._closeWithoutSaving && !this._savingBookmarkManually) {
      this.#saveBookmark().catch(e => this.#handleSaveError(e)).then(() => {
        this.args.closeModal(closeModalArgs);
      });
    } else {
      this.args.closeModal(closeModalArgs);
    }
  }
  static #_24 = dt7948.n(this.prototype, "closingModal", [action]);
  closeWithoutSavingBookmark() {
    this._closeWithoutSaving = true;
    this.args.closeModal({
      closeWithoutSaving: this._closeWithoutSaving
    });
  }
  static #_25 = dt7948.n(this.prototype, "closeWithoutSavingBookmark", [action]);
  delete() {
    if (!this.bookmark.id) {
      return;
    }
    this._deleting = true;
    const deleteAction = () => {
      this._closeWithoutSaving = true;
      this.#deleteBookmark().then(() => {
        this._deleting = false;
        this.args.closeModal({
          closeWithoutSaving: this._closeWithoutSaving
        });
      }).catch(error => this.#handleSaveError(error));
    };
    if (this.existingBookmarkHasReminder) {
      this.dialog.deleteConfirm({
        message: I18n.t("bookmarks.confirm_delete"),
        didConfirm: () => deleteAction()
      });
    } else {
      deleteAction();
    }
  }
  static #_26 = dt7948.n(this.prototype, "delete", [action]);
  #parsedPostLocalDateTime() {
    let parsedPostLocalDate = parseCustomDatetime(this.postDetectedLocalDate, this.postDetectedLocalTime, this.userTimezone, this.postDetectedLocalTimezone);
    if (!this.postDetectedLocalTime) {
      return startOfDay(parsedPostLocalDate);
    }
    return parsedPostLocalDate;
  }
  #saveBookmark() {
    if (this.bookmark.selectedReminderType === TIME_SHORTCUT_TYPES.CUSTOM) {
      if (!this.bookmark.reminderAtISO) {
        return Promise.reject(I18n.t("bookmarks.invalid_custom_datetime"));
      }
    }
    if (this.editingExistingBookmark) {
      return this.bookmarkApi.update(this.bookmark).then(() => {
        this.args.model.afterSave?.(this.bookmark);
      });
    } else {
      return this.bookmarkApi.create(this.bookmark).then(() => {
        this.args.model.afterSave?.(this.bookmark);
      });
    }
  }
  #deleteBookmark() {
    return this.bookmarkApi.delete(this.bookmark.id).then(response => {
      this.args.model.afterDelete?.(response, this.bookmark.id);
    });
  }
  #handleSaveError(error) {
    this._savingBookmarkManually = false;
    if (typeof error === "string") {
      this.flash = sanitize(error);
    } else {
      this.flash = sanitize(extractError(error));
    }
  }
  #bindKeyboardShortcuts() {
    KeyboardShortcuts.pause();
    Object.keys(BOOKMARK_BINDINGS).forEach(shortcut => {
      this._itsatrap.bind(shortcut, () => {
        const binding = BOOKMARK_BINDINGS[shortcut];
        this[binding.handler]();
        return false;
      });
    });
  }
  #initializeExistingBookmarkData() {
    if (!this.existingBookmarkHasReminder || !this.editingExistingBookmark) {
      return;
    }
    this.prefilledDatetime = this.bookmark.reminderAt;
    this.bookmark.selectedDatetime = parseCustomDatetime(this.prefilledDatetime, null, this.userTimezone);
  }

  // If we detect we are bookmarking a post which has local-date data
  // in it, we can preload that date + time into the form to use as the
  // bookmark reminder date + time.
  #loadPostLocalDates() {
    if (this.bookmark.bookmarkableType !== "Post") {
      return;
    }
    const postEl = document.querySelector(`[data-post-id="${this.bookmark.bookmarkableId}"]`);
    const localDateEl = postEl?.querySelector(".discourse-local-date");
    if (localDateEl) {
      this.postDetectedLocalDate = localDateEl.dataset.date;
      this.postDetectedLocalTime = localDateEl.dataset.time;
      this.postDetectedLocalTimezone = localDateEl.dataset.timezone;
    }
  }
}
setComponentTemplate(TEMPLATE, BookmarkModal);