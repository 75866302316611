import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import raw from "discourse/helpers/raw";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class NewListHeaderControlsWrapper extends Component {
  click(e1) {
    const target1 = e1.target;
    if (target1.closest("button.topics-replies-toggle.--all")) {
      this.args.changeNewListSubset(null);
    } else if (target1.closest("button.topics-replies-toggle.--topics")) {
      this.args.changeNewListSubset("topics");
    } else if (target1.closest("button.topics-replies-toggle.--replies")) {
      this.args.changeNewListSubset("replies");
    }
  }
  static #_ = dt7948.n(this.prototype, "click", [action]);
  static #_2 = setComponentTemplate(createTemplateFactory(
  /*
    
      <div
        {{! template-lint-disable no-invalid-interactive }}
        {{on "click" this.click}}
        class="topic-replies-toggle-wrapper"
      >
        {{raw
          "list/new-list-header-controls"
          current=@current
          newRepliesCount=@newRepliesCount
          newTopicsCount=@newTopicsCount
          noStaticLabel=true
        }}
      </div>
    
  */
  {
    "id": "8c69zD0d",
    "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"topic-replies-toggle-wrapper\"],[4,[32,0],[\"click\",[30,0,[\"click\"]]],null],[12],[1,\"\\n      \"],[1,[28,[32,1],[\"list/new-list-header-controls\"],[[\"current\",\"newRepliesCount\",\"newTopicsCount\",\"noStaticLabel\"],[[30,1],[30,2],[30,3],true]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@current\",\"@newRepliesCount\",\"@newTopicsCount\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/new-list-header-controls-wrapper.js",
    "scope": () => [on, raw],
    "isStrictMode": true
  }), this);
}