/* import __COLOCATED_TEMPLATE__ from './json-schema-editor.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { create } from "virtual-dom";
import loadScript from "discourse/lib/load-script";
import { iconNode } from "discourse-common/lib/icon-library";
import { afterRender } from "discourse-common/utils/decorators";
import TEMPLATE from "./json-schema-editor.hbs";
import { setComponentTemplate } from "@ember/component";
export default class JsonSchemaEditorModal extends Component {
  static #_ = dt7948.g(this.prototype, "editor", [tracked], function () {
    return null;
  });
  #editor = (dt7948.i(this, "editor"), void 0);
  static #_2 = dt7948.g(this.prototype, "value", [tracked], function () {
    return this.args.model.value;
  });
  #value = (dt7948.i(this, "value"), void 0);
  static #_3 = dt7948.g(this.prototype, "flash", [tracked]);
  #flash = (dt7948.i(this, "flash"), void 0);
  static #_4 = dt7948.g(this.prototype, "flashType", [tracked]);
  #flashType = (dt7948.i(this, "flashType"), void 0);
  get settingName() {
    return this.args.model.settingName.replace(/\_/g, " ");
  }
  buildJsonEditor(editor) {
    loadScript("/javascripts/jsoneditor.js").then(this._loadEditor.bind(this, editor));
  }
  static #_5 = dt7948.n(this.prototype, "buildJsonEditor", [action]);
  teardownJsonEditor() {
    this.editor?.destroy();
  }
  static #_6 = dt7948.n(this.prototype, "teardownJsonEditor", [action]);
  saveChanges() {
    const errors = this.editor.validate();
    if (!errors.length) {
      this.value = JSON.stringify(this.editor.getValue());
      this.args.model.updateValue(this.value);
      this.args.closeModal();
    } else {
      this.flash = errors.mapBy("message").join("\n");
      this.flashType = "error";
    }
  }
  static #_7 = dt7948.n(this.prototype, "saveChanges", [action]);
  _loadEditor(editor) {
    let {
      JSONEditor
    } = window;
    JSONEditor.defaults.options.theme = "barebones";
    JSONEditor.defaults.iconlibs = {
      discourseIcons: DiscourseJsonSchemaEditorIconlib
    };
    JSONEditor.defaults.options.iconlib = "discourseIcons";
    this.editor = new JSONEditor(editor, {
      schema: this.args.model.jsonSchema,
      disable_array_delete_all_rows: true,
      disable_array_delete_last_row: true,
      disable_array_reorder: false,
      disable_array_copy: false,
      enable_array_copy: true,
      disable_edit_json: true,
      disable_properties: true,
      disable_collapse: false,
      show_errors: "never",
      startval: this.value ? JSON.parse(this.value) : null
    });
  }
  static #_8 = dt7948.n(this.prototype, "_loadEditor", [afterRender]);
}
class DiscourseJsonSchemaEditorIconlib {
  constructor() {
    this.mapping = {
      delete: "trash-alt",
      add: "plus",
      moveup: "arrow-up",
      movedown: "arrow-down",
      moveleft: "chevron-left",
      moveright: "chevron-right",
      copy: "copy",
      collapse: "chevron-down",
      expand: "chevron-up"
    };
  }
  getIcon(key) {
    if (!this.mapping[key]) {
      return;
    }
    return create(iconNode(this.mapping[key]));
  }
}
setComponentTemplate(TEMPLATE, JsonSchemaEditorModal);