import Controller, { inject as controller } from "@ember/controller";
import { computed } from "@ember/object";
import { service } from "@ember/service";
import I18n from "discourse-i18n";
export default class extends Controller {
  static #_ = dt7948.g(this.prototype, "router", [service]);
  #router = (dt7948.i(this, "router"), void 0);
  static #_2 = dt7948.g(this.prototype, "user", [controller]);
  #user = (dt7948.i(this, "user"), void 0);
  get viewingSelf() {
    return this.user.viewingSelf;
  }
  get showWarningsWarning() {
    return this.router.currentRoute.name === "userPrivateMessages.user.warnings" && !this.viewingSelf && !this.currentUser.isAdmin;
  }
  static #_3 = dt7948.n(this.prototype, "showWarningsWarning", [computed("viewingSelf", "router.currentRoute.name", "currentUser.admin")]);
  get newLinkText() {
    return this.#linkText("new");
  }
  static #_4 = dt7948.n(this.prototype, "newLinkText", [computed("pmTopicTrackingState.newIncoming.[]", "pmTopicTrackingState.statesModificationCounter", "pmTopicTrackingState.isTracking")]);
  get unreadLinkText() {
    return this.#linkText("unread");
  }
  static #_5 = dt7948.n(this.prototype, "unreadLinkText", [computed("pmTopicTrackingState.newIncoming.[]", "pmTopicTrackingState.statesModificationCounter", "pmTopicTrackingState.isTracking")]);
  #linkText(type) {
    const count = this.pmTopicTrackingState?.lookupCount(type, {
      inboxFilter: "user"
    });
    if (count === 0) {
      return I18n.t(`user.messages.${type}`);
    } else {
      return I18n.t(`user.messages.${type}_with_count`, {
        count
      });
    }
  }
}