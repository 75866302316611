/* import __COLOCATED_TEMPLATE__ from './topic-timeline.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import optionalService from "discourse/lib/optional-service";
import { bind } from "discourse-common/utils/decorators";
import TEMPLATE from "./topic-timeline.hbs";
import { setComponentTemplate } from "@ember/component";
export default class TopicTimeline extends Component {
  static #_ = dt7948.g(this.prototype, "siteSettings", [service]);
  #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
  static #_2 = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_3 = dt7948.g(this.prototype, "enteredIndex", [tracked], function () {
    return this.args.enteredIndex;
  });
  #enteredIndex = (dt7948.i(this, "enteredIndex"), void 0);
  static #_4 = dt7948.g(this.prototype, "docked", [tracked], function () {
    return false;
  });
  #docked = (dt7948.i(this, "docked"), void 0);
  static #_5 = dt7948.g(this.prototype, "dockedBottom", [tracked], function () {
    return false;
  });
  #dockedBottom = (dt7948.i(this, "dockedBottom"), void 0);
  adminTools = optionalService();
  constructor() {
    super(...arguments);
    if (this.args.prevEvent) {
      this.enteredIndex = this.args.prevEvent.postIndex - 1;
    }
  }
  get createdAt() {
    return new Date(this.args.model.created_at);
  }
  get classes() {
    const classes = [];
    if (this.args.fullscreen) {
      classes.push("timeline-fullscreen");
    }
    if (this.docked) {
      classes.push("timeline-docked");
      if (this.dockedBottom) {
        classes.push("timeline-docked-bottom");
      }
    }
    return classes.join(" ");
  }
  addShowClass(element) {
    if (this.args.fullscreen && !this.args.addShowClass) {
      element.classList.add("show");
    }
  }
  static #_6 = dt7948.n(this.prototype, "addShowClass", [bind]);
  setDocked(value) {
    if (this.docked !== value) {
      this.docked = value;
    }
  }
  static #_7 = dt7948.n(this.prototype, "setDocked", [action]);
  setDockedBottom(value) {
    if (this.dockedBottom !== value) {
      this.dockedBottom = value;
    }
  }
  static #_8 = dt7948.n(this.prototype, "setDockedBottom", [action]);
}
setComponentTemplate(TEMPLATE, TopicTimeline);