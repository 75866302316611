import Service, { service } from "@ember/service";
import { disableImplicitInjections } from "discourse/lib/implicit-injections";
const NavigationMenu = dt7948.c(class NavigationMenu extends Service {
  static #_ = dt7948.g(this.prototype, "site", [service]);
  #site = (dt7948.i(this, "site"), void 0);
  static #_2 = dt7948.g(this.prototype, "siteSettings", [service]);
  #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
  get isDesktopDropdownMode() {
    const headerDropdownMode = this.siteSettings.navigation_menu === "header dropdown";
    return this.site.desktopView && headerDropdownMode;
  }
}, [disableImplicitInjections]);
export default NavigationMenu;