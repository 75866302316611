/* import __COLOCATED_TEMPLATE__ from './reject-reason-reviewable.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import TEMPLATE from "./reject-reason-reviewable.hbs";
import { setComponentTemplate } from "@ember/component";
export default class RejectReasonReviewableModal extends Component {
  rejectReason;
  sendEmail = false;
  async perform() {
    this.args.model.reviewable.setProperties({
      rejectReason: this.rejectReason,
      sendEmail: this.sendEmail
    });
    this.args.closeModal();
    await this.args.model.performConfirmed(this.args.model.action);
  }
  static #_ = dt7948.n(this.prototype, "perform", [action]);
}
setComponentTemplate(TEMPLATE, RejectReasonReviewableModal);