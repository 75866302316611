/* import __COLOCATED_TEMPLATE__ from './forgot-password.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import { isEmpty } from "@ember/utils";
import { ajax } from "discourse/lib/ajax";
import { extractError } from "discourse/lib/ajax-error";
import cookie from "discourse/lib/cookie";
import { escapeExpression } from "discourse/lib/utilities";
import getURL from "discourse-common/lib/get-url";
import I18n from "discourse-i18n";
import TEMPLATE from "./forgot-password.hbs";
import { setComponentTemplate } from "@ember/component";
export default class ForgotPassword extends Component {
  static #_ = dt7948.g(this.prototype, "siteSettings", [service]);
  #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
  static #_2 = dt7948.g(this.prototype, "emailOrUsername", [tracked], function () {
    return cookie("email") || this.args.model?.emailOrUsername || "";
  });
  #emailOrUsername = (dt7948.i(this, "emailOrUsername"), void 0);
  static #_3 = dt7948.g(this.prototype, "disabled", [tracked], function () {
    return false;
  });
  #disabled = (dt7948.i(this, "disabled"), void 0);
  static #_4 = dt7948.g(this.prototype, "helpSeen", [tracked], function () {
    return false;
  });
  #helpSeen = (dt7948.i(this, "helpSeen"), void 0);
  static #_5 = dt7948.g(this.prototype, "offerHelp", [tracked]);
  #offerHelp = (dt7948.i(this, "offerHelp"), void 0);
  static #_6 = dt7948.g(this.prototype, "flash", [tracked]);
  #flash = (dt7948.i(this, "flash"), void 0);
  get submitDisabled() {
    if (this.disabled) {
      return true;
    } else if (this.siteSettings.hide_email_address_taken) {
      return !this.emailOrUsername.includes("@");
    } else {
      return isEmpty(this.emailOrUsername.trim());
    }
  }
  updateEmailOrUsername(event) {
    this.emailOrUsername = event.target.value;
  }
  static #_7 = dt7948.n(this.prototype, "updateEmailOrUsername", [action]);
  help() {
    this.offerHelp = I18n.t("forgot_password.help", {
      basePath: getURL("")
    });
    this.helpSeen = true;
  }
  static #_8 = dt7948.n(this.prototype, "help", [action]);
  async resetPassword() {
    if (this.submitDisabled) {
      return false;
    }
    this.disabled = true;
    this.flash = null;
    try {
      const data = await ajax("/session/forgot_password", {
        data: {
          login: this.emailOrUsername.trim()
        },
        type: "POST"
      });
      const emailOrUsername = escapeExpression(this.emailOrUsername);
      let key = "forgot_password.complete";
      key += emailOrUsername.match(/@/) ? "_email" : "_username";
      if (data.user_found === false) {
        key += "_not_found";
        this.flash = htmlSafe(I18n.t(key, {
          email: emailOrUsername,
          username: emailOrUsername
        }));
      } else {
        key += data.user_found ? "_found" : "";
        this.emailOrUsername = "";
        this.offerHelp = I18n.t(key, {
          email: emailOrUsername,
          username: emailOrUsername
        });
        this.helpSeen = !data.user_found;
      }
    } catch (error) {
      this.flash = extractError(error);
    } finally {
      this.disabled = false;
    }
  }
  static #_9 = dt7948.n(this.prototype, "resetPassword", [action]);
}
setComponentTemplate(TEMPLATE, ForgotPassword);