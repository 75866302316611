/* import __COLOCATED_TEMPLATE__ from './reorder-categories.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import TEMPLATE from "./reorder-categories.hbs";
import { setComponentTemplate } from "@ember/component";
class Entry {
  static #_ = dt7948.g(this.prototype, "position", [tracked]);
  #position = (dt7948.i(this, "position"), void 0);
  constructor(_ref) {
    let {
      position,
      depth,
      category,
      descendantCount
    } = _ref;
    this.position = position;
    this.depth = depth;
    this.category = category;
    this.descendantCount = descendantCount;
  }
}
export default class ReorderCategories extends Component {
  static #_ = dt7948.g(this.prototype, "site", [service]);
  #site = (dt7948.i(this, "site"), void 0);
  static #_2 = dt7948.g(this.prototype, "changed", [tracked], function () {
    return false;
  });
  #changed = (dt7948.i(this, "changed"), void 0);
  static #_3 = dt7948.g(this.prototype, "entries", [tracked], function () {
    return this.reorder();
  });
  #entries = (dt7948.i(this, "entries"), void 0);
  get sortedEntries() {
    return this.entries.sortBy("position");
  }
  reorder(from) {
    from ??= this.site.categories.map(category => ({
      category,
      position: category.position
    }));
    return this.createEntries([...from.sortBy("position")]);
  }

  /**
   * 1. Make sure all categories have unique position numbers.
   * 2. Place sub-categories after their parent categories while maintaining
   *    the same relative order.
   *
   *    e.g.
   *      parent/c2/c1          parent
   *      parent/c1             parent/c1
   *      parent          =>    parent/c2
   *      other                 parent/c2/c1
   *      parent/c2             other
   **/
  createEntries(from) {
    let position = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    let categoryId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    let depth = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
    let result = [];
    for (const entry of from) {
      if (categoryId === null && !entry.category.parent_category_id || entry.category.parent_category_id === categoryId) {
        const descendants = this.createEntries(from, position + result.length + 1, entry.category.id, depth + 1);
        result = [...result, new Entry({
          position: position + result.length,
          depth,
          category: entry.category,
          descendantCount: descendants.length
        }), ...descendants];
      }
    }
    return result;
  }
  move(entry, delta) {
    let targetPosition = entry.position + delta;

    // Adjust target position for sub-categories
    if (delta > 0) {
      // Moving down (position gets larger)
      if (entry.descendantCount) {
        // This category has subcategories, adjust targetPosition to account for them
        if (entry.descendantCount >= delta) {
          // Only apply offset if target position is occupied by a subcategory
          // Seems weird but fixes a UX quirk
          targetPosition += entry.descendantCount;
        }
      }
    } else {
      // Moving up (position gets smaller)
      const ancestors = this.sortedEntries[targetPosition]?.category?.ancestors;
      if (ancestors) {
        // Target category is a subcategory, adjust targetPosition to account for ancestors
        const highestAncestorEntry = this.sortedEntries.findBy("category.id", ancestors[0].id);
        targetPosition = highestAncestorEntry.position;
      }
    }

    // Adjust target position for range bounds
    if (targetPosition >= this.entries.length) {
      // Set to max
      targetPosition = this.entries.length - 1;
    } else if (targetPosition < 0) {
      // Set to min
      targetPosition = 0;
    }

    // Update other categories between current and target position
    for (const e of this.sortedEntries) {
      if (delta > 0) {
        // Moving down (position gets larger)
        if (e.position > entry.position && e.position <= targetPosition) {
          e.position -= 1;
        }
      } else {
        // Moving up (position gets smaller)
        if (e.position < entry.position && e.position >= targetPosition) {
          e.position += 1;
        }
      }
    }

    // Update this category's position to target position
    entry.position = targetPosition;
    this.entries = this.reorder(this.sortedEntries);
    this.changed = true;
  }
  static #_4 = dt7948.n(this.prototype, "move", [action]);
  change(entry, newPosition) {
    const delta = parseInt(newPosition, 10) - entry.position;
    this.move(entry, delta);
  }
  static #_5 = dt7948.n(this.prototype, "change", [action]);
  async save() {
    const entries = this.reorder(this.sortedEntries);
    const data = {};
    for (const {
      category,
      position
    } of entries) {
      data[category.id] = position;
    }
    try {
      await ajax("/categories/reorder", {
        type: "POST",
        data: {
          mapping: JSON.stringify(data)
        }
      });
      window.location.reload();
    } catch (e) {
      popupAjaxError(e);
    }
  }
  static #_6 = dt7948.n(this.prototype, "save", [action]);
}
setComponentTemplate(TEMPLATE, ReorderCategories);