import { readOnly } from "@ember/object/computed";
import RestModel from "discourse/models/rest";
import discourseComputed from "discourse-common/utils/decorators";
export default class Tag extends RestModel {
  static #_ = dt7948.g(this.prototype, "pmOnly", [readOnly("pm_only")]);
  #pmOnly = (dt7948.i(this, "pmOnly"), void 0);
  totalCount(count, pmCount) {
    return pmCount ? count + pmCount : count;
  }
  static #_2 = dt7948.n(this.prototype, "totalCount", [discourseComputed("count", "pm_count")]);
  searchContext(id) {
    return {
      type: "tag",
      id,
      tag: this,
      name: id
    };
  }
  static #_3 = dt7948.n(this.prototype, "searchContext", [discourseComputed("id")]);
}