/* import __COLOCATED_TEMPLATE__ from './local-login-form.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import { isEmpty } from "@ember/utils";
import ForgotPassword from "discourse/components/modal/forgot-password";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import { escapeExpression } from "discourse/lib/utilities";
import { getWebauthnCredential } from "discourse/lib/webauthn";
import I18n from "discourse-i18n";
import TEMPLATE from "./local-login-form.hbs";
import { setComponentTemplate } from "@ember/component";
export default class LocalLoginBody extends Component {
  static #_ = dt7948.g(this.prototype, "modal", [service]);
  #modal = (dt7948.i(this, "modal"), void 0);
  static #_2 = dt7948.g(this.prototype, "maskPassword", [tracked], function () {
    return true;
  });
  #maskPassword = (dt7948.i(this, "maskPassword"), void 0);
  static #_3 = dt7948.g(this.prototype, "processingEmailLink", [tracked], function () {
    return false;
  });
  #processingEmailLink = (dt7948.i(this, "processingEmailLink"), void 0);
  static #_4 = dt7948.g(this.prototype, "capsLockOn", [tracked], function () {
    return false;
  });
  #capsLockOn = (dt7948.i(this, "capsLockOn"), void 0);
  get credentialsClass() {
    return this.args.showSecondFactor || this.args.showSecurityKey ? "hidden" : "";
  }
  get secondFactorClass() {
    return this.args.showSecondFactor || this.args.showSecurityKey ? "" : "hidden";
  }
  get disableLoginFields() {
    return this.args.showSecondFactor || this.args.showSecurityKey;
  }
  passkeyConditionalLogin() {
    if (this.args.canUsePasskeys) {
      this.args.passkeyLogin("conditional");
    }
  }
  static #_5 = dt7948.n(this.prototype, "passkeyConditionalLogin", [action]);
  togglePasswordMask() {
    this.maskPassword = !this.maskPassword;
  }
  static #_6 = dt7948.n(this.prototype, "togglePasswordMask", [action]);
  async emailLogin(event) {
    event?.preventDefault();
    if (this.processingEmailLink) {
      return;
    }
    if (isEmpty(this.args.loginName)) {
      this.args.flashChanged(I18n.t("login.blank_username"));
      this.args.flashTypeChanged("info");
      return;
    }
    try {
      this.processingEmailLink = true;
      const data = await ajax("/u/email-login", {
        data: {
          login: this.args.loginName.trim()
        },
        type: "POST"
      });
      const loginName = escapeExpression(this.args.loginName);
      const isEmail = loginName.match(/@/);
      const key = isEmail ? "email_login.complete_email" : "email_login.complete_username";
      if (data.user_found === false) {
        this.args.flashChanged(htmlSafe(I18n.t(`${key}_not_found`, {
          email: loginName,
          username: loginName
        })));
        this.args.flashTypeChanged("error");
      } else {
        const postfix = data.hide_taken ? "" : "_found";
        this.args.flashChanged(htmlSafe(I18n.t(`${key}${postfix}`, {
          email: loginName,
          username: loginName
        })));
        this.args.flashTypeChanged("success");
      }
    } catch (e) {
      popupAjaxError(e);
    } finally {
      this.processingEmailLink = false;
    }
  }
  static #_7 = dt7948.n(this.prototype, "emailLogin", [action]);
  loginOnEnter(event) {
    if (event.key === "Enter") {
      this.args.login();
    }
  }
  static #_8 = dt7948.n(this.prototype, "loginOnEnter", [action]);
  handleForgotPassword(event) {
    event?.preventDefault();
    this.modal.show(ForgotPassword, {
      model: {
        emailOrUsername: this.args.loginName
      }
    });
  }
  static #_9 = dt7948.n(this.prototype, "handleForgotPassword", [action]);
  authenticateSecurityKey() {
    getWebauthnCredential(this.args.securityKeyChallenge, this.args.securityKeyAllowedCredentialIds, credentialData => {
      this.args.securityKeyCredentialChanged(credentialData);
      this.args.login();
    }, error => {
      this.args.flashChanged(error);
      this.args.flashTypeChanged("error");
    });
  }
  static #_10 = dt7948.n(this.prototype, "authenticateSecurityKey", [action]);
}
setComponentTemplate(TEMPLATE, LocalLoginBody);