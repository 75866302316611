/* import __COLOCATED_TEMPLATE__ from './change-owner.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { isEmpty } from "@ember/utils";
import DiscourseURL from "discourse/lib/url";
import Topic from "discourse/models/topic";
import I18n from "discourse-i18n";
import TEMPLATE from "./change-owner.hbs";
import { setComponentTemplate } from "@ember/component";
export default class ChangeOwnerModal extends Component {
  static #_ = dt7948.g(this.prototype, "saving", [tracked], function () {
    return false;
  });
  #saving = (dt7948.i(this, "saving"), void 0);
  static #_2 = dt7948.g(this.prototype, "newOwner", [tracked], function () {
    return null;
  });
  #newOwner = (dt7948.i(this, "newOwner"), void 0);
  static #_3 = dt7948.g(this.prototype, "flash", [tracked]);
  #flash = (dt7948.i(this, "flash"), void 0);
  get buttonDisabled() {
    return this.saving || isEmpty(this.newOwner);
  }
  get selectedPostsUsername() {
    return this.args.model.selectedPostsUsername;
  }
  get selectedPostIds() {
    return this.args.model.selectedPostIds;
  }
  get selectedPostsCount() {
    return this.args.model.selectedPostsCount;
  }
  async changeOwnershipOfPosts() {
    this.saving = true;
    const options = {
      post_ids: this.selectedPostIds,
      username: this.newOwner
    };
    try {
      await Topic.changeOwners(this.args.model.topic.id, options);
      this.args.closeModal();
      this.args.model.deselectAll();
      if (this.args.model.multiSelect) {
        this.args.model.toggleMultiSelect();
      }
      DiscourseURL.routeTo(this.args.model.topic.url);
    } catch (error) {
      this.flash = I18n.t("topic.change_owner.error");
      this.saving = false;
    }
    return false;
  }
  static #_4 = dt7948.n(this.prototype, "changeOwnershipOfPosts", [action]);
  async updateNewOwner(selected) {
    this.newOwner = selected.firstObject;
  }
  static #_5 = dt7948.n(this.prototype, "updateNewOwner", [action]);
}
setComponentTemplate(TEMPLATE, ChangeOwnerModal);