import Controller from "@ember/controller";
import { action } from "@ember/object";
import { reads } from "@ember/object/computed";
import { service } from "@ember/service";
import discourseComputed from "discourse-common/utils/decorators";
export default class CategoriesController extends Controller {
  static #_ = dt7948.g(this.prototype, "router", [service]);
  #router = (dt7948.i(this, "router"), void 0);
  static #_2 = dt7948.g(this.prototype, "composer", [service]);
  #composer = (dt7948.i(this, "composer"), void 0);
  static #_3 = dt7948.g(this.prototype, "canEdit", [reads("currentUser.staff")]);
  #canEdit = (dt7948.i(this, "canEdit"), void 0);
  isCategoriesRoute() {
    return this.router.currentRouteName === "discovery.categories";
  }
  static #_4 = dt7948.n(this.prototype, "isCategoriesRoute", [discourseComputed]);
  showInserted(event) {
    event?.preventDefault();
    const tracker = this.topicTrackingState;
    // Move inserted into topics
    this.model.loadBefore(tracker.get("newIncoming"), true);
    tracker.resetTracking();
  }
  static #_5 = dt7948.n(this.prototype, "showInserted", [action]);
  createTopic() {
    this.composer.openNewTopic({
      preferDraft: true
    });
  }
  static #_6 = dt7948.n(this.prototype, "createTopic", [action]);
  refresh() {
    this.send("triggerRefresh");
  }
  static #_7 = dt7948.n(this.prototype, "refresh", [action]);
}