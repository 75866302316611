import { tracked } from "@glimmer/tracking";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import { or, reads } from "@ember/object/computed";
import { isNone } from "@ember/utils";
import { popupAjaxError } from "discourse/lib/ajax-error";
import BulkSelectHelper from "discourse/lib/bulk-select-helper";
import { defineTrackedProperty } from "discourse/lib/tracked-tools";
import Topic from "discourse/models/topic";
import { NEW_FILTER, UNREAD_FILTER } from "discourse/routes/build-private-messages-route";
import { QUERY_PARAMS } from "discourse/routes/user-topic-list";
import discourseComputed from "discourse-common/utils/decorators";

// Lists of topics on a user's page.
export default class UserTopicsListController extends Controller {
  static #_ = dt7948.g(this.prototype, "model", [tracked]);
  #model = (dt7948.i(this, "model"), void 0);
  hideCategory = false;
  showPosters = false;
  channel = null;
  tagsForUser = null;
  queryParams = Object.keys(QUERY_PARAMS);
  bulkSelectHelper = new BulkSelectHelper(this);
  static #_2 = dt7948.g(this.prototype, "incomingCount", [reads("pmTopicTrackingState.newIncoming.length")]);
  #incomingCount = (dt7948.i(this, "incomingCount"), void 0);
  static #_3 = dt7948.g(this.prototype, "canBulkSelect", [or("currentUser.canManageTopic", "showDismissRead", "showResetNew")]);
  #canBulkSelect = (dt7948.i(this, "canBulkSelect"), void 0);
  constructor() {
    super(...arguments);
    for (const [name, info] of Object.entries(QUERY_PARAMS)) {
      defineTrackedProperty(this, name, info.default);
    }
  }
  get bulkSelectEnabled() {
    return this.bulkSelectHelper.bulkSelectEnabled;
  }
  get selected() {
    return this.bulkSelectHelper.selected;
  }
  noContent(topicsLength, incomingCount) {
    return topicsLength === 0 && incomingCount === 0;
  }
  static #_4 = dt7948.n(this.prototype, "noContent", [discourseComputed("model.topics.length", "incomingCount")]);
  showResetNew(filter, hasTopics) {
    return filter === NEW_FILTER && hasTopics;
  }
  static #_5 = dt7948.n(this.prototype, "showResetNew", [discourseComputed("filter", "model.topics.length")]);
  showDismissRead(filter, hasTopics) {
    return filter === UNREAD_FILTER && hasTopics;
  }
  static #_6 = dt7948.n(this.prototype, "showDismissRead", [discourseComputed("filter", "model.topics.length")]);
  subscribe() {
    this.pmTopicTrackingState.trackIncoming(this.inbox, this.filter);
  }
  unsubscribe() {
    this.pmTopicTrackingState.stopIncomingTracking();
  }
  changeSort(sortBy) {
    if (sortBy === this.resolvedOrder) {
      this.ascending = !this.resolvedAscending;
    } else {
      this.ascending = false;
    }
    this.order = sortBy;
  }
  static #_7 = dt7948.n(this.prototype, "changeSort", [action]);
  get resolvedAscending() {
    if (isNone(this.ascending)) {
      return this.model.get("params.ascending") === "true";
    } else {
      return this.ascending.toString() === "true";
    }
  }
  get resolvedOrder() {
    return this.order ?? this.model.get("params.order") ?? "activity";
  }
  resetNew() {
    const topicIds = this.selected ? this.selected.map(topic => topic.id) : null;
    const opts = {
      inbox: this.inbox,
      topicIds
    };
    if (this.group) {
      opts.groupName = this.group.name;
    }
    Topic.pmResetNew(opts).then(result => {
      if (result && result.topic_ids.length > 0) {
        this.pmTopicTrackingState.removeTopics(result.topic_ids);
        this.send("refresh");
      }
    });
  }
  static #_8 = dt7948.n(this.prototype, "resetNew", [action]);
  loadMore() {
    this.model.loadMore();
  }
  static #_9 = dt7948.n(this.prototype, "loadMore", [action]);
  async showInserted(event) {
    event?.preventDefault();
    if (this.model.loadingBefore) {
      return;
    }
    try {
      const topicIds = [...this.pmTopicTrackingState.newIncoming];
      await this.model.loadBefore(topicIds);
      this.pmTopicTrackingState.resetIncomingTracking(topicIds);
    } catch (e) {
      popupAjaxError(e);
    }
  }
  static #_10 = dt7948.n(this.prototype, "showInserted", [action]);
  refresh() {
    this.send("triggerRefresh");
  }
  static #_11 = dt7948.n(this.prototype, "refresh", [action]);
  toggleBulkSelect() {
    this.bulkSelectHelper.toggleBulkSelect();
  }
  static #_12 = dt7948.n(this.prototype, "toggleBulkSelect", [action]);
  dismissRead(operationType, options) {
    this.bulkSelectHelper.dismissRead(operationType, options);
  }
  static #_13 = dt7948.n(this.prototype, "dismissRead", [action]);
  updateAutoAddTopicsToBulkSelect(value) {
    this.bulkSelectHelper.autoAddTopicsToBulkSelect = value;
  }
  static #_14 = dt7948.n(this.prototype, "updateAutoAddTopicsToBulkSelect", [action]);
  addTopicsToBulkSelect(topics) {
    this.bulkSelectHelper.addTopics(topics);
  }
  static #_15 = dt7948.n(this.prototype, "addTopicsToBulkSelect", [action]);
}