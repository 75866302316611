import Component from "@glimmer/component";
import { hash } from "@ember/helper";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { waitForPromise } from "@ember/test-waiters";
import { isDocumentRTL } from "discourse/lib/text-direction";
import { prefersReducedMotion } from "discourse/lib/utilities";
import { isTesting } from "discourse-common/config/environment";
import discourseLater from "discourse-common/lib/later";
import closeOnClickOutside from "../../modifiers/close-on-click-outside";
import HamburgerDropdown from "../sidebar/hamburger-dropdown";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
const CLOSE_ON_CLICK_SELECTORS = "a[href], .sidebar-section-header-button, .sidebar-section-link-button, .sidebar-section-link";
export default class HamburgerDropdownWrapper extends Component {
  click(e1) {
    if (e1.target.closest(CLOSE_ON_CLICK_SELECTORS)) {
      this.args.toggleHamburger();
    }
  }
  static #_ = dt7948.n(this.prototype, "click", [action]);
  clickOutside(e1) {
    if (e1.target.classList.contains("header-cloak") && !prefersReducedMotion()) {
      const panel1 = document.querySelector(".menu-panel");
      const headerCloak1 = document.querySelector(".header-cloak");
      const finishPosition1 = isDocumentRTL() ? "340px" : "-340px";
      const panelAnimatePromise1 = panel1.animate([{
        transform: `translate3d(${finishPosition1}, 0, 0)`
      }], {
        duration: isTesting() ? 0 : 200,
        fill: "forwards",
        easing: "ease-in"
      }).finished.then(() => {
        if (isTesting()) {
          this.args.toggleHamburger();
        } else {
          discourseLater(() => this.args.toggleHamburger());
        }
      });
      const cloakAnimatePromise1 = headerCloak1.animate([{
        opacity: 0
      }], {
        duration: isTesting() ? 0 : 200,
        fill: "forwards",
        easing: "ease-in"
      }).finished;
      waitForPromise(panelAnimatePromise1);
      waitForPromise(cloakAnimatePromise1);
    } else {
      this.args.toggleHamburger();
    }
  }
  static #_2 = dt7948.n(this.prototype, "clickOutside", [action]);
  static #_3 = setComponentTemplate(createTemplateFactory(
  /*
    
      <div
        class="hamburger-dropdown-wrapper"
        {{! template-lint-disable no-invalid-interactive }}
        {{on "click" this.click}}
        {{! we don't want to close the hamburger dropdown when clicking on the hamburger dropdown itself
          so we use the secondaryTargetSelector to prevent that }}
        {{closeOnClickOutside
          this.clickOutside
          (hash
            targetSelector=".hamburger-panel"
            secondaryTargetSelector=".hamburger-dropdown"
          )
        }}
      >
        <HamburgerDropdown />
      </div>
    
  */
  {
    "id": "kdb5jz++",
    "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"hamburger-dropdown-wrapper\"],[4,[32,0],[\"click\",[30,0,[\"click\"]]],null],[4,[32,1],[[30,0,[\"clickOutside\"]],[28,[32,2],null,[[\"targetSelector\",\"secondaryTargetSelector\"],[\".hamburger-panel\",\".hamburger-dropdown\"]]]],null],[12],[1,\"\\n      \"],[8,[32,3],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/hamburger-dropdown-wrapper.js",
    "scope": () => [on, closeOnClickOutside, hash, HamburgerDropdown],
    "isStrictMode": true
  }), this);
}