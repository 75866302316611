/* import __COLOCATED_TEMPLATE__ from './hamburger-dropdown.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import TEMPLATE from "./hamburger-dropdown.hbs";
import { setComponentTemplate } from "@ember/component";
export default class SidebarHamburgerDropdown extends Component {
  static #_ = dt7948.g(this.prototype, "appEvents", [service]);
  #appEvents = (dt7948.i(this, "appEvents"), void 0);
  static #_2 = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_3 = dt7948.g(this.prototype, "site", [service]);
  #site = (dt7948.i(this, "site"), void 0);
  static #_4 = dt7948.g(this.prototype, "siteSettings", [service]);
  #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
  static #_5 = dt7948.g(this.prototype, "sidebarState", [service]);
  #sidebarState = (dt7948.i(this, "sidebarState"), void 0);
  triggerRenderedAppEvent() {
    this.appEvents.trigger("sidebar-hamburger-dropdown:rendered");
  }
  static #_6 = dt7948.n(this.prototype, "triggerRenderedAppEvent", [action]);
  get collapsableSections() {
    if (this.siteSettings.navigation_menu === "header dropdown" && !this.args.collapsableSections) {
      return this.site.mobileView || this.site.narrowDesktopView;
    } else {
      this.args.collapsableSections;
    }
  }
}
setComponentTemplate(TEMPLATE, SidebarHamburgerDropdown);