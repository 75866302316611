/* import __COLOCATED_TEMPLATE__ from './sections.hbs'; */
import Component from "@glimmer/component";
import { service } from "@ember/service";
import TEMPLATE from "./sections.hbs";
import { setComponentTemplate } from "@ember/component";
export default class SidebarUserSections extends Component {
  static #_ = dt7948.g(this.prototype, "siteSettings", [service]);
  #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
  static #_2 = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_3 = dt7948.g(this.prototype, "site", [service]);
  #site = (dt7948.i(this, "site"), void 0);
  get enableMessagesSection() {
    return this.currentUser?.can_send_private_messages;
  }
}
setComponentTemplate(TEMPLATE, SidebarUserSections);