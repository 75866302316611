import { action } from "@ember/object";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
export default DiscourseRoute.extend(dt7948.p({
  titleToken() {
    return I18n.t("groups.manage.logs.title");
  },
  model() {
    return this.modelFor("group").findLogs();
  },
  setupController(controller, model) {
    this.controllerFor("group-manage-logs").setProperties({
      model
    });
  },
  willTransition() {
    this.controllerFor("group-manage-logs").reset();
  }
}, [["method", "willTransition", [action]]]));