import Component from "@glimmer/component";
import { service } from "@ember/service";
import { getURLWithCDN } from "discourse-common/lib/get-url";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class DStyles extends Component {
  static #_ = dt7948.g(this.prototype, "session", [service]);
  #session = (dt7948.i(this, "session"), void 0);
  static #_2 = dt7948.g(this.prototype, "site", [service]);
  #site = (dt7948.i(this, "site"), void 0);
  get categoryColors() {
    return [":root {", ...this.site.categories.map(category1 => `--category-${category1.id}-color: #${category1.color};`), "}"].join("\n");
  }
  get categoryBackgrounds() {
    const css1 = [];
    const darkCss1 = [];
    this.site.categories.forEach(category1 => {
      const lightUrl1 = category1.uploaded_background?.url;
      const darkUrl1 = this.session.defaultColorSchemeIsDark || this.session.darkModeAvailable ? category1.uploaded_background_dark?.url : null;
      const defaultUrl1 = darkUrl1 && this.session.defaultColorSchemeIsDark ? darkUrl1 : lightUrl1;
      if (defaultUrl1) {
        const url1 = getURLWithCDN(defaultUrl1);
        css1.push(`body.category-${category1.fullSlug} { background-image: url(${url1}); }`);
      }
      if (darkUrl1 && defaultUrl1 !== darkUrl1) {
        const url1 = getURLWithCDN(darkUrl1);
        darkCss1.push(`body.category-${category1.fullSlug} { background-image: url(${url1}); }`);
      }
    });
    if (darkCss1.length > 0) {
      css1.push("@media (prefers-color-scheme: dark) {", ...darkCss1, "}");
    }
    return css1.join("\n");
  }
  get categoryBadges() {
    const css1 = [];
    this.site.categories.forEach(category1 => {
      css1.push(`.badge-category[data-category-id="${category1.id}"] { ` + `--category-badge-color: var(--category-${category1.id}-color); ` + `--category-badge-text-color: #${category1.text_color}; ` + `}`);
      if (category1.isParent) {
        css1.push(`.badge-category[data-parent-category-id="${category1.id}"] { ` + `--parent-category-badge-color: var(--category-${category1.id}-color); ` + `}`);
      }
    });
    return css1.join("\n");
  }
  static #_3 = setComponentTemplate(createTemplateFactory(
  /*
    
      {{! template-lint-disable no-forbidden-elements }}
      <style id="d-styles">
        {{#if this.site.categories}}
          {{this.categoryColors}}
          {{this.categoryBackgrounds}}
          {{this.categoryBadges}}
        {{/if}}
      </style>
    
  */
  {
    "id": "ubSv2iSd",
    "block": "[[[1,\"\\n\"],[1,\"    \"],[10,\"style\"],[14,1,\"d-styles\"],[12],[1,\"\\n\"],[41,[30,0,[\"site\",\"categories\"]],[[[1,\"        \"],[1,[30,0,[\"categoryColors\"]]],[1,\"\\n        \"],[1,[30,0,[\"categoryBackgrounds\"]]],[1,\"\\n        \"],[1,[30,0,[\"categoryBadges\"]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/d-styles.js",
    "isStrictMode": true
  }), this);
}