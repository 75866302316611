import Component from "@glimmer/component";
import { service } from "@ember/service";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class TopicPostBadges extends Component {
  static #_ = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  get displayUnreadPosts() {
    return this.args.newPosts || this.args.unreadPosts;
  }
  get newDotText() {
    return this.currentUser?.trust_level > 0 ? " " : i18n("filters.new.lower_title");
  }
  static #_2 = setComponentTemplate(createTemplateFactory(
  /*
    
      <span class="topic-post-badges">
        {{~#if this.displayUnreadPosts~}}
          &nbsp;<a
            href={{@url}}
            title={{i18n "topic.unread_posts" count=this.displayUnreadPosts}}
            class="badge badge-notification unread-posts"
          >{{this.displayUnreadPosts}}</a>
        {{/if~}}
  
        {{~#if @unseen~}}
          &nbsp;<a
            href={{@url}}
            title={{i18n "topic.new"}}
            class="badge badge-notification new-topic"
          >{{this.newDotText}}</a>
        {{/if~}}
      </span>
    
  */
  {
    "id": "tCIn0dDy",
    "block": "[[[1,\"\\n    \"],[10,1],[14,0,\"topic-post-badges\"],[12],[41,[30,0,[\"displayUnreadPosts\"]],[[[1,\" \"],[10,3],[15,6,[30,1]],[15,\"title\",[28,[32,0],[\"topic.unread_posts\"],[[\"count\"],[[30,0,[\"displayUnreadPosts\"]]]]]],[14,0,\"badge badge-notification unread-posts\"],[12],[1,[30,0,[\"displayUnreadPosts\"]]],[13],[1,\"\\n\"]],[]],null],[41,[30,2],[[[1,\" \"],[10,3],[15,6,[30,1]],[15,\"title\",[28,[32,0],[\"topic.new\"],null]],[14,0,\"badge badge-notification new-topic\"],[12],[1,[30,0,[\"newDotText\"]]],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n  \"]],[\"@url\",\"@unseen\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-post-badges.js",
    "scope": () => [i18n],
    "isStrictMode": true
  }), this);
}