/* import __COLOCATED_TEMPLATE__ from './search-term.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { DEFAULT_TYPE_FILTER, SEARCH_INPUT_ID } from "discourse/components/search-menu";
import { isiPad } from "discourse/lib/utilities";
import TEMPLATE from "./search-term.hbs";
import { setComponentTemplate } from "@ember/component";
const SECOND_ENTER_MAX_DELAY = 15000;
const onKeyUpCallbacks = [];
export function addOnKeyUpCallback(fn) {
  onKeyUpCallbacks.push(fn);
}
export function resetOnKeyUpCallbacks() {
  onKeyUpCallbacks.clear();
}
export default class SearchTerm extends Component {
  static #_ = dt7948.g(this.prototype, "search", [service]);
  #search = (dt7948.i(this, "search"), void 0);
  static #_2 = dt7948.g(this.prototype, "appEvents", [service]);
  #appEvents = (dt7948.i(this, "appEvents"), void 0);
  static #_3 = dt7948.g(this.prototype, "lastEnterTimestamp", [tracked], function () {
    return null;
  });
  #lastEnterTimestamp = (dt7948.i(this, "lastEnterTimestamp"), void 0);
  static #_4 = dt7948.g(this.prototype, "searchCleared", [tracked], function () {
    return !this.search.activeGlobalSearchTerm;
  });
  #searchCleared = (dt7948.i(this, "searchCleared"), void 0);
  // make constant available in template
  get inputId() {
    return SEARCH_INPUT_ID;
  }
  updateSearchTerm(input) {
    this.parseAndUpdateSearchTerm(this.search.activeGlobalSearchTerm, input.target.value);
    this.searchCleared = this.search.activeGlobalSearchTerm ? false : true;
  }
  static #_5 = dt7948.n(this.prototype, "updateSearchTerm", [action]);
  focus(element) {
    if (this.args.autofocus) {
      element.focus();
      element.select();
    }
  }
  static #_6 = dt7948.n(this.prototype, "focus", [action]);
  onKeydown(e) {
    if (e.key === "Escape") {
      this.args.closeSearchMenu();
      e.preventDefault();
      e.stopPropagation();
    }
  }
  static #_7 = dt7948.n(this.prototype, "onKeydown", [action]);
  onKeyup(e) {
    if (onKeyUpCallbacks.length && !onKeyUpCallbacks.some(fn => fn(this, e))) {
      // Return early if any callbacks return false
      return;
    }
    this.args.openSearchMenu();
    this.search.handleArrowUpOrDown(e);
    if (e.key === "Enter") {
      const recentEnterHit = this.lastEnterTimestamp && Date.now() - this.lastEnterTimestamp < SECOND_ENTER_MAX_DELAY;
      if (e.ctrlKey || e.metaKey || isiPad() && e.altKey || this.args.typeFilter !== DEFAULT_TYPE_FILTER && recentEnterHit) {
        this.args.fullSearch();
        this.args.closeSearchMenu();
      } else {
        this.args.updateTypeFilter(null);
        this.args.triggerSearch();
      }
      this.lastEnterTimestamp = Date.now();
    }
    if (e.key === "Backspace") {
      if (!e.target.value) {
        // only clear context if we're not in the middle of a search
        if (this.searchCleared) {
          this.args.clearTopicContext();
          this.args.clearPMInboxContext();
          this.focus(e.target);
        }
        this.searchCleared = true;
      }
    }
    e.preventDefault();
  }
  static #_8 = dt7948.n(this.prototype, "onKeyup", [action]);
  parseAndUpdateSearchTerm(originalVal, newVal) {
    // remove zero-width chars
    const parsedVal = newVal.replace(/[\u200B-\u200D\uFEFF]/, "");
    if (parsedVal !== originalVal) {
      this.args.searchTermChanged(parsedVal);
    }
  }
}
setComponentTemplate(TEMPLATE, SearchTerm);