import Component from "@glimmer/component";
import "@glimmer/tracking";
let cached = esc(require('@glimmer/tracking')).cached;
import { service } from "@ember/service";
import { canDisplayCategory } from "discourse/lib/sidebar/helpers";
import CategorySectionLink from "discourse/lib/sidebar/user/categories-section/category-section-link";
import Category from "discourse/models/category";
import esc from "../../../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";
export const TOP_SITE_CATEGORIES_TO_SHOW = 5;
export default class SidebarCommonCategoriesSection extends Component {
  static #_ = dt7948.g(this.prototype, "topicTrackingState", [service]);
  #topicTrackingState = (dt7948.i(this, "topicTrackingState"), void 0);
  static #_2 = dt7948.g(this.prototype, "siteSettings", [service]);
  #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
  static #_3 = dt7948.g(this.prototype, "site", [service]);
  #site = (dt7948.i(this, "site"), void 0);
  shouldSortCategoriesByDefault = true;

  /**
   * Override in child
   *
   * @returns {Object[]} An array of Category objects
   */
  get categories() {}
  get topSiteCategories() {
    return this.site.categoriesList.filter(category => {
      return !category.parent_category_id && canDisplayCategory(category.id, this.siteSettings);
    }).slice(0, TOP_SITE_CATEGORIES_TO_SHOW);
  }
  get sortedCategories() {
    if (!this.shouldSortCategoriesByDefault) {
      return this.categories;
    }
    let categories = [...this.site.categories];
    if (!this.siteSettings.fixed_category_positions) {
      categories.sort((a, b) => a.name.localeCompare(b.name));
    }
    const categoryIds = this.categories.map(category => category.id);
    return Category.sortCategories(categories).reduce((filteredCategories, category) => {
      if (categoryIds.includes(category.id) && canDisplayCategory(category.id, this.siteSettings)) {
        filteredCategories.push(category);
      }
      return filteredCategories;
    }, []);
  }
  get sectionLinks() {
    return this.sortedCategories.map(category => {
      return new CategorySectionLink({
        category,
        topicTrackingState: this.topicTrackingState,
        currentUser: this.currentUser
      });
    });
  }
  static #_4 = dt7948.n(this.prototype, "sectionLinks", [cached]);
}