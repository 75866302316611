import { tracked } from "@glimmer/tracking";
import { formattedReminderTime } from "discourse/lib/bookmark";
import { TIME_SHORTCUT_TYPES } from "discourse/lib/time-shortcut";
import { AUTO_DELETE_PREFERENCES } from "discourse/models/bookmark";
export class BookmarkFormData {
  static #_ = dt7948.g(this.prototype, "selectedDatetime", [tracked]);
  #selectedDatetime = (dt7948.i(this, "selectedDatetime"), void 0);
  static #_2 = dt7948.g(this.prototype, "selectedReminderType", [tracked], function () {
    return TIME_SHORTCUT_TYPES.NONE;
  });
  #selectedReminderType = (dt7948.i(this, "selectedReminderType"), void 0);
  static #_3 = dt7948.g(this.prototype, "id", [tracked]);
  #id = (dt7948.i(this, "id"), void 0);
  static #_4 = dt7948.g(this.prototype, "reminderAt", [tracked]);
  #reminderAt = (dt7948.i(this, "reminderAt"), void 0);
  static #_5 = dt7948.g(this.prototype, "autoDeletePreference", [tracked]);
  #autoDeletePreference = (dt7948.i(this, "autoDeletePreference"), void 0);
  static #_6 = dt7948.g(this.prototype, "name", [tracked]);
  #name = (dt7948.i(this, "name"), void 0);
  static #_7 = dt7948.g(this.prototype, "bookmarkableId", [tracked]);
  #bookmarkableId = (dt7948.i(this, "bookmarkableId"), void 0);
  static #_8 = dt7948.g(this.prototype, "bookmarkableType", [tracked]);
  #bookmarkableType = (dt7948.i(this, "bookmarkableType"), void 0);
  constructor(bookmark) {
    this.id = bookmark.id;
    this.reminderAt = bookmark.reminder_at;
    this.name = bookmark.name;
    this.bookmarkableId = bookmark.bookmarkable_id;
    this.bookmarkableType = bookmark.bookmarkable_type;
    this.autoDeletePreference = bookmark.auto_delete_preference ?? AUTO_DELETE_PREFERENCES.CLEAR_REMINDER;
  }
  get reminderAtISO() {
    if (this.selectedReminderType === TIME_SHORTCUT_TYPES.NONE) {
      return null;
    }
    if (!this.selectedReminderType || !this.selectedDatetime) {
      if (this.reminderAt) {
        return this.reminderAt.toISOString();
      } else {
        return null;
      }
    }
    return this.selectedDatetime.toISOString();
  }
  get saveData() {
    return {
      reminder_at: this.reminderAtISO,
      name: this.name,
      id: this.id,
      auto_delete_preference: this.autoDeletePreference,
      bookmarkable_id: this.bookmarkableId,
      bookmarkable_type: this.bookmarkableType
    };
  }
  formattedReminder(timezone) {
    return formattedReminderTime(this.reminderAt, timezone);
  }
}