/* import __COLOCATED_TEMPLATE__ from './filter-navigation.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { resettableTracked } from "discourse/lib/tracked-tools";
import discourseDebounce from "discourse-common/lib/debounce";
import { bind } from "discourse-common/utils/decorators";
import TEMPLATE from "./filter-navigation.hbs";
import { setComponentTemplate } from "@ember/component";
export default class DiscoveryFilterNavigation extends Component {
  static #_ = dt7948.g(this.prototype, "copyIcon", [tracked], function () {
    return "link";
  });
  #copyIcon = (dt7948.i(this, "copyIcon"), void 0);
  static #_2 = dt7948.g(this.prototype, "copyClass", [tracked], function () {
    return "btn-default";
  });
  #copyClass = (dt7948.i(this, "copyClass"), void 0);
  static #_3 = dt7948.g(this.prototype, "newQueryString", [resettableTracked], function () {
    return this.args.queryString;
  });
  #newQueryString = (dt7948.i(this, "newQueryString"), void 0);
  updateQueryString(string) {
    this.newQueryString = string;
  }
  static #_4 = dt7948.n(this.prototype, "updateQueryString", [bind]);
  clearInput() {
    this.newQueryString = "";
    this.args.updateTopicsListQueryParams(this.newQueryString);
  }
  static #_5 = dt7948.n(this.prototype, "clearInput", [action]);
  copyQueryString() {
    this.copyIcon = "check";
    this.copyClass = "btn-default ok";
    navigator.clipboard.writeText(window.location);
    discourseDebounce(this._restoreButton, 3000);
  }
  static #_6 = dt7948.n(this.prototype, "copyQueryString", [action]);
  _restoreButton() {
    if (this.isDestroying || this.isDestroyed) {
      return;
    }
    this.copyIcon = "link";
    this.copyClass = "btn-default";
  }
  static #_7 = dt7948.n(this.prototype, "_restoreButton", [bind]);
}
setComponentTemplate(TEMPLATE, DiscoveryFilterNavigation);