import Component from "@glimmer/component";
import { service } from "@ember/service";
import CdnImg from "discourse/components/cdn-img";
import { getURLWithCDN } from "discourse-common/lib/get-url";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class LightDarkImg extends Component {
  static #_ = dt7948.g(this.prototype, "session", [service]);
  #session = (dt7948.i(this, "session"), void 0);
  get isDarkImageAvailable() {
    return this.args.lightImg?.url &&
    // the light image must be present
    this.args.darkImg?.url && (this.session.defaultColorSchemeIsDark || this.session.darkModeAvailable);
  }
  get defaultImg() {
    // use dark logo by default in edge case
    // when scheme is dark and dark logo is present
    if (this.session.defaultColorSchemeIsDark && this.args.darkImg) {
      return this.args.darkImg;
    }
    return this.args.lightImg;
  }
  get darkImgCdnSrc() {
    return getURLWithCDN(this.args.darkImg.url);
  }
  static #_2 = setComponentTemplate(createTemplateFactory(
  /*
    
      {{#if this.isDarkImageAvailable}}
        <picture>
          <source
            srcset={{this.darkImgCdnSrc}}
            width={{@darkImg.width}}
            height={{@darkImg.height}}
            media="(prefers-color-scheme: dark)"
          />
          <CdnImg
            ...attributes
            @src={{this.defaultImg.url}}
            @width={{this.defaultImg.width}}
            @height={{this.defaultImg.height}}
          />
        </picture>
      {{else if @lightImg.url}}
        <CdnImg
          ...attributes
          @src={{@lightImg.url}}
          @width={{@lightImg.width}}
          @height={{@lightImg.height}}
        />
      {{/if}}
    
  */
  {
    "id": "UCcxc1Cl",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"isDarkImageAvailable\"]],[[[1,\"      \"],[10,\"picture\"],[12],[1,\"\\n        \"],[10,\"source\"],[15,\"srcset\",[30,0,[\"darkImgCdnSrc\"]]],[15,\"width\",[30,1,[\"width\"]]],[15,\"height\",[30,1,[\"height\"]]],[14,\"media\",\"(prefers-color-scheme: dark)\"],[12],[13],[1,\"\\n        \"],[8,[32,0],[[17,2]],[[\"@src\",\"@width\",\"@height\"],[[30,0,[\"defaultImg\",\"url\"]],[30,0,[\"defaultImg\",\"width\"]],[30,0,[\"defaultImg\",\"height\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[41,[30,3,[\"url\"]],[[[1,\"      \"],[8,[32,0],[[17,2]],[[\"@src\",\"@width\",\"@height\"],[[30,3,[\"url\"]],[30,3,[\"width\"]],[30,3,[\"height\"]]]],null],[1,\"\\n    \"]],[]],null]],[]]],[1,\"  \"]],[\"@darkImg\",\"&attrs\",\"@lightImg\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/light-dark-img.js",
    "scope": () => [CdnImg],
    "isStrictMode": true
  }), this);
}