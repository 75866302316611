/* import __COLOCATED_TEMPLATE__ from './section.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { bind } from "discourse-common/utils/decorators";
import TEMPLATE from "./section.hbs";
import { setComponentTemplate } from "@ember/component";
export default class SidebarSection extends Component {
  static #_ = dt7948.g(this.prototype, "keyValueStore", [service]);
  #keyValueStore = (dt7948.i(this, "keyValueStore"), void 0);
  static #_2 = dt7948.g(this.prototype, "sidebarState", [service]);
  #sidebarState = (dt7948.i(this, "sidebarState"), void 0);
  static #_3 = dt7948.g(this.prototype, "collapsedSections", [tracked], function () {
    return this.sidebarState.collapsedSections;
  });
  #collapsedSections = (dt7948.i(this, "collapsedSections"), void 0);
  sidebarSectionContentID = `sidebar-section-content-${this.args.sectionName}`;
  collapsedSidebarSectionKey = `sidebar-section-${this.args.sectionName}-collapsed`;
  get isCollapsed() {
    if (!this.args.collapsable) {
      return false;
    }
    if (this.keyValueStore.getItem(this.collapsedSidebarSectionKey) === undefined) {
      return this.args.collapsedByDefault;
    }
    return this.keyValueStore.getItem(this.collapsedSidebarSectionKey) === "true";
  }
  setExpandedState() {
    if (this.isCollapsed) {
      this.sidebarState.collapseSection(this.args.sectionName);
    } else {
      this.sidebarState.expandSection(this.args.sectionName);
    }
  }
  static #_4 = dt7948.n(this.prototype, "setExpandedState", [bind]);
  get displaySectionContent() {
    return !this.collapsedSections.includes(this.collapsedSidebarSectionKey);
  }
  willDestroy() {
    super.willDestroy(...arguments);
    this.args.willDestroy?.();
  }
  toggleSectionDisplay() {
    if (this.displaySectionContent) {
      this.sidebarState.collapseSection(this.args.sectionName);
    } else {
      this.sidebarState.expandSection(this.args.sectionName);
    }

    // remove focus from the toggle, but only on click
    if (!event.key) {
      document.activeElement.blur();
    }
  }
  static #_5 = dt7948.n(this.prototype, "toggleSectionDisplay", [action]);
  handleMultipleHeaderActions(id) {
    this.args.headerActions.find(headerAction => headerAction.id === id).action();
  }
  static #_6 = dt7948.n(this.prototype, "handleMultipleHeaderActions", [action]);
  get headerCaretIcon() {
    return this.displaySectionContent ? "angle-down" : "angle-right";
  }
  get isSingleHeaderAction() {
    return this.args.headerActions?.length === 1;
  }
  get isMultipleHeaderActions() {
    return this.args.headerActions?.length > 1;
  }
  get displaySection() {
    if (this.args.displaySection === undefined) {
      return true;
    }
    return this.args.displaySection;
  }
}
setComponentTemplate(TEMPLATE, SidebarSection);