import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import didUpdate from "@ember/render-modifiers/modifiers/did-update";
import { service } from "@ember/service";
import { resolveAllShortUrls } from "pretty-text/upload-short-url";
import { ajax } from "discourse/lib/ajax";
import { loadOneboxes } from "discourse/lib/load-oneboxes";
import { cook } from "discourse/lib/text";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class CookText extends Component {
  static #_ = dt7948.g(this.prototype, "siteSettings", [service]);
  #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
  static #_2 = dt7948.g(this.prototype, "cooked", [tracked], function () {
    return null;
  });
  #cooked = (dt7948.i(this, "cooked"), void 0);
  constructor(owner1, args1) {
    super(owner1, args1);
    this.loadCookedText();
  }
  async loadCookedText() {
    const cooked1 = await cook(this.args.rawText);
    this.cooked = cooked1;
  }
  static #_3 = dt7948.n(this.prototype, "loadCookedText", [action]);
  calculateOffsetHeight(element1) {
    if (!this.args.onOffsetHeightCalculated) {
      return;
    }
    return this.args.onOffsetHeightCalculated(element1?.offsetHeight);
  }
  static #_4 = dt7948.n(this.prototype, "calculateOffsetHeight", [action]);
  buildOneboxes(element1) {
    if (this.args.paintOneboxes && this.cooked !== null) {
      loadOneboxes(element1, ajax, this.args.topicId, this.args.categoryId, this.siteSettings.max_oneboxes_per_post, false // refresh
      );
    }
  }
  static #_5 = dt7948.n(this.prototype, "buildOneboxes", [action]);
  resolveShortUrls(element1) {
    resolveAllShortUrls(ajax, this.siteSettings, element1, this.args.opts);
  }
  static #_6 = dt7948.n(this.prototype, "resolveShortUrls", [action]);
  static #_7 = setComponentTemplate(createTemplateFactory(
  /*
    
      <div
        ...attributes
        {{didUpdate this.buildOneboxes this.cooked}}
        {{didUpdate this.resolveShortUrls this.cooked}}
        {{didUpdate this.calculateOffsetHeight this.cooked}}
        {{didUpdate this.loadCookedText @rawText}}
      >
        {{this.cooked}}
      </div>
    
  */
  {
    "id": "Gnx6a88S",
    "block": "[[[1,\"\\n    \"],[11,0],[17,1],[4,[32,0],[[30,0,[\"buildOneboxes\"]],[30,0,[\"cooked\"]]],null],[4,[32,0],[[30,0,[\"resolveShortUrls\"]],[30,0,[\"cooked\"]]],null],[4,[32,0],[[30,0,[\"calculateOffsetHeight\"]],[30,0,[\"cooked\"]]],null],[4,[32,0],[[30,0,[\"loadCookedText\"]],[30,2]],null],[12],[1,\"\\n      \"],[1,[30,0,[\"cooked\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&attrs\",\"@rawText\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/cook-text.js",
    "scope": () => [didUpdate],
    "isStrictMode": true
  }), this);
}