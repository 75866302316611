import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import DButton from "discourse/components/d-button";
import DModal from "discourse/components/d-modal";
import PreferenceCheckbox from "discourse/components/preference-checkbox";
import I18n from "discourse-i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
const REPLIES_SUBSET = "replies";
const TOPICS_SUBSET = "topics";
export default class DismissNew extends Component {
  static #_ = dt7948.g(this.prototype, "untrack", [tracked], function () {
    return false;
  });
  #untrack = (dt7948.i(this, "untrack"), void 0);
  static #_2 = dt7948.g(this.prototype, "dismissTopics", [tracked], function () {
    return true;
  });
  #dismissTopics = (dt7948.i(this, "dismissTopics"), void 0);
  static #_3 = dt7948.g(this.prototype, "dismissPosts", [tracked], function () {
    return true;
  });
  #dismissPosts = (dt7948.i(this, "dismissPosts"), void 0);
  constructor() {
    super(...arguments);
    if (this.args.model.subset === "replies") {
      this.dismissTopics = false;
    }
    if (this.args.model.subset === "topics") {
      this.dismissPosts = false;
    }
  }
  get partialDismiss() {
    return (this.selectedTopics?.length || 0) !== 0;
  }
  get dismissNewTopicsLabel() {
    return "topics.bulk.dismiss_new_modal.topics" + (this.partialDismiss ? "_with_count" : "");
  }
  get dismissNewRepliesLabel() {
    return "topics.bulk.dismiss_new_modal.replies" + (this.partialDismiss ? "_with_count" : "");
  }
  get showDismissNewTopics() {
    if (this.partialDismiss) {
      return this.countNewTopics > 0;
    }
    return this.subset === TOPICS_SUBSET || !this.subset;
  }
  get showDismissNewReplies() {
    if (this.partialDismiss) {
      return this.countNewReplies > 0;
    }
    return this.subset === REPLIES_SUBSET || !this.subset;
  }
  get countNewTopics() {
    const topics1 = this.selectedTopics;
    if (!topics1?.length) {
      return 0;
    }
    return topics1.filter(topic1 => !topic1.unread_posts).length;
  }
  get countNewReplies() {
    const topics1 = this.selectedTopics;
    if (!topics1?.length) {
      return 0;
    }
    return topics1.filter(topic1 => topic1.unread_posts).length;
  }
  get subset() {
    return this.args.model.subset;
  }
  get selectedTopics() {
    return this.args.model.selectedTopics;
  }
  get modalTitle() {
    return I18n.t("topics.bulk.dismiss_new_modal.title");
  }
  dismissed() {
    this.args.model.dismissCallback({
      dismissTopics: this.dismissTopics,
      dismissPosts: this.dismissPosts,
      untrack: this.untrack
    });
    this.args.closeModal();
  }
  static #_4 = dt7948.n(this.prototype, "dismissed", [action]);
  static #_5 = setComponentTemplate(createTemplateFactory(
  /*
    
      <DModal
        @closeModal={{@closeModal}}
        @title={{this.modalTitle}}
        @inline={{@inline}}
      >
        <:body>
          <p>
            {{#if this.showDismissNewTopics}}
              <PreferenceCheckbox
                @labelKey={{this.dismissNewTopicsLabel}}
                @labelCount={{this.countNewTopics}}
                @checked={{this.dismissTopics}}
                class="dismiss-topics"
              />
            {{/if}}
            {{#if this.showDismissNewReplies}}
              <PreferenceCheckbox
                @labelKey={{this.dismissNewRepliesLabel}}
                @labelCount={{this.countNewReplies}}
                @checked={{this.dismissPosts}}
                class="dismiss-posts"
              />
            {{/if}}
            <PreferenceCheckbox
              @labelKey="topics.bulk.dismiss_new_modal.untrack"
              @checked={{this.untrack}}
              class="untrack"
            />
          </p>
        </:body>
        <:footer>
          <DButton
            @action={{this.dismissed}}
            @icon="check"
            @label="topics.bulk.dismiss"
            id="dismiss-read-confirm"
            class="btn-primary"
          />
        </:footer>
      </DModal>
    
  */
  {
    "id": "3tzgzDzS",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@closeModal\",\"@title\",\"@inline\"],[[30,1],[30,0,[\"modalTitle\"]],[30,2]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,2],[12],[1,\"\\n\"],[41,[30,0,[\"showDismissNewTopics\"]],[[[1,\"            \"],[8,[32,1],[[24,0,\"dismiss-topics\"]],[[\"@labelKey\",\"@labelCount\",\"@checked\"],[[30,0,[\"dismissNewTopicsLabel\"]],[30,0,[\"countNewTopics\"]],[30,0,[\"dismissTopics\"]]]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"showDismissNewReplies\"]],[[[1,\"            \"],[8,[32,1],[[24,0,\"dismiss-posts\"]],[[\"@labelKey\",\"@labelCount\",\"@checked\"],[[30,0,[\"dismissNewRepliesLabel\"]],[30,0,[\"countNewReplies\"]],[30,0,[\"dismissPosts\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"          \"],[8,[32,1],[[24,0,\"untrack\"]],[[\"@labelKey\",\"@checked\"],[\"topics.bulk.dismiss_new_modal.untrack\",[30,0,[\"untrack\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,2],[[24,1,\"dismiss-read-confirm\"],[24,0,\"btn-primary\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"dismissed\"]],\"check\",\"topics.bulk.dismiss\"]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@inline\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/modal/dismiss-new.js",
    "scope": () => [DModal, PreferenceCheckbox, DButton],
    "isStrictMode": true
  }), this);
}