/* import __COLOCATED_TEMPLATE__ from './custom-section.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { getOwner } from "@ember/application";
import { service } from "@ember/service";
import CommonCommunitySection from "discourse/lib/sidebar/common/community-section/section";
import Section from "discourse/lib/sidebar/section";
import AdminCommunitySection from "discourse/lib/sidebar/user/community-section/admin-section";
import TEMPLATE from "./custom-section.hbs";
import { setComponentTemplate } from "@ember/component";
export default class SidebarCustomSection extends Component {
  static #_ = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_2 = dt7948.g(this.prototype, "navigationMenu", [service]);
  #navigationMenu = (dt7948.i(this, "navigationMenu"), void 0);
  static #_3 = dt7948.g(this.prototype, "site", [service]);
  #site = (dt7948.i(this, "site"), void 0);
  static #_4 = dt7948.g(this.prototype, "siteSettings", [service]);
  #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
  static #_5 = dt7948.g(this.prototype, "section", [tracked]);
  #section = (dt7948.i(this, "section"), void 0);
  constructor() {
    super(...arguments);
    this.section = this.#initializeSection();
  }
  willDestroy() {
    this.section.teardown?.();
    super.willDestroy();
  }
  #initializeSection() {
    let sectionClass = Section;
    switch (this.args.sectionData.section_type) {
      case "community":
        sectionClass = this.currentUser?.admin ? AdminCommunitySection : CommonCommunitySection;
        break;
    }
    return new sectionClass({
      section: this.args.sectionData,
      owner: getOwner(this)
    });
  }
}
setComponentTemplate(TEMPLATE, SidebarCustomSection);