import Component from "@glimmer/component";
import { inject as service } from "@ember/service";
import avatar from "discourse/helpers/bound-avatar-template";
import { userPath } from "discourse/lib/url";
import i18n from "discourse-common/helpers/i18n";
import getURL from "discourse-common/lib/get-url";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class SmallUserList extends Component {
  static #_ = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  smallUserAtts(user1) {
    return {
      template: user1.avatar_template,
      username: user1.username,
      post_url: user1.post_url,
      url: userPath(user1.username_lower),
      unknown: user1.unknown
    };
  }
  get users() {
    let users1 = this.args.data.users;
    if (this.args.data.addSelf && !users1.some(u1 => u1.username === this.currentUser.username)) {
      users1 = users1.concat(this.smallUserAtts(this.currentUser));
    }
    return users1;
  }
  get postUrl() {
    const url1 = this.users.find(user1 => user1.post_url);
    if (url1) {
      return getURL(url1);
    }
  }
  static #_2 = setComponentTemplate(createTemplateFactory(
  /*
    
      {{#each this.users as |user|}}
        {{#if user.unknown}}
          <div
            title={{i18n "post.unknown_user"}}
            class="unknown"
            role="listitem"
          ></div>
        {{else}}
          <a
            class="trigger-user-card"
            data-user-card={{user.username}}
            title={{user.username}}
            aria-hidden="false"
            role="listitem"
          >
            {{avatar user.template "tiny"}}
          </a>
        {{/if}}
      {{/each}}
  
      {{#if @data.description}}
        {{#if this.postUrl}}
          <a href={{this.postUrl}}>
            <span aria-hidden="true" class="list-description">
              {{i18n @data.description count=@data.count}}
            </span>
          </a>
        {{else}}
          <span aria-hidden="true" class="list-description">
            {{i18n @data.description count=@data.count}}
          </span>
        {{/if}}
      {{/if}}
    
  */
  {
    "id": "0sT159n0",
    "block": "[[[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"users\"]]],null]],null],null,[[[41,[30,1,[\"unknown\"]],[[[1,\"        \"],[10,0],[15,\"title\",[28,[32,0],[\"post.unknown_user\"],null]],[14,0,\"unknown\"],[14,\"role\",\"listitem\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,3],[14,0,\"trigger-user-card\"],[15,\"data-user-card\",[30,1,[\"username\"]]],[15,\"title\",[30,1,[\"username\"]]],[14,\"aria-hidden\",\"false\"],[14,\"role\",\"listitem\"],[12],[1,\"\\n          \"],[1,[28,[32,1],[[30,1,[\"template\"]],\"tiny\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]]],[1]],null],[1,\"\\n\"],[41,[30,2,[\"description\"]],[[[41,[30,0,[\"postUrl\"]],[[[1,\"        \"],[10,3],[15,6,[30,0,[\"postUrl\"]]],[12],[1,\"\\n          \"],[10,1],[14,\"aria-hidden\",\"true\"],[14,0,\"list-description\"],[12],[1,\"\\n            \"],[1,[28,[32,0],[[30,2,[\"description\"]]],[[\"count\"],[[30,2,[\"count\"]]]]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[14,\"aria-hidden\",\"true\"],[14,0,\"list-description\"],[12],[1,\"\\n          \"],[1,[28,[32,0],[[30,2,[\"description\"]]],[[\"count\"],[[30,2,[\"count\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]]],[]],null],[1,\"  \"]],[\"user\",\"@data\"],false,[\"each\",\"-track-array\",\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/small-user-list.js",
    "scope": () => [i18n, avatar],
    "isStrictMode": true
  }), this);
}