import Component from "@glimmer/component";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class ActivationControls extends Component {
  static #_ = dt7948.g(this.prototype, "siteSettings", [service]);
  #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
  get canEditEmail() {
    return this.siteSettings.enable_local_logins || this.siteSettings.email_editable;
  }
  static #_2 = setComponentTemplate(createTemplateFactory(
  /*
    
      <div class="activation-controls">
        {{#unless this.siteSettings.must_approve_users}}
          <DButton
            @action={{@sendActivationEmail}}
            @label="login.resend_title"
            @icon="envelope"
            class="btn-primary resend"
          />
        {{/unless}}
  
        {{#if this.canEditEmail}}
          <DButton
            @action={{@editActivationEmail}}
            @label="login.change_email"
            @icon="pencil-alt"
            class="edit-email"
          />
        {{/if}}
      </div>
    
  */
  {
    "id": "HfmmpOKe",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"activation-controls\"],[12],[1,\"\\n\"],[41,[51,[30,0,[\"siteSettings\",\"must_approve_users\"]]],[[[1,\"        \"],[8,[32,0],[[24,0,\"btn-primary resend\"]],[[\"@action\",\"@label\",\"@icon\"],[[30,1],\"login.resend_title\",\"envelope\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"canEditEmail\"]],[[[1,\"        \"],[8,[32,0],[[24,0,\"edit-email\"]],[[\"@action\",\"@label\",\"@icon\"],[[30,2],\"login.change_email\",\"pencil-alt\"]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@sendActivationEmail\",\"@editActivationEmail\"],false,[\"unless\",\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/activation-controls.js",
    "scope": () => [DButton],
    "isStrictMode": true
  }), this);
}