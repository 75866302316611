/* import __COLOCATED_TEMPLATE__ from './download-calendar.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { downloadGoogle, downloadIcs } from "discourse/lib/download-calendar";
import TEMPLATE from "./download-calendar.hbs";
import { setComponentTemplate } from "@ember/component";
export default class DownloadCalendar extends Component {
  static #_ = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_2 = dt7948.g(this.prototype, "selectedCalendar", [tracked], function () {
    return "ics";
  });
  #selectedCalendar = (dt7948.i(this, "selectedCalendar"), void 0);
  static #_3 = dt7948.g(this.prototype, "remember", [tracked], function () {
    return false;
  });
  #remember = (dt7948.i(this, "remember"), void 0);
  downloadCalendar() {
    if (this.remember) {
      this.currentUser.set("user_option.default_calendar", this.selectedCalendar);
      this.currentUser.save(["default_calendar"]);
    }
    if (this.selectedCalendar === "ics") {
      downloadIcs(this.args.model.calendar.title, this.args.model.calendar.dates, {
        recurrenceRule: this.args.model.calendar.recurrenceRule,
        location: this.args.model.calendar.location,
        details: this.args.model.calendar.details
      });
    } else {
      downloadGoogle(this.args.model.calendar.title, this.args.model.calendar.dates, {
        recurrenceRule: this.args.model.calendar.recurrenceRule,
        location: this.args.model.calendar.location,
        details: this.args.model.calendar.details
      });
    }
    this.args.closeModal();
  }
  static #_4 = dt7948.n(this.prototype, "downloadCalendar", [action]);
  selectCalendar(calendar) {
    this.selectedCalendar = calendar;
  }
  static #_5 = dt7948.n(this.prototype, "selectCalendar", [action]);
}
setComponentTemplate(TEMPLATE, DownloadCalendar);