/* import __COLOCATED_TEMPLATE__ from './second-factor-confirm-phrase.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import I18n from "discourse-i18n";
import TEMPLATE from "./second-factor-confirm-phrase.hbs";
import { setComponentTemplate } from "@ember/component";
export default class SecondFactorConfirmPhrase extends Component {
  static #_ = dt7948.g(this.prototype, "dialog", [service]);
  #dialog = (dt7948.i(this, "dialog"), void 0);
  static #_2 = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_3 = dt7948.g(this.prototype, "confirmPhraseInput", [tracked], function () {
    return "";
  });
  #confirmPhraseInput = (dt7948.i(this, "confirmPhraseInput"), void 0);
  disabledString = I18n.t("user.second_factor.disable");
  onConfirmPhraseInput() {
    if (this.confirmPhraseInput === this.disabledString) {
      this.dialog.set("confirmButtonDisabled", false);
    } else {
      this.dialog.set("confirmButtonDisabled", true);
    }
  }
  static #_4 = dt7948.n(this.prototype, "onConfirmPhraseInput", [action]);
}
setComponentTemplate(TEMPLATE, SecondFactorConfirmPhrase);