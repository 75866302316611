import Component from "@glimmer/component";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class MessagesSecondaryNav extends Component {
  get messagesNav() {
    return document.getElementById("user-navigation-secondary__horizontal-nav");
  }
  static #_ = setComponentTemplate(createTemplateFactory(
  /*
    
      {{#in-element this.messagesNav}}
        {{yield}}
      {{/in-element}}
    
  */
  {
    "id": "e9ozi6Gf",
    "block": "[[[1,\"\\n\"],[40,[[[1,\"      \"],[18,1,null],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[31,1],[[30,0,[\"messagesNav\"]]],null]],[1,\"  \"]],[\"&default\"],false,[\"in-element\",\"-in-el-null\",\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/user-nav/messages-secondary-nav.js",
    "isStrictMode": true
  }), this);
}