/* import __COLOCATED_TEMPLATE__ from './tags-section.hbs'; */
import Component from "@glimmer/component";
import "@glimmer/tracking";
let cached = esc(require('@glimmer/tracking')).cached;
import { action } from "@ember/object";
import { service } from "@ember/service";
import SidebarEditNavigationMenuTagsModal from "discourse/components/sidebar/edit-navigation-menu/tags-modal";
import { hasDefaultSidebarTags } from "discourse/lib/sidebar/helpers";
import PMTagSectionLink from "discourse/lib/sidebar/user/tags-section/pm-tag-section-link";
import TagSectionLink from "discourse/lib/sidebar/user/tags-section/tag-section-link";
import TEMPLATE from "./tags-section.hbs";
import esc from "../../../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";
import { setComponentTemplate } from "@ember/component";
export default class SidebarUserTagsSection extends Component {
  static #_ = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_2 = dt7948.g(this.prototype, "modal", [service]);
  #modal = (dt7948.i(this, "modal"), void 0);
  static #_3 = dt7948.g(this.prototype, "pmTopicTrackingState", [service]);
  #pmTopicTrackingState = (dt7948.i(this, "pmTopicTrackingState"), void 0);
  static #_4 = dt7948.g(this.prototype, "router", [service]);
  #router = (dt7948.i(this, "router"), void 0);
  static #_5 = dt7948.g(this.prototype, "site", [service]);
  #site = (dt7948.i(this, "site"), void 0);
  static #_6 = dt7948.g(this.prototype, "siteSettings", [service]);
  #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
  static #_7 = dt7948.g(this.prototype, "topicTrackingState", [service]);
  #topicTrackingState = (dt7948.i(this, "topicTrackingState"), void 0);
  constructor() {
    super(...arguments);
    this.callbackId = this.topicTrackingState.onStateChange(() => {
      this.sectionLinks.forEach(sectionLink => {
        if (sectionLink.refreshCounts) {
          sectionLink.refreshCounts();
        }
      });
    });
  }
  willDestroy() {
    super.willDestroy(...arguments);
    this.topicTrackingState.offStateChange(this.callbackId);
  }
  get sectionLinks() {
    const links = [];
    let tags;
    if (this.currentUser.sidebarTags.length > 0) {
      tags = this.currentUser.sidebarTags;
    } else {
      tags = this.site.navigation_menu_site_top_tags || [];
    }
    for (const tag of tags) {
      if (tag.pm_only) {
        links.push(new PMTagSectionLink({
          tag,
          currentUser: this.currentUser
        }));
      } else {
        links.push(new TagSectionLink({
          tag,
          topicTrackingState: this.topicTrackingState,
          currentUser: this.currentUser
        }));
      }
    }
    return links;
  }
  static #_8 = dt7948.n(this.prototype, "sectionLinks", [cached]);
  get shouldDisplayDefaultConfig() {
    return this.currentUser.admin && !this.hasDefaultSidebarTags;
  }
  get hasDefaultSidebarTags() {
    return hasDefaultSidebarTags(this.siteSettings);
  }
  showModal() {
    this.modal.show(SidebarEditNavigationMenuTagsModal);
  }
  static #_9 = dt7948.n(this.prototype, "showModal", [action]);
}
setComponentTemplate(TEMPLATE, SidebarUserTagsSection);