import { tracked } from "@glimmer/tracking";
import { registerDestructor } from "@ember/destroyable";
import Service from "@ember/service";
import { TrackedSet } from "@ember-compat/tracked-built-ins";
import { disableImplicitInjections } from "discourse/lib/implicit-injections";
const FooterService = dt7948.c(class FooterService extends Service {
  #hiders = new TrackedSet();
  static #_ = dt7948.g(this.prototype, "_showFooterOverride", [tracked], function () {
    return null;
  });
  #_showFooterOverride = (dt7948.i(this, "_showFooterOverride"), void 0);
  get showFooter() {
    return this._showFooterOverride ?? this.#hiders.size === 0;
  }
  set showFooter(value) {
    if (value === true) {
      this._showFooterOverride = null;
    } else {
      this._showFooterOverride = value;
    }
  }
  registerHider(destroyable) {
    this.#hiders.add(destroyable);
    registerDestructor(destroyable, () => this.#hiders.delete(destroyable));
  }
}, [disableImplicitInjections]);
export default FooterService;