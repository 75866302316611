/* import __COLOCATED_TEMPLATE__ from './move-to-topic.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { isEmpty } from "@ember/utils";
import DiscourseURL from "discourse/lib/url";
import { mergeTopic, movePosts } from "discourse/models/topic";
import I18n from "discourse-i18n";
import TEMPLATE from "./move-to-topic.hbs";
import { setComponentTemplate } from "@ember/component";
export default class MoveToTopic extends Component {
  static #_ = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_2 = dt7948.g(this.prototype, "site", [service]);
  #site = (dt7948.i(this, "site"), void 0);
  static #_3 = dt7948.g(this.prototype, "topicName", [tracked]);
  #topicName = (dt7948.i(this, "topicName"), void 0);
  static #_4 = dt7948.g(this.prototype, "saving", [tracked], function () {
    return false;
  });
  #saving = (dt7948.i(this, "saving"), void 0);
  static #_5 = dt7948.g(this.prototype, "categoryId", [tracked]);
  #categoryId = (dt7948.i(this, "categoryId"), void 0);
  static #_6 = dt7948.g(this.prototype, "tags", [tracked]);
  #tags = (dt7948.i(this, "tags"), void 0);
  static #_7 = dt7948.g(this.prototype, "participants", [tracked], function () {
    return [];
  });
  #participants = (dt7948.i(this, "participants"), void 0);
  static #_8 = dt7948.g(this.prototype, "chronologicalOrder", [tracked], function () {
    return false;
  });
  #chronologicalOrder = (dt7948.i(this, "chronologicalOrder"), void 0);
  static #_9 = dt7948.g(this.prototype, "selection", [tracked], function () {
    return "new_topic";
  });
  #selection = (dt7948.i(this, "selection"), void 0);
  static #_10 = dt7948.g(this.prototype, "selectedTopicId", [tracked]);
  #selectedTopicId = (dt7948.i(this, "selectedTopicId"), void 0);
  static #_11 = dt7948.g(this.prototype, "flash", [tracked]);
  #flash = (dt7948.i(this, "flash"), void 0);
  constructor() {
    super(...arguments);
    if (this.args.model.topic.isPrivateMessage) {
      this.selection = this.canSplitToPM ? "new_message" : "existing_message";
    } else if (!this.canSplitTopic) {
      this.selection = "existing_topic";
    }
  }
  get newTopic() {
    return this.selection === "new_topic";
  }
  get existingTopic() {
    return this.selection === "existing_topic";
  }
  get newMessage() {
    return this.selection === "new_message";
  }
  get existingMessage() {
    return this.selection === "existing_message";
  }
  get buttonDisabled() {
    return this.saving || isEmpty(this.selectedTopicId) && isEmpty(this.topicName);
  }
  get buttonTitle() {
    if (this.newTopic) {
      return "topic.split_topic.title";
    } else if (this.existingTopic) {
      return "topic.merge_topic.title";
    } else if (this.newMessage) {
      return "topic.move_to_new_message.title";
    } else if (this.existingMessage) {
      return "topic.move_to_existing_message.title";
    } else {
      return "saving";
    }
  }
  get canSplitTopic() {
    return !this.args.model.selectedAllPosts && this.args.model.selectedPosts.length > 0 && this.args.model.selectedPosts.sort((a, b) => a.post_number - b.post_number)[0].post_type === this.site.get("post_types.regular");
  }
  get canSplitToPM() {
    return this.canSplitTopic && this.currentUser?.admin;
  }
  get canAddTags() {
    return this.site.can_create_tag;
  }
  get canTagMessages() {
    return this.site.can_tag_pms;
  }
  performMove() {
    if (this.newTopic) {
      this.movePostsTo("newTopic");
    } else if (this.existingTopic) {
      this.movePostsTo("existingTopic");
    } else if (this.newMessage) {
      this.movePostsTo("newMessage");
    } else if (this.existingMessage) {
      this.movePostsTo("existingMessage");
    }
  }
  static #_12 = dt7948.n(this.prototype, "performMove", [action]);
  async movePostsTo(type) {
    this.saving = true;
    this.flash = null;
    let mergeOptions, moveOptions;
    if (type === "existingTopic") {
      mergeOptions = {
        destination_topic_id: this.selectedTopicId,
        chronological_order: this.chronologicalOrder
      };
      moveOptions = {
        post_ids: this.args.model.selectedPostIds,
        ...mergeOptions
      };
    } else if (type === "existingMessage") {
      mergeOptions = {
        destination_topic_id: this.selectedTopicId,
        participants: this.participants.join(","),
        archetype: "private_message",
        chronological_order: this.chronologicalOrder
      };
      moveOptions = {
        post_ids: this.args.model.selectedPostIds,
        ...mergeOptions
      };
    } else if (type === "newTopic") {
      mergeOptions = {};
      moveOptions = {
        title: this.topicName,
        post_ids: this.args.model.selectedPostIds,
        category_id: this.categoryId,
        tags: this.tags
      };
    } else {
      mergeOptions = {};
      moveOptions = {
        title: this.topicName,
        post_ids: this.args.model.selectedPostIds,
        tags: this.tags,
        archetype: "private_message"
      };
    }
    try {
      let result;
      if (this.args.model.selectedAllPosts) {
        result = await mergeTopic(this.args.model.topic.id, mergeOptions);
      } else {
        result = await movePosts(this.args.model.topic.id, moveOptions);
      }
      this.args.closeModal();
      this.args.model.toggleMultiSelect();
      DiscourseURL.routeTo(result.url);
    } catch {
      this.flash = I18n.t("topic.move_to.error");
    } finally {
      this.saving = false;
    }
  }
  static #_13 = dt7948.n(this.prototype, "movePostsTo", [action]);
  updateTopicName(newName) {
    this.topicName = newName;
  }
  static #_14 = dt7948.n(this.prototype, "updateTopicName", [action]);
  updateCategoryId(newId) {
    this.categoryId = newId;
  }
  static #_15 = dt7948.n(this.prototype, "updateCategoryId", [action]);
  updateTags(newTags) {
    this.tags = newTags;
  }
  static #_16 = dt7948.n(this.prototype, "updateTags", [action]);
}
setComponentTemplate(TEMPLATE, MoveToTopic);