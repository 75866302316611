/* import __COLOCATED_TEMPLATE__ from './bookmark-icon.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { computed } from "@ember/object";
import { isEmpty } from "@ember/utils";
import { NO_REMINDER_ICON, WITH_REMINDER_ICON } from "discourse/models/bookmark";
import I18n from "discourse-i18n";
import TEMPLATE from "./bookmark-icon.hbs";
export default class BookmarkIcon extends Component {
  tagName = "";
  bookmark = null;
  get icon() {
    if (!this.bookmark) {
      return NO_REMINDER_ICON;
    }
    if (!isEmpty(this.bookmark.reminder_at)) {
      return WITH_REMINDER_ICON;
    }
    return NO_REMINDER_ICON;
  }
  static #_ = dt7948.n(this.prototype, "icon", [computed("bookmark.reminder_at")]);
  get cssClasses() {
    return this.bookmark ? "bookmark-icon bookmark-icon__bookmarked" : "bookmark-icon";
  }
  static #_2 = dt7948.n(this.prototype, "cssClasses", [computed("bookmark")]);
  get title() {
    if (!this.bookmark) {
      return I18n.t("bookmarks.create");
    }
    return this.bookmark.reminderTitle;
  }
  static #_3 = dt7948.n(this.prototype, "title", [computed("bookmark.title")]);
}
setComponentTemplate(TEMPLATE, BookmarkIcon);