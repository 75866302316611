/* import __COLOCATED_TEMPLATE__ from './convert-to-public-topic.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import I18n from "discourse-i18n";
import TEMPLATE from "./convert-to-public-topic.hbs";
import { setComponentTemplate } from "@ember/component";
export default class ConvertToPublicTopic extends Component {
  static #_ = dt7948.g(this.prototype, "appEvents", [service]);
  #appEvents = (dt7948.i(this, "appEvents"), void 0);
  static #_2 = dt7948.g(this.prototype, "publicCategoryId", [tracked]);
  #publicCategoryId = (dt7948.i(this, "publicCategoryId"), void 0);
  static #_3 = dt7948.g(this.prototype, "saving", [tracked], function () {
    return false;
  });
  #saving = (dt7948.i(this, "saving"), void 0);
  static #_4 = dt7948.g(this.prototype, "flash", [tracked]);
  #flash = (dt7948.i(this, "flash"), void 0);
  async makePublic() {
    try {
      this.saving = true;
      await this.args.model.topic.convertTopic("public", {
        categoryId: this.publicCategoryId
      });
      this.args.model.topic.set("archetype", "regular");
      this.args.model.topic.set("category_id", this.publicCategoryId);
      this.appEvents.trigger("header:show-topic", this.args.model.topic);
      this.saving = false;
      this.args.closeModal();
    } catch (e) {
      this.flash = I18n.t("generic_error");
      this.saving = false;
    }
  }
  static #_5 = dt7948.n(this.prototype, "makePublic", [action]);
}
setComponentTemplate(TEMPLATE, ConvertToPublicTopic);