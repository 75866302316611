/* import __COLOCATED_TEMPLATE__ from './login.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { schedule } from "@ember/runloop";
import { service } from "@ember/service";
import { isEmpty } from "@ember/utils";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import cookie, { removeCookie } from "discourse/lib/cookie";
import { areCookiesEnabled } from "discourse/lib/utilities";
import { wavingHandURL } from "discourse/lib/waving-hand-url";
import { getPasskeyCredential, isWebauthnSupported } from "discourse/lib/webauthn";
import { findAll } from "discourse/models/login-method";
import { SECOND_FACTOR_METHODS } from "discourse/models/user";
import escape from "discourse-common/lib/escape";
import getURL from "discourse-common/lib/get-url";
import I18n from "discourse-i18n";
import TEMPLATE from "./login.hbs";
import { setComponentTemplate } from "@ember/component";
export default class Login extends Component {
  static #_ = dt7948.g(this.prototype, "capabilities", [service]);
  #capabilities = (dt7948.i(this, "capabilities"), void 0);
  static #_2 = dt7948.g(this.prototype, "dialog", [service]);
  #dialog = (dt7948.i(this, "dialog"), void 0);
  static #_3 = dt7948.g(this.prototype, "siteSettings", [service]);
  #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
  static #_4 = dt7948.g(this.prototype, "site", [service]);
  #site = (dt7948.i(this, "site"), void 0);
  static #_5 = dt7948.g(this.prototype, "login", [service]);
  #login = (dt7948.i(this, "login"), void 0);
  static #_6 = dt7948.g(this.prototype, "loggingIn", [tracked], function () {
    return false;
  });
  #loggingIn = (dt7948.i(this, "loggingIn"), void 0);
  static #_7 = dt7948.g(this.prototype, "loggedIn", [tracked], function () {
    return false;
  });
  #loggedIn = (dt7948.i(this, "loggedIn"), void 0);
  static #_8 = dt7948.g(this.prototype, "showLoginButtons", [tracked], function () {
    return true;
  });
  #showLoginButtons = (dt7948.i(this, "showLoginButtons"), void 0);
  static #_9 = dt7948.g(this.prototype, "showSecondFactor", [tracked], function () {
    return false;
  });
  #showSecondFactor = (dt7948.i(this, "showSecondFactor"), void 0);
  static #_10 = dt7948.g(this.prototype, "loginPassword", [tracked], function () {
    return "";
  });
  #loginPassword = (dt7948.i(this, "loginPassword"), void 0);
  static #_11 = dt7948.g(this.prototype, "loginName", [tracked], function () {
    return "";
  });
  #loginName = (dt7948.i(this, "loginName"), void 0);
  static #_12 = dt7948.g(this.prototype, "flash", [tracked], function () {
    return this.args.model.flash;
  });
  #flash = (dt7948.i(this, "flash"), void 0);
  static #_13 = dt7948.g(this.prototype, "flashType", [tracked], function () {
    return this.args.model.flashType;
  });
  #flashType = (dt7948.i(this, "flashType"), void 0);
  static #_14 = dt7948.g(this.prototype, "canLoginLocal", [tracked], function () {
    return this.siteSettings.enable_local_logins;
  });
  #canLoginLocal = (dt7948.i(this, "canLoginLocal"), void 0);
  static #_15 = dt7948.g(this.prototype, "canLoginLocalWithEmail", [tracked], function () {
    return this.siteSettings.enable_local_logins_via_email;
  });
  #canLoginLocalWithEmail = (dt7948.i(this, "canLoginLocalWithEmail"), void 0);
  static #_16 = dt7948.g(this.prototype, "secondFactorMethod", [tracked], function () {
    return SECOND_FACTOR_METHODS.TOTP;
  });
  #secondFactorMethod = (dt7948.i(this, "secondFactorMethod"), void 0);
  static #_17 = dt7948.g(this.prototype, "securityKeyCredential", [tracked]);
  #securityKeyCredential = (dt7948.i(this, "securityKeyCredential"), void 0);
  static #_18 = dt7948.g(this.prototype, "otherMethodAllowed", [tracked]);
  #otherMethodAllowed = (dt7948.i(this, "otherMethodAllowed"), void 0);
  static #_19 = dt7948.g(this.prototype, "secondFactorRequired", [tracked]);
  #secondFactorRequired = (dt7948.i(this, "secondFactorRequired"), void 0);
  static #_20 = dt7948.g(this.prototype, "backupEnabled", [tracked]);
  #backupEnabled = (dt7948.i(this, "backupEnabled"), void 0);
  static #_21 = dt7948.g(this.prototype, "totpEnabled", [tracked]);
  #totpEnabled = (dt7948.i(this, "totpEnabled"), void 0);
  static #_22 = dt7948.g(this.prototype, "showSecurityKey", [tracked]);
  #showSecurityKey = (dt7948.i(this, "showSecurityKey"), void 0);
  static #_23 = dt7948.g(this.prototype, "securityKeyChallenge", [tracked]);
  #securityKeyChallenge = (dt7948.i(this, "securityKeyChallenge"), void 0);
  static #_24 = dt7948.g(this.prototype, "securityKeyAllowedCredentialIds", [tracked]);
  #securityKeyAllowedCredentialIds = (dt7948.i(this, "securityKeyAllowedCredentialIds"), void 0);
  static #_25 = dt7948.g(this.prototype, "secondFactorToken", [tracked]);
  #secondFactorToken = (dt7948.i(this, "secondFactorToken"), void 0);
  get awaitingApproval() {
    return this.args.model.awaitingApproval && !this.canLoginLocal && !this.canLoginLocalWithEmail;
  }
  get loginDisabled() {
    return this.loggingIn || this.loggedIn;
  }
  get wavingHandURL() {
    return wavingHandURL();
  }
  get modalBodyClasses() {
    const classes = ["login-modal-body"];
    if (this.awaitingApproval) {
      classes.push("awaiting-approval");
    }
    if (this.hasAtLeastOneLoginButton && !this.showSecondFactor && !this.showSecurityKey) {
      classes.push("has-alt-auth");
    }
    if (!this.canLoginLocal) {
      classes.push("no-local-login");
    }
    if (this.showSecondFactor || this.showSecurityKey) {
      classes.push("second-factor");
    }
    return classes.join(" ");
  }
  get canUsePasskeys() {
    return this.siteSettings.enable_local_logins && this.siteSettings.enable_passkeys && isWebauthnSupported();
  }
  get hasAtLeastOneLoginButton() {
    return findAll().length > 0 || this.canUsePasskeys;
  }
  get hasNoLoginOptions() {
    return !this.hasAtLeastOneLoginButton && !this.canLoginLocal;
  }
  get loginButtonLabel() {
    return this.loggingIn ? "login.logging_in" : "login.title";
  }
  get showSignupLink() {
    return this.args.model.canSignUp && !this.loggingIn && !this.showSecondFactor;
  }
  get adminLoginPath() {
    return getURL("/u/admin-login");
  }
  async passkeyLogin() {
    let mediation = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "optional";
    try {
      const publicKeyCredential = await getPasskeyCredential(e => this.dialog.alert(e), mediation, this.capabilities.isFirefox);
      if (publicKeyCredential) {
        const authResult = await ajax("/session/passkey/auth.json", {
          type: "POST",
          data: {
            publicKeyCredential
          }
        });
        if (authResult && !authResult.error) {
          const destinationUrl = cookie("destination_url");
          if (destinationUrl) {
            removeCookie("destination_url");
            window.location.assign(destinationUrl);
          } else {
            window.location.reload();
          }
        } else {
          this.dialog.alert(authResult.error);
        }
      }
    } catch (e) {
      popupAjaxError(e);
    }
  }
  static #_26 = dt7948.n(this.prototype, "passkeyLogin", [action]);
  preloadLogin() {
    const prefillUsername = document.querySelector("#hidden-login-form input[name=username]")?.value;
    if (prefillUsername) {
      this.loginName = prefillUsername;
      this.loginPassword = document.querySelector("#hidden-login-form input[name=password]").value;
    } else if (cookie("email")) {
      this.loginName = cookie("email");
    }
  }
  static #_27 = dt7948.n(this.prototype, "preloadLogin", [action]);
  securityKeyCredentialChanged(value) {
    this.securityKeyCredential = value;
  }
  static #_28 = dt7948.n(this.prototype, "securityKeyCredentialChanged", [action]);
  flashChanged(value) {
    this.flash = value;
  }
  static #_29 = dt7948.n(this.prototype, "flashChanged", [action]);
  flashTypeChanged(value) {
    this.flashType = value;
  }
  static #_30 = dt7948.n(this.prototype, "flashTypeChanged", [action]);
  loginNameChanged(event) {
    this.loginName = event.target.value;
  }
  static #_31 = dt7948.n(this.prototype, "loginNameChanged", [action]);
  async triggerLogin() {
    if (this.loginDisabled) {
      return;
    }
    if (isEmpty(this.loginName) || isEmpty(this.loginPassword)) {
      this.flash = I18n.t("login.blank_username_or_password");
      this.flashType = "error";
      return;
    }
    try {
      this.loggingIn = true;
      const result = await ajax("/session", {
        type: "POST",
        data: {
          login: this.loginName,
          password: this.loginPassword,
          second_factor_token: this.securityKeyCredential || this.secondFactorToken,
          second_factor_method: this.secondFactorMethod,
          timezone: moment.tz.guess()
        }
      });
      if (result && result.error) {
        this.loggingIn = false;
        this.flash = null;
        if ((result.security_key_enabled || result.totp_enabled) && !this.secondFactorRequired) {
          this.otherMethodAllowed = result.multiple_second_factor_methods;
          this.secondFactorRequired = true;
          this.showLoginButtons = false;
          this.backupEnabled = result.backup_enabled;
          this.totpEnabled = result.totp_enabled;
          this.showSecondFactor = result.totp_enabled;
          this.showSecurityKey = result.security_key_enabled;
          this.secondFactorMethod = result.security_key_enabled ? SECOND_FACTOR_METHODS.SECURITY_KEY : SECOND_FACTOR_METHODS.TOTP;
          this.securityKeyChallenge = result.challenge;
          this.securityKeyAllowedCredentialIds = result.allowed_credential_ids;

          // only need to focus the 2FA input for TOTP
          if (!this.showSecurityKey) {
            schedule("afterRender", () => document.getElementById("second-factor").querySelector("input").focus());
          }
          return;
        } else if (result.reason === "not_activated") {
          this.args.model.showNotActivated({
            username: this.loginName,
            sentTo: escape(result.sent_to_email),
            currentEmail: escape(result.current_email)
          });
        } else if (result.reason === "suspended") {
          this.args.closeModal();
          this.dialog.alert(result.error);
        } else {
          this.flash = result.error;
          this.flashType = "error";
        }
      } else {
        this.loggedIn = true;
        // Trigger the browser's password manager using the hidden static login form:
        const hiddenLoginForm = document.getElementById("hidden-login-form");
        const applyHiddenFormInputValue = (value, key) => {
          if (!hiddenLoginForm) {
            return;
          }
          hiddenLoginForm.querySelector(`input[name=${key}]`).value = value;
        };
        const destinationUrl = cookie("destination_url");
        const ssoDestinationUrl = cookie("sso_destination_url");
        applyHiddenFormInputValue(this.loginName, "username");
        applyHiddenFormInputValue(this.loginPassword, "password");
        if (ssoDestinationUrl) {
          removeCookie("sso_destination_url");
          window.location.assign(ssoDestinationUrl);
          return;
        } else if (destinationUrl) {
          // redirect client to the original URL
          removeCookie("destination_url");
          applyHiddenFormInputValue(destinationUrl, "redirect");
        } else {
          applyHiddenFormInputValue(window.location.href, "redirect");
        }
        if (hiddenLoginForm) {
          if (navigator.userAgent.match(/(iPad|iPhone|iPod)/g) && navigator.userAgent.match(/Safari/g)) {
            // In case of Safari on iOS do not submit hidden login form
            window.location.href = hiddenLoginForm.querySelector("input[name=redirect]").value;
          } else {
            hiddenLoginForm.submit();
          }
        }
        return;
      }
    } catch (e) {
      // Failed to login
      if (e.jqXHR && e.jqXHR.status === 429) {
        this.flash = I18n.t("login.rate_limit");
        this.flashType = "error";
      } else if (e.jqXHR && e.jqXHR.status === 503 && e.jqXHR.responseJSON.error_type === "read_only") {
        this.flash = I18n.t("read_only_mode.login_disabled");
        this.flashType = "error";
      } else if (!areCookiesEnabled()) {
        this.flash = I18n.t("login.cookies_error");
        this.flashType = "error";
      } else {
        this.flash = I18n.t("login.error");
        this.flashType = "error";
      }
      this.loggingIn = false;
    }
  }
  static #_32 = dt7948.n(this.prototype, "triggerLogin", [action]);
  async externalLoginAction(loginMethod) {
    if (this.loginDisabled) {
      return;
    }
    this.login.externalLogin(loginMethod, {
      signup: false,
      setLoggingIn: value => this.loggingIn = value
    });
  }
  static #_33 = dt7948.n(this.prototype, "externalLoginAction", [action]);
  createAccount() {
    let createAccountProps = {};
    if (this.loginName && this.loginName.indexOf("@") > 0) {
      createAccountProps.accountEmail = this.loginName;
      createAccountProps.accountUsername = null;
    } else {
      createAccountProps.accountUsername = this.loginName;
      createAccountProps.accountEmail = null;
    }
    this.args.model.showCreateAccount(createAccountProps);
  }
  static #_34 = dt7948.n(this.prototype, "createAccount", [action]);
}
setComponentTemplate(TEMPLATE, Login);