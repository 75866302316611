/* You might be looking for navigation-item. */
import Component from "@glimmer/component";
import { LinkTo } from "@ember/routing";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import concatClass from "discourse/helpers/concat-class";
import getURL from "discourse-common/lib/get-url";
import { iconHTML } from "discourse-common/lib/icon-library";
import I18n from "discourse-i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class NavItem extends Component {
  static #_ = dt7948.g(this.prototype, "router", [service]);
  #router = (dt7948.i(this, "router"), void 0);
  get contents() {
    const text1 = this.args.i18nLabel || I18n.t(this.args.label);
    if (this.args.icon) {
      return htmlSafe(`${iconHTML(this.args.icon)} ${text1}`);
    }
    return text1;
  }
  get active() {
    if (!this.args.route) {
      return;
    }
    // This is needed because the setting route is underneath /admin/plugins/:plugin_id,
    // but is not a child route of the plugin routes themselves. E.g. discourse-ai
    // for the plugin ID has its own nested routes defined in the plugin.
    if (this.router.currentRoute.name === "adminPlugins.show.settings") {
      return this.router.currentRoute.parent.params.plugin_id === this.args.routeParam;
    }
    if (this.args.routeParam && this.router.currentRoute && this.router.currentRoute.params.filter) {
      return this.router.currentRoute.params.filter === this.args.routeParam;
    }
    return this.router.isActive(this.args.route);
  }
  static #_2 = setComponentTemplate(createTemplateFactory(
  /*
    
      <li class={{concatClass (if this.active "active") @class}} ...attributes>
        {{#if @routeParam}}
          <LinkTo
            @route={{@route}}
            @model={{@routeParam}}
            @current-when={{this.active}}
          >{{this.contents}}</LinkTo>
        {{else if @route}}
          <LinkTo @route={{@route}}>{{this.contents}}</LinkTo>
        {{else}}
          <a href={{getURL @path}} data-auto-route="true">{{this.contents}}</a>
        {{/if}}
      </li>
    
  */
  {
    "id": "Ady/Tm2D",
    "block": "[[[1,\"\\n    \"],[11,\"li\"],[16,0,[28,[32,0],[[52,[30,0,[\"active\"]],\"active\"],[30,1]],null]],[17,2],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"        \"],[8,[32,1],null,[[\"@route\",\"@model\",\"@current-when\"],[[30,4],[30,3],[30,0,[\"active\"]]]],[[\"default\"],[[[[1,[30,0,[\"contents\"]]]],[]]]]],[1,\"\\n\"]],[]],[[[41,[30,4],[[[1,\"        \"],[8,[32,1],null,[[\"@route\"],[[30,4]]],[[\"default\"],[[[[1,[30,0,[\"contents\"]]]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,3],[15,6,[28,[32,2],[[30,5]],null]],[14,\"data-auto-route\",\"true\"],[12],[1,[30,0,[\"contents\"]]],[13],[1,\"\\n      \"]],[]]]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@class\",\"&attrs\",\"@routeParam\",\"@route\",\"@path\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/nav-item.js",
    "scope": () => [concatClass, LinkTo, getURL],
    "isStrictMode": true
  }), this);
}