import { getOwner } from "@ember/application";
import { service } from "@ember/service";
import DiscourseRoute from "discourse/routes/discourse";
export default class UserIndex extends DiscourseRoute {
  static #_ = dt7948.g(this.prototype, "router", [service]);
  #router = (dt7948.i(this, "router"), void 0);
  static #_2 = dt7948.g(this.prototype, "site", [service]);
  #site = (dt7948.i(this, "site"), void 0);
  static #_3 = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_4 = dt7948.g(this.prototype, "siteSettings", [service]);
  #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
  get viewingOtherUserDefaultRoute() {
    let viewUserRoute = this.siteSettings.view_user_route;
    if (viewUserRoute === "activity") {
      viewUserRoute = "userActivity";
    } else {
      viewUserRoute = `user.${viewUserRoute}`;
    }
    if (getOwner(this).lookup(`route:${viewUserRoute}`)) {
      return viewUserRoute;
    } else {
      // eslint-disable-next-line no-console
      console.error(`Invalid value for view_user_route '${viewUserRoute}'. Falling back to 'summary'.`);
      return "user.summary";
    }
  }
  beforeModel() {
    const viewingMe = this.currentUser?.username === this.modelFor("user").username;
    let destination;
    if (viewingMe) {
      destination = "userActivity";
    } else {
      destination = this.viewingOtherUserDefaultRoute;
    }
    this.router.transitionTo(destination);
  }
}