import Component from "@glimmer/component";
import { service } from "@ember/service";
import { eq, not, or } from "truth-helpers";
import DAG from "discourse/lib/dag";
import getURL from "discourse-common/lib/get-url";
import Dropdown from "./dropdown";
import UserDropdown from "./user-dropdown";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let headerIcons;
resetHeaderIcons();
function resetHeaderIcons() {
  headerIcons = new DAG({
    defaultPosition: {
      before: "search"
    }
  });
  headerIcons.add("search");
  headerIcons.add("hamburger", undefined, {
    after: "search"
  });
  headerIcons.add("user-menu", undefined, {
    after: "hamburger"
  });
}
export function headerIconsDAG() {
  return headerIcons;
}
export function clearExtraHeaderIcons() {
  resetHeaderIcons();
}
export default class Icons extends Component {
  static #_ = dt7948.g(this.prototype, "site", [service]);
  #site = (dt7948.i(this, "site"), void 0);
  static #_2 = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_3 = dt7948.g(this.prototype, "header", [service]);
  #header = (dt7948.i(this, "header"), void 0);
  static #_4 = dt7948.g(this.prototype, "search", [service]);
  #search = (dt7948.i(this, "search"), void 0);
  static #_5 = setComponentTemplate(createTemplateFactory(
  /*
    
      <ul class="icons d-header-icons">
        {{#each (headerIcons.resolve) as |entry|}}
          {{#if (eq entry.key "search")}}
            <Dropdown
              @title="search.title"
              @icon="search"
              @iconId={{@searchButtonId}}
              @onClick={{@toggleSearchMenu}}
              @active={{this.search.visible}}
              @href={{getURL "/search"}}
              @className="search-dropdown"
              @targetSelector=".search-menu-panel"
            />
          {{else if (eq entry.key "hamburger")}}
            {{#if (or (not @sidebarEnabled) this.site.mobileView)}}
              <Dropdown
                @title="hamburger_menu"
                @icon="bars"
                @iconId="toggle-hamburger-menu"
                @active={{this.header.hamburgerVisible}}
                @onClick={{@toggleHamburger}}
                @className="hamburger-dropdown"
              />
            {{/if}}
          {{else if (eq entry.key "user-menu")}}
            {{#if this.currentUser}}
              <UserDropdown
                @active={{this.header.userVisible}}
                @toggleUserMenu={{@toggleUserMenu}}
              />
            {{/if}}
          {{else if entry.value}}
            <entry.value />
          {{/if}}
        {{/each}}
      </ul>
    
  */
  {
    "id": "5c17ga1L",
    "block": "[[[1,\"\\n    \"],[10,\"ul\"],[14,0,\"icons d-header-icons\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[28,[32,0,[\"resolve\"]],null,null]],null]],null],null,[[[41,[28,[32,1],[[30,1,[\"key\"]],\"search\"],null],[[[1,\"          \"],[8,[32,2],null,[[\"@title\",\"@icon\",\"@iconId\",\"@onClick\",\"@active\",\"@href\",\"@className\",\"@targetSelector\"],[\"search.title\",\"search\",[30,2],[30,3],[30,0,[\"search\",\"visible\"]],[28,[32,3],[\"/search\"],null],\"search-dropdown\",\".search-menu-panel\"]],null],[1,\"\\n\"]],[]],[[[41,[28,[32,1],[[30,1,[\"key\"]],\"hamburger\"],null],[[[41,[28,[32,4],[[28,[32,5],[[30,4]],null],[30,0,[\"site\",\"mobileView\"]]],null],[[[1,\"            \"],[8,[32,2],null,[[\"@title\",\"@icon\",\"@iconId\",\"@active\",\"@onClick\",\"@className\"],[\"hamburger_menu\",\"bars\",\"toggle-hamburger-menu\",[30,0,[\"header\",\"hamburgerVisible\"]],[30,5],\"hamburger-dropdown\"]],null],[1,\"\\n\"]],[]],null]],[]],[[[41,[28,[32,1],[[30,1,[\"key\"]],\"user-menu\"],null],[[[41,[30,0,[\"currentUser\"]],[[[1,\"            \"],[8,[32,6],null,[[\"@active\",\"@toggleUserMenu\"],[[30,0,[\"header\",\"userVisible\"]],[30,6]]],null],[1,\"\\n\"]],[]],null]],[]],[[[41,[30,1,[\"value\"]],[[[1,\"          \"],[8,[30,1,[\"value\"]],null,null,null],[1,\"\\n        \"]],[]],null]],[]]]],[]]]],[]]]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"entry\",\"@searchButtonId\",\"@toggleSearchMenu\",\"@sidebarEnabled\",\"@toggleHamburger\",\"@toggleUserMenu\"],false,[\"each\",\"-track-array\",\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/icons.js",
    "scope": () => [headerIcons, eq, Dropdown, getURL, or, not, UserDropdown],
    "isStrictMode": true
  }), this);
}