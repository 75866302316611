import { tracked } from "@glimmer/tracking";
import Service from "@ember/service";
export default class AdminPostMenuButtons extends Service {
  static #_ = dt7948.g(this.prototype, "callbacks", [tracked], function () {
    return [];
  });
  #callbacks = (dt7948.i(this, "callbacks"), void 0);
  addButton(callback) {
    this.callbacks.push(callback);
  }
}