import Component from "@glimmer/component";
import { inject as controller } from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import GroupCardContents from "discourse/components/group-card-contents";
import UserCardContents from "discourse/components/user-card-contents";
import routeAction from "discourse/helpers/route-action";
import DiscourseURL, { groupPath, userPath } from "discourse/lib/url";
import PluginOutlet from "./plugin-outlet";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class CardContainer extends Component {
  static #_ = dt7948.g(this.prototype, "site", [service]);
  #site = (dt7948.i(this, "site"), void 0);
  static #_2 = dt7948.g(this.prototype, "topic", [controller]);
  #topic = (dt7948.i(this, "topic"), void 0);
  filterPosts(user1) {
    this.topic.send("filterParticipant", user1);
  }
  static #_3 = dt7948.n(this.prototype, "filterPosts", [action]);
  showUser(user1) {
    DiscourseURL.routeTo(userPath(user1.username_lower));
  }
  static #_4 = dt7948.n(this.prototype, "showUser", [action]);
  showGroup(group1) {
    DiscourseURL.routeTo(groupPath(group1.name));
  }
  static #_5 = dt7948.n(this.prototype, "showGroup", [action]);
  static #_6 = setComponentTemplate(createTemplateFactory(
  /*
    
      {{#if this.site.mobileView}}
        <div class="card-cloak hidden"></div>
      {{/if}}
  
      <PluginOutlet @name="user-card-content-container">
        <UserCardContents
          @topic={{this.topic.model}}
          @showUser={{this.showUser}}
          @filterPosts={{this.filterPosts}}
          @composePrivateMessage={{routeAction "composePrivateMessage"}}
          role="dialog"
        />
      </PluginOutlet>
  
      <GroupCardContents
        @topic={{this.topic.model}}
        @showUser={{this.showUser}}
        @showGroup={{this.showGroup}}
      />
    
  */
  {
    "id": "LJSOFGKD",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"      \"],[10,0],[14,0,\"card-cloak hidden\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[8,[32,0],null,[[\"@name\"],[\"user-card-content-container\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[32,1],[[24,\"role\",\"dialog\"]],[[\"@topic\",\"@showUser\",\"@filterPosts\",\"@composePrivateMessage\"],[[30,0,[\"topic\",\"model\"]],[30,0,[\"showUser\"]],[30,0,[\"filterPosts\"]],[28,[32,2],[\"composePrivateMessage\"],null]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[32,3],null,[[\"@topic\",\"@showUser\",\"@showGroup\"],[[30,0,[\"topic\",\"model\"]],[30,0,[\"showUser\"]],[30,0,[\"showGroup\"]]]],null],[1,\"\\n  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/card-container.js",
    "scope": () => [PluginOutlet, UserCardContents, routeAction, GroupCardContents],
    "isStrictMode": true
  }), this);
}