/* import __COLOCATED_TEMPLATE__ from './tags-section.hbs'; */
import Component from "@glimmer/component";
import "@glimmer/tracking";
let cached = esc(require('@glimmer/tracking')).cached;
import { service } from "@ember/service";
import TagSectionLink from "discourse/lib/sidebar/user/tags-section/tag-section-link";
import TEMPLATE from "./tags-section.hbs";
import esc from "../../../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";
import { setComponentTemplate } from "@ember/component";
export default class SidebarAnonymousTagsSection extends Component {
  static #_ = dt7948.g(this.prototype, "router", [service]);
  #router = (dt7948.i(this, "router"), void 0);
  static #_2 = dt7948.g(this.prototype, "topicTrackingState", [service]);
  #topicTrackingState = (dt7948.i(this, "topicTrackingState"), void 0);
  static #_3 = dt7948.g(this.prototype, "site", [service]);
  #site = (dt7948.i(this, "site"), void 0);
  get displaySection() {
    return this.site.anonymous_default_navigation_menu_tags?.length > 0 || this.site.navigation_menu_site_top_tags?.length > 0;
  }
  get sectionLinks() {
    return (this.site.anonymous_default_navigation_menu_tags || this.site.navigation_menu_site_top_tags).map(tag => {
      return new TagSectionLink({
        tag,
        topicTrackingState: this.topicTrackingState
      });
    });
  }
  static #_4 = dt7948.n(this.prototype, "sectionLinks", [cached]);
}
setComponentTemplate(TEMPLATE, SidebarAnonymousTagsSection);