import { dasherize, underscore } from "@ember/string";
import { Promise } from "rsvp";
import { ajax } from "discourse/lib/ajax";
import RestModel from "discourse/models/rest";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import Category from "./category";
export const PENDING = 0;
export const APPROVED = 1;
export const REJECTED = 2;
export const IGNORED = 3;
export const DELETED = 4;
export default class Reviewable extends RestModel {
  static munge(json) {
    // ensure we are not overriding category computed property
    delete json.category;
    return json;
  }
  resolvedType(type, topic) {
    // Display "Queued Topic" if the post will create a topic
    if (type === "ReviewableQueuedPost" && !topic) {
      return "ReviewableQueuedTopic";
    }
    return type;
  }
  static #_ = dt7948.n(this.prototype, "resolvedType", [discourseComputed("type", "topic")]);
  humanType(resolvedType) {
    return I18n.t(`review.types.${underscore(resolvedType)}.title`, {
      defaultValue: ""
    });
  }
  static #_2 = dt7948.n(this.prototype, "humanType", [discourseComputed("resolvedType")]);
  humanTypeCssClass(humanType) {
    return "-" + dasherize(humanType);
  }
  static #_3 = dt7948.n(this.prototype, "humanTypeCssClass", [discourseComputed("humanType")]);
  humanNoun(resolvedType) {
    return I18n.t(`review.types.${underscore(resolvedType)}.noun`, {
      defaultValue: "reviewable"
    });
  }
  static #_4 = dt7948.n(this.prototype, "humanNoun", [discourseComputed("resolvedType")]);
  flaggedReviewableContextQuestion(humanNoun) {
    const uniqueReviewableScores = this.reviewable_scores.uniqBy("score_type.type");
    if (uniqueReviewableScores.length === 1) {
      if (uniqueReviewableScores[0].score_type.type === "notify_moderators") {
        return I18n.t("review.context_question.something_else_wrong", {
          reviewable_type: humanNoun
        });
      }
    }
    const listOfQuestions = I18n.listJoiner(uniqueReviewableScores.map(score => score.score_type.title.toLowerCase()).uniq(), I18n.t("review.context_question.delimiter"));
    return I18n.t("review.context_question.is_this_post", {
      reviewable_human_score_types: listOfQuestions,
      reviewable_type: humanNoun
    });
  }
  static #_5 = dt7948.n(this.prototype, "flaggedReviewableContextQuestion", [discourseComputed("humanNoun")]);
  category() {
    return Category.findById(this.category_id);
  }
  static #_6 = dt7948.n(this.prototype, "category", [discourseComputed("category_id")]);
  update(updates) {
    // If no changes, do nothing
    if (Object.keys(updates).length === 0) {
      return Promise.resolve();
    }
    let adapter = this.store.adapterFor("reviewable");
    return ajax(`/review/${this.id}?version=${this.version}`, adapter.getPayload("PUT", {
      reviewable: updates
    })).then(updated => {
      updated.payload = Object.assign({}, this.payload || {}, updated.payload || {});
      this.setProperties(updated);
    });
  }
}