/* import __COLOCATED_TEMPLATE__ from './tags-modal.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { popupAjaxError } from "discourse/lib/ajax-error";
import { INPUT_DELAY } from "discourse-common/config/environment";
import discourseDebounce from "discourse-common/lib/debounce";
import TEMPLATE from "./tags-modal.hbs";
import { setComponentTemplate } from "@ember/component";
export default setComponentTemplate(TEMPLATE, class extends Component {
  static #_ = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_2 = dt7948.g(this.prototype, "siteSettings", [service]);
  #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
  static #_3 = dt7948.g(this.prototype, "store", [service]);
  #store = (dt7948.i(this, "store"), void 0);
  static #_4 = dt7948.g(this.prototype, "filter", [tracked], function () {
    return "";
  });
  #filter = (dt7948.i(this, "filter"), void 0);
  static #_5 = dt7948.g(this.prototype, "onlySelected", [tracked], function () {
    return false;
  });
  #onlySelected = (dt7948.i(this, "onlySelected"), void 0);
  static #_6 = dt7948.g(this.prototype, "onlyUnSelected", [tracked], function () {
    return false;
  });
  #onlyUnSelected = (dt7948.i(this, "onlyUnSelected"), void 0);
  static #_7 = dt7948.g(this.prototype, "tags", [tracked], function () {
    return [];
  });
  #tags = (dt7948.i(this, "tags"), void 0);
  static #_8 = dt7948.g(this.prototype, "tagsLoading", [tracked]);
  #tagsLoading = (dt7948.i(this, "tagsLoading"), void 0);
  static #_9 = dt7948.g(this.prototype, "disableFiltering", [tracked]);
  #disableFiltering = (dt7948.i(this, "disableFiltering"), void 0);
  static #_10 = dt7948.g(this.prototype, "selectedTags", [tracked], function () {
    return [...this.currentUser.sidebarTagNames];
  });
  #selectedTags = (dt7948.i(this, "selectedTags"), void 0);
  constructor() {
    super(...arguments);
    this.#loadTags();
  }
  async #loadTags() {
    this.tagsLoading = true;
    const findArgs = {};
    if (this.filter !== "") {
      findArgs.filter = this.filter;
    }
    if (this.onlySelected) {
      findArgs.only_tags = this.selectedTags.join(",");
    }
    if (this.onlyUnselected) {
      findArgs.exclude_tags = this.selectedTags.join(",");
    }
    await this.store.findAll("listTag", findArgs).then(tags => {
      this.tags = tags;
    }).catch(error => {
      popupAjaxError(error);
    }).finally(() => {
      this.tagsLoading = false;
      this.disableFiltering = false;
    });
  }
  didInsertTag(element) {
    const tagName = element.dataset.tagName;
    const lastTagName = this.tags.content[this.tags.content.length - 1].name;
    if (tagName === lastTagName) {
      if (this.observer) {
        this.observer.disconnect();
      } else {
        const root = document.querySelector(".d-modal__body");
        const style = window.getComputedStyle(root);
        const marginTop = parseFloat(style.marginTop);
        const paddingTop = parseFloat(style.paddingTop);
        this.observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              this.tags.loadMore();
            }
          });
        }, {
          root: document.querySelector(".d-modal__body"),
          rootMargin: `0px 0px ${marginTop + paddingTop}px 0px`,
          threshold: 1.0
        });
      }
      this.observer.observe(element);
    }
  }
  static #_11 = dt7948.n(this.prototype, "didInsertTag", [action]);
  resetFilter() {
    this.onlySelected = false;
    this.onlyUnselected = false;
    this.#loadTags();
  }
  static #_12 = dt7948.n(this.prototype, "resetFilter", [action]);
  filterSelected() {
    this.onlySelected = true;
    this.onlyUnselected = false;
    this.#loadTags();
  }
  static #_13 = dt7948.n(this.prototype, "filterSelected", [action]);
  filterUnselected() {
    this.onlySelected = false;
    this.onlyUnselected = true;
    this.#loadTags();
  }
  static #_14 = dt7948.n(this.prototype, "filterUnselected", [action]);
  onFilterInput(filter) {
    this.disableFiltering = true;
    discourseDebounce(this, this.#performFiltering, filter, INPUT_DELAY);
  }
  static #_15 = dt7948.n(this.prototype, "onFilterInput", [action]);
  #performFiltering(filter) {
    this.filter = filter.toLowerCase();
    this.#loadTags();
  }
  deselectAll() {
    this.selectedTags.clear();
  }
  static #_16 = dt7948.n(this.prototype, "deselectAll", [action]);
  resetToDefaults() {
    this.selectedTags = this.siteSettings.default_navigation_menu_tags.split("|");
  }
  static #_17 = dt7948.n(this.prototype, "resetToDefaults", [action]);
  toggleTag(tag) {
    if (this.selectedTags.includes(tag)) {
      this.selectedTags.removeObject(tag);
    } else {
      this.selectedTags.addObject(tag);
    }
  }
  static #_18 = dt7948.n(this.prototype, "toggleTag", [action]);
  save() {
    this.saving = true;
    const initialSidebarTags = this.currentUser.sidebar_tags;
    this.currentUser.set("sidebar_tag_names", this.selectedTags);
    this.currentUser.save(["sidebar_tag_names"]).then(result => {
      this.currentUser.set("sidebar_tags", result.user.sidebar_tags);
      this.args.closeModal();
    }).catch(error => {
      this.currentUser.set("sidebar_tags", initialSidebarTags);
      popupAjaxError(error);
    }).finally(() => {
      this.saving = false;
    });
  }
  static #_19 = dt7948.n(this.prototype, "save", [action]);
});