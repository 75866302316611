/* import __COLOCATED_TEMPLATE__ from './security-key-form.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { SECOND_FACTOR_METHODS } from "discourse/models/user";
import TEMPLATE from "./security-key-form.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  useAnotherMethod(event) {
    event?.preventDefault();
    this.set("showSecurityKey", false);
    this.set("showSecondFactor", true);
    this.set("secondFactorMethod", SECOND_FACTOR_METHODS.TOTP);
  }
}, [["method", "useAnotherMethod", [action]]])));