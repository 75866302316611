/* import __COLOCATED_TEMPLATE__ from './fullscreen-code.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import CodeblockButtons from "discourse/lib/codeblock-buttons";
import highlightSyntax from "discourse/lib/highlight-syntax";
import TEMPLATE from "./fullscreen-code.hbs";
import { setComponentTemplate } from "@ember/component";
export default class FullscreenCode extends Component {
  static #_ = dt7948.g(this.prototype, "siteSettings", [service]);
  #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
  static #_2 = dt7948.g(this.prototype, "session", [service]);
  #session = (dt7948.i(this, "session"), void 0);
  closeModal() {
    this.codeBlockButtons.cleanup();
    this.args.closeModal();
  }
  static #_3 = dt7948.n(this.prototype, "closeModal", [action]);
  applyCodeblockButtons(element) {
    const modalElement = element.querySelector(".d-modal__body");
    highlightSyntax(modalElement, this.siteSettings, this.session);
    this.codeBlockButtons = new CodeblockButtons({
      showFullscreen: false,
      showCopy: true
    });
    this.codeBlockButtons.attachToGeneric(modalElement);
  }
  static #_4 = dt7948.n(this.prototype, "applyCodeblockButtons", [action]);
}
setComponentTemplate(TEMPLATE, FullscreenCode);