/* import __COLOCATED_TEMPLATE__ from './reviewable-flagged-post.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { bind } from "discourse-common/utils/decorators";
import TEMPLATE from "./reviewable-flagged-post.hbs";
import { setComponentTemplate } from "@ember/component";
export default class ReviewableFlaggedPost extends Component {
  static #_ = dt7948.g(this.prototype, "isCollapsed", [tracked], function () {
    return false;
  });
  #isCollapsed = (dt7948.i(this, "isCollapsed"), void 0);
  static #_2 = dt7948.g(this.prototype, "isLongPost", [tracked], function () {
    return false;
  });
  #isLongPost = (dt7948.i(this, "isLongPost"), void 0);
  maxPostHeight = 300;
  toggleContent() {
    this.isCollapsed = !this.isCollapsed;
  }
  static #_3 = dt7948.n(this.prototype, "toggleContent", [action]);
  calculatePostBodySize(element) {
    if (element?.offsetHeight > this.maxPostHeight) {
      this.isCollapsed = true;
      this.isLongPost = true;
    } else {
      this.isCollapsed = false;
      this.isLongPost = false;
    }
  }
  static #_4 = dt7948.n(this.prototype, "calculatePostBodySize", [bind]);
  get collapseButtonProps() {
    if (this.isCollapsed) {
      return {
        label: "review.show_more",
        icon: "chevron-down"
      };
    }
    return {
      label: "review.show_less",
      icon: "chevron-up"
    };
  }
}
setComponentTemplate(TEMPLATE, ReviewableFlaggedPost);