import { tracked } from "@glimmer/tracking";
import { getOwner, setOwner } from "@ember/application";
import { service } from "@ember/service";
import { TrackedArray } from "@ember-compat/tracked-built-ins";
import { NotificationLevels } from "discourse/lib/notification-levels";
import Topic from "discourse/models/topic";
import { bind } from "discourse-common/utils/decorators";
export default class BulkSelectHelper {
  static #_ = dt7948.g(this.prototype, "router", [service]);
  #router = (dt7948.i(this, "router"), void 0);
  static #_2 = dt7948.g(this.prototype, "modal", [service]);
  #modal = (dt7948.i(this, "modal"), void 0);
  static #_3 = dt7948.g(this.prototype, "pmTopicTrackingState", [service]);
  #pmTopicTrackingState = (dt7948.i(this, "pmTopicTrackingState"), void 0);
  static #_4 = dt7948.g(this.prototype, "topicTrackingState", [service]);
  #topicTrackingState = (dt7948.i(this, "topicTrackingState"), void 0);
  static #_5 = dt7948.g(this.prototype, "bulkSelectEnabled", [tracked], function () {
    return false;
  });
  #bulkSelectEnabled = (dt7948.i(this, "bulkSelectEnabled"), void 0);
  static #_6 = dt7948.g(this.prototype, "autoAddTopicsToBulkSelect", [tracked], function () {
    return false;
  });
  #autoAddTopicsToBulkSelect = (dt7948.i(this, "autoAddTopicsToBulkSelect"), void 0);
  selected = new TrackedArray();
  constructor(context) {
    setOwner(this, getOwner(context));
  }
  clear() {
    this.selected.length = 0;
  }
  addTopics(topics) {
    this.selected.concat(topics);
  }
  get selectedCategoryIds() {
    return this.selected.mapBy("category_id").uniq();
  }
  toggleBulkSelect() {
    this.bulkSelectEnabled = !this.bulkSelectEnabled;
    this.clear();
  }
  static #_7 = dt7948.n(this.prototype, "toggleBulkSelect", [bind]);
  dismissRead(operationType, options) {
    const operation = operationType === "posts" ? {
      type: "dismiss_posts"
    } : {
      type: "change_notification_level",
      notification_level_id: NotificationLevels.REGULAR
    };
    const isTracked = (this.router.currentRoute.queryParams["f"] || this.router.currentRoute.queryParams["filter"]) === "tracked";
    const promise = this.selected.length ? Topic.bulkOperation(this.selected, operation, {}, isTracked) : Topic.bulkOperationByFilter("unread", operation, options, isTracked);
    promise.then(result => {
      if (result?.topic_ids) {
        if (options?.private_message_inbox) {
          this.pmTopicTrackingState.removeTopics(result.topic_ids);
        } else {
          this.topicTrackingState.removeTopics(result.topic_ids);
        }
      }
      this.modal.close();
      this.router.refresh();
    });
  }
}