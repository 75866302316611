import { tracked } from "@glimmer/tracking";
export default class SectionLink {
  static #_ = dt7948.g(this.prototype, "linkDragCss", [tracked]);
  #linkDragCss = (dt7948.i(this, "linkDragCss"), void 0);
  constructor(_ref, section) {
    let {
      external,
      icon,
      id,
      name,
      value
    } = _ref;
    this.external = external;
    this.prefixValue = icon;
    this.id = id;
    this.name = name;
    this.text = name;
    this.value = value;
    this.section = section;
    this.withAnchor = value.match(/#\w+$/gi);
  }
  get shouldDisplay() {
    return true;
  }
}