import Component from "@glimmer/component";
import { hash } from "@ember/helper";
import { service } from "@ember/service";
import { and } from "truth-helpers";
import BootstrapModeNotice from "../bootstrap-mode-notice";
import PluginOutlet from "../plugin-outlet";
import HomeLogo from "./home-logo";
import SidebarToggle from "./sidebar-toggle";
import TopicInfo from "./topic/info";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class Contents extends Component {
  static #_ = dt7948.g(this.prototype, "site", [service]);
  #site = (dt7948.i(this, "site"), void 0);
  static #_2 = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_3 = dt7948.g(this.prototype, "siteSettings", [service]);
  #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
  static #_4 = dt7948.g(this.prototype, "header", [service]);
  #header = (dt7948.i(this, "header"), void 0);
  get topicPresent() {
    return !!this.header.topic;
  }
  static #_5 = setComponentTemplate(createTemplateFactory(
  /*
    
      <div class="contents">
        {{#if this.site.desktopView}}
          {{#if @sidebarEnabled}}
            <SidebarToggle
              @toggleHamburger={{@toggleHamburger}}
              @showSidebar={{@showSidebar}}
            />
          {{/if}}
        {{/if}}
  
        <div class="home-logo-wrapper-outlet">
          <PluginOutlet @name="home-logo-wrapper">
            <HomeLogo @minimized={{this.topicPresent}} />
          </PluginOutlet>
        </div>
  
        {{#if this.header.topic}}
          <TopicInfo @topic={{this.header.topic}} />
        {{else if
          (and
            this.siteSettings.bootstrap_mode_enabled
            this.currentUser.staff
            this.site.desktopView
          )
        }}
          <div class="d-header-mode">
            <BootstrapModeNotice />
          </div>
        {{/if}}
  
        <div class="before-header-panel-outlet">
          <PluginOutlet
            @name="before-header-panel"
            @outletArgs={{hash topic=this.header.topic}}
          />
        </div>
        <div class="panel" role="navigation">{{yield}}</div>
        <div class="after-header-panel-outlet">
          <PluginOutlet
            @name="after-header-panel"
            @outletArgs={{hash topic=this.header.topic}}
          />
        </div>
      </div>
    
  */
  {
    "id": "K9HvZDqM",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"contents\"],[12],[1,\"\\n\"],[41,[30,0,[\"site\",\"desktopView\"]],[[[41,[30,1],[[[1,\"          \"],[8,[32,0],null,[[\"@toggleHamburger\",\"@showSidebar\"],[[30,2],[30,3]]],null],[1,\"\\n\"]],[]],null]],[]],null],[1,\"\\n      \"],[10,0],[14,0,\"home-logo-wrapper-outlet\"],[12],[1,\"\\n        \"],[8,[32,1],null,[[\"@name\"],[\"home-logo-wrapper\"]],[[\"default\"],[[[[1,\"\\n          \"],[8,[32,2],null,[[\"@minimized\"],[[30,0,[\"topicPresent\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"header\",\"topic\"]],[[[1,\"        \"],[8,[32,3],null,[[\"@topic\"],[[30,0,[\"header\",\"topic\"]]]],null],[1,\"\\n\"]],[]],[[[41,[28,[32,4],[[30,0,[\"siteSettings\",\"bootstrap_mode_enabled\"]],[30,0,[\"currentUser\",\"staff\"]],[30,0,[\"site\",\"desktopView\"]]],null],[[[1,\"        \"],[10,0],[14,0,\"d-header-mode\"],[12],[1,\"\\n          \"],[8,[32,5],null,null,null],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]],null]],[]]],[1,\"\\n      \"],[10,0],[14,0,\"before-header-panel-outlet\"],[12],[1,\"\\n        \"],[8,[32,1],null,[[\"@name\",\"@outletArgs\"],[\"before-header-panel\",[28,[32,6],null,[[\"topic\"],[[30,0,[\"header\",\"topic\"]]]]]]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"panel\"],[14,\"role\",\"navigation\"],[12],[18,4,null],[13],[1,\"\\n      \"],[10,0],[14,0,\"after-header-panel-outlet\"],[12],[1,\"\\n        \"],[8,[32,1],null,[[\"@name\",\"@outletArgs\"],[\"after-header-panel\",[28,[32,6],null,[[\"topic\"],[[30,0,[\"header\",\"topic\"]]]]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@sidebarEnabled\",\"@toggleHamburger\",\"@showSidebar\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/contents.js",
    "scope": () => [SidebarToggle, PluginOutlet, HomeLogo, TopicInfo, and, BootstrapModeNotice, hash],
    "isStrictMode": true
  }), this);
}