import { tracked } from "@glimmer/tracking";
import { setOwner } from "@ember/application";
import { inject as controller } from "@ember/controller";
import { inject as service } from "@ember/service";
import { CLOSE_INITIATED_BY_BUTTON, CLOSE_INITIATED_BY_ESC } from "discourse/components/d-modal";
import { BookmarkFormData } from "discourse/lib/bookmark-form-data";
import Bookmark from "discourse/models/bookmark";
export default class PostBookmarkManager {
  static #_ = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_2 = dt7948.g(this.prototype, "bookmarkApi", [service]);
  #bookmarkApi = (dt7948.i(this, "bookmarkApi"), void 0);
  static #_3 = dt7948.g(this.prototype, "topicController", [controller("topic")]);
  #topicController = (dt7948.i(this, "topicController"), void 0);
  static #_4 = dt7948.g(this.prototype, "trackedBookmark", [tracked]);
  #trackedBookmark = (dt7948.i(this, "trackedBookmark"), void 0);
  static #_5 = dt7948.g(this.prototype, "bookmarkModel", [tracked]);
  #bookmarkModel = (dt7948.i(this, "bookmarkModel"), void 0);
  constructor(owner, post) {
    setOwner(this, owner);
    this.model = post;
    this.type = "Post";
    this.bookmarkModel = this.topicController.model?.bookmarks.find(bookmark => bookmark.bookmarkable_id === this.model.id && bookmark.bookmarkable_type === this.type) || this.bookmarkApi.buildNewBookmark(this.type, this.model.id);
    this.trackedBookmark = new BookmarkFormData(this.bookmarkModel);
  }
  create() {
    return this.bookmarkApi.create(this.trackedBookmark).then(updatedBookmark => {
      this.trackedBookmark = updatedBookmark;
    });
  }
  delete() {
    return this.bookmarkApi.delete(this.trackedBookmark.id);
  }
  save() {
    return this.bookmarkApi.update(this.trackedBookmark);
  }
  afterModalClose(closeData) {
    if (!closeData) {
      return;
    }
    if (closeData.closeWithoutSaving || closeData.initiatedBy === CLOSE_INITIATED_BY_ESC || closeData.initiatedBy === CLOSE_INITIATED_BY_BUTTON) {
      this.model.appEvents.trigger("post-stream:refresh", {
        id: this.model.id
      });
    }
  }
  afterSave(bookmarkFormData) {
    this.trackedBookmark = bookmarkFormData;
    this._syncBookmarks(bookmarkFormData.saveData);
    this.topicController.model.set("bookmarking", false);
    this.model.createBookmark(bookmarkFormData.saveData);
    this.topicController.model.afterPostBookmarked(this.model, bookmarkFormData.saveData);
    return [this.model.id];
  }
  afterDelete(deleteResponse, bookmarkId) {
    this.topicController.model.removeBookmark(bookmarkId);
    this.model.deleteBookmark(deleteResponse.topic_bookmarked);
    this.bookmarkModel = this.bookmarkApi.buildNewBookmark(this.type, this.model.id);
    this.trackedBookmark = new BookmarkFormData(this.bookmarkModel);
  }
  _syncBookmarks(data) {
    if (!this.topicController.bookmarks) {
      this.topicController.set("bookmarks", []);
    }
    const bookmark = this.topicController.bookmarks.findBy("id", data.id);
    if (!bookmark) {
      this.topicController.bookmarks.pushObject(Bookmark.create(data));
    } else {
      bookmark.reminder_at = data.reminder_at;
      bookmark.name = data.name;
      bookmark.auto_delete_preference = data.auto_delete_preference;
    }
  }
}