import { computed } from "@ember/object";
import I18n from "discourse-i18n";
export default class TrustLevel {
  constructor(id, key) {
    this.id = id;
    this._key = key;
  }
  get name() {
    return I18n.t(`trust_levels.names.${this._key}`);
  }
  static #_ = dt7948.n(this.prototype, "name", [computed]);
  get detailedName() {
    return I18n.t("trust_levels.detailed_name", {
      level: this.id,
      name: this.name
    });
  }
  static #_2 = dt7948.n(this.prototype, "detailedName", [computed]);
}