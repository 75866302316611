import { registerDestructor } from "@ember/destroyable";
import { schedule } from "@ember/runloop";
import { service } from "@ember/service";
import Modifier from "ember-modifier";
import { addWidgetCleanCallback, removeWidgetCleanCallback } from "discourse/components/mount-widget";
import { headerOffset } from "discourse/lib/offset-calculator";
import { bind } from "discourse-common/utils/decorators";
const STICKY_CLASS = "sticky-avatar";
const TOPIC_POST_SELECTOR = ".post-stream .topic-post";
export default class StickyAvatars extends Modifier {
  static #_ = dt7948.g(this.prototype, "site", [service]);
  #site = (dt7948.i(this, "site"), void 0);
  static #_2 = dt7948.g(this.prototype, "appEvents", [service]);
  #appEvents = (dt7948.i(this, "appEvents"), void 0);
  element;
  intersectionObserver;
  direction = "⬇️";
  prevOffset = -1;
  constructor() {
    super(...arguments);
    registerDestructor(this, instance => instance.cleanup());
  }
  modify(element) {
    if (this.site.mobileView || !("IntersectionObserver" in window)) {
      return;
    }
    this.element = element;
    this.appEvents.on("topic:current-post-scrolled", this, this._handlePostNodes);
    this.appEvents.on("topic:scrolled", this, this._handleScroll);
    this.appEvents.on("page:topic-loaded", this, this._initIntersectionObserver);
    addWidgetCleanCallback("post-stream", this._clearIntersectionObserver);
  }
  cleanup() {
    if (this.site.mobileView || !("IntersectionObserver" in window)) {
      return;
    }
    this.appEvents.off("topic:current-post-scrolled", this, this._handlePostNodes);
    this.appEvents.off("topic:scrolled", this, this._handleScroll);
    this.appEvents.off("page:topic-loaded", this, this._initIntersectionObserver);
    removeWidgetCleanCallback("post-stream", this._clearIntersectionObserver);
  }
  _handleScroll(offset) {
    if (offset <= 0) {
      this.direction = "⬇️";
      this.element.querySelectorAll(`${TOPIC_POST_SELECTOR}.${STICKY_CLASS}`).forEach(node => node.classList.remove(STICKY_CLASS));
    } else if (offset > this.prevOffset) {
      this.direction = "⬇️";
    } else {
      this.direction = "⬆️";
    }
    this.prevOffset = offset;
  }
  static #_3 = dt7948.n(this.prototype, "_handleScroll", [bind]);
  _handlePostNodes() {
    this._clearIntersectionObserver();
    this._initIntersectionObserver();
    schedule("afterRender", () => {
      this.element.querySelectorAll(TOPIC_POST_SELECTOR).forEach(postNode => {
        this.intersectionObserver.observe(postNode);
        const topicAvatarNode = postNode.querySelector(".topic-avatar");
        if (!topicAvatarNode || !postNode.querySelector("#post_1")) {
          return;
        }
        const topicMapNode = postNode.querySelector(".topic-map");
        if (!topicMapNode) {
          return;
        }
        topicAvatarNode.style.marginBottom = `${topicMapNode.clientHeight}px`;
      });
    });
  }
  static #_4 = dt7948.n(this.prototype, "_handlePostNodes", [bind]);
  _initIntersectionObserver() {
    schedule("afterRender", () => {
      const offset = headerOffset();
      const headerOffsetInPx = offset <= 0 ? "0px" : `-${offset}px`;
      this.intersectionObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (!entry.isIntersecting || entry.intersectionRatio === 1) {
            entry.target.classList.remove(STICKY_CLASS);
            return;
          }
          const postContentHeight = entry.target.querySelector(".contents")?.clientHeight;
          if (this.direction === "⬆️" || postContentHeight > window.innerHeight - offset) {
            entry.target.classList.add(STICKY_CLASS);
          }
        });
      }, {
        threshold: [0.0, 1.0],
        rootMargin: `${headerOffsetInPx} 0px 0px 0px`
      });
    });
  }
  static #_5 = dt7948.n(this.prototype, "_initIntersectionObserver", [bind]);
  _clearIntersectionObserver() {
    this.intersectionObserver?.disconnect();
    this.intersectionObserver = null;
  }
  static #_6 = dt7948.n(this.prototype, "_clearIntersectionObserver", [bind]);
}