/* import __COLOCATED_TEMPLATE__ from './associate-account-confirm.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import TEMPLATE from "./associate-account-confirm.hbs";
import { setComponentTemplate } from "@ember/component";
export default class AssociateAccountConfirm extends Component {
  static #_ = dt7948.g(this.prototype, "router", [service]);
  #router = (dt7948.i(this, "router"), void 0);
  static #_2 = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_3 = dt7948.g(this.prototype, "flash", [tracked]);
  #flash = (dt7948.i(this, "flash"), void 0);
  async finishConnect() {
    try {
      const result = await ajax({
        url: `/associate/${encodeURIComponent(this.args.model.token)}`,
        type: "POST"
      });
      if (result.success) {
        this.router.transitionTo("preferences.account", this.currentUser.findDetails());
        this.args.closeModal();
      } else {
        this.flash = result.error;
      }
    } catch (e) {
      popupAjaxError(e);
    }
  }
  static #_4 = dt7948.n(this.prototype, "finishConnect", [action]);
}
setComponentTemplate(TEMPLATE, AssociateAccountConfirm);