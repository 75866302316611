import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { cancel, next } from "@ember/runloop";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import { eq } from "truth-helpers";
import concatClass from "discourse/helpers/concat-class";
import { bind } from "discourse-common/utils/decorators";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class PageLoadingSlider extends Component {
  static #_ = dt7948.g(this.prototype, "loadingSlider", [service]);
  #loadingSlider = (dt7948.i(this, "loadingSlider"), void 0);
  static #_2 = dt7948.g(this.prototype, "capabilities", [service]);
  #capabilities = (dt7948.i(this, "capabilities"), void 0);
  static #_3 = dt7948.g(this.prototype, "state", [tracked], function () {
    return "ready";
  });
  #state = (dt7948.i(this, "state"), void 0);
  constructor() {
    super(...arguments);
    this.loadingSlider.on("stateChanged", this.stateChanged);
  }
  willDestroy() {
    super.willDestroy(...arguments);
    this.loadingSlider.off("stateChange", this, "stateChange");
  }
  stateChanged(loading1) {
    if (this._deferredStateChange) {
      cancel(this._deferredStateChange);
      this._deferredStateChange = null;
    }
    if (loading1 && this.ready) {
      this.state = "loading";
    } else if (loading1) {
      this.state = "ready";
      this._deferredStateChange = next(() => this.state = "loading");
    } else {
      this.state = "done";
    }
  }
  static #_4 = dt7948.n(this.prototype, "stateChanged", [bind]);
  get containerStyle() {
    const duration1 = this.loadingSlider.averageLoadingDuration.toFixed(2);
    return htmlSafe(`--loading-duration: ${duration1}s`);
  }
  onContainerTransitionEnd(event1) {
    if (event1.target === event1.currentTarget && event1.propertyName === "opacity") {
      this.state = "ready";
    }
  }
  static #_5 = dt7948.n(this.prototype, "onContainerTransitionEnd", [action]);
  onBarTransitionEnd(event1) {
    if (event1.target === event1.currentTarget && event1.propertyName === "transform" && this.state === "loading") {
      this.state = "still-loading";
    }
  }
  static #_6 = dt7948.n(this.prototype, "onBarTransitionEnd", [action]);
  static #_7 = setComponentTemplate(createTemplateFactory(
  /*
    
      {{#if (eq this.loadingSlider.mode "slider")}}
        <div
          {{on "transitionend" this.onContainerTransitionEnd}}
          style={{this.containerStyle}}
          class={{concatClass
            "loading-indicator-container"
            this.state
            (if this.capabilities.isAppWebview "discourse-hub-webview")
          }}
        >
          <div
            {{on "transitionend" this.onBarTransitionEnd}}
            class="loading-indicator"
          >
          </div>
        </div>
      {{/if}}
    
  */
  {
    "id": "FycjnmDY",
    "block": "[[[1,\"\\n\"],[41,[28,[32,0],[[30,0,[\"loadingSlider\",\"mode\"]],\"slider\"],null],[[[1,\"      \"],[11,0],[16,5,[30,0,[\"containerStyle\"]]],[16,0,[28,[32,1],[\"loading-indicator-container\",[30,0,[\"state\"]],[52,[30,0,[\"capabilities\",\"isAppWebview\"]],\"discourse-hub-webview\"]],null]],[4,[32,2],[\"transitionend\",[30,0,[\"onContainerTransitionEnd\"]]],null],[12],[1,\"\\n        \"],[11,0],[24,0,\"loading-indicator\"],[4,[32,2],[\"transitionend\",[30,0,[\"onBarTransitionEnd\"]]],null],[12],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/page-loading-slider.js",
    "scope": () => [eq, concatClass, on],
    "isStrictMode": true
  }), this);
}