import Component from "@glimmer/component";
import { service } from "@ember/service";
import { ADMIN_PANEL } from "discourse/lib/sidebar/panels";
import BackToForum from "./back-to-forum";
import Filter from "./filter";
import ToggleAllSections from "./toggle-all-sections";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class AdminHeader extends Component {
  static #_ = dt7948.g(this.prototype, "sidebarState", [service]);
  #sidebarState = (dt7948.i(this, "sidebarState"), void 0);
  get shouldDisplay() {
    return this.sidebarState.isCurrentPanel(ADMIN_PANEL);
  }
  static #_2 = setComponentTemplate(createTemplateFactory(
  /*
    
      {{#if this.shouldDisplay}}
        <div class="sidebar-admin-header">
          <div class="sidebar-admin-header__row">
            <BackToForum />
            <ToggleAllSections @sections={{@sections}} />
          </div>
          <div class="sidebar-admin-header__row">
            <Filter />
          </div>
        </div>
      {{/if}}
    
  */
  {
    "id": "tZSSO1tf",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldDisplay\"]],[[[1,\"      \"],[10,0],[14,0,\"sidebar-admin-header\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"sidebar-admin-header__row\"],[12],[1,\"\\n          \"],[8,[32,0],null,null,null],[1,\"\\n          \"],[8,[32,1],null,[[\"@sections\"],[[30,1]]],null],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"sidebar-admin-header__row\"],[12],[1,\"\\n          \"],[8,[32,2],null,null,null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@sections\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/admin-header.js",
    "scope": () => [BackToForum, ToggleAllSections, Filter],
    "isStrictMode": true
  }), this);
}