import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { service } from "@ember/service";
import concatClass from "discourse/helpers/concat-class";
import icon from "discourse-common/helpers/d-icon";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class SidebarToggle extends Component {
  static #_ = dt7948.g(this.prototype, "site", [service]);
  #site = (dt7948.i(this, "site"), void 0);
  toggleWithBlur(e1) {
    this.args.toggleHamburger();
    // remove the focus of the header dropdown button after clicking
    e1.target.tagName.toLowerCase() === "button" ? e1.target.blur() : e1.target.closest("button").blur();
  }
  static #_2 = dt7948.n(this.prototype, "toggleWithBlur", [action]);
  static #_3 = setComponentTemplate(createTemplateFactory(
  /*
    
      <span class="header-sidebar-toggle">
        <button
          title={{i18n "sidebar.title"}}
          class={{concatClass
            "btn btn-flat btn-sidebar-toggle no-text btn-icon"
            (if this.site.narrowDesktopView "narrow-desktop")
          }}
          aria-expanded={{if @showSidebar "true" "false"}}
          aria-controls="d-sidebar"
          {{on "click" this.toggleWithBlur}}
        >
          {{icon "bars"}}
        </button>
      </span>
    
  */
  {
    "id": "NUQeGcpB",
    "block": "[[[1,\"\\n    \"],[10,1],[14,0,\"header-sidebar-toggle\"],[12],[1,\"\\n      \"],[11,\"button\"],[16,\"title\",[28,[32,0],[\"sidebar.title\"],null]],[16,0,[28,[32,1],[\"btn btn-flat btn-sidebar-toggle no-text btn-icon\",[52,[30,0,[\"site\",\"narrowDesktopView\"]],\"narrow-desktop\"]],null]],[16,\"aria-expanded\",[52,[30,1],\"true\",\"false\"]],[24,\"aria-controls\",\"d-sidebar\"],[4,[32,2],[\"click\",[30,0,[\"toggleWithBlur\"]]],null],[12],[1,\"\\n        \"],[1,[28,[32,3],[\"bars\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@showSidebar\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/sidebar-toggle.js",
    "scope": () => [i18n, concatClass, on, icon],
    "isStrictMode": true
  }), this);
}