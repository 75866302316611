import { action } from "@ember/object";
import { service } from "@ember/service";
import SidebarSectionForm from "discourse/components/modal/sidebar-section-form";
import CommonCommunitySection from "discourse/lib/sidebar/common/community-section/section";
import I18n from "discourse-i18n";
export default class extends CommonCommunitySection {
  static #_ = dt7948.g(this.prototype, "modal", [service]);
  #modal = (dt7948.i(this, "modal"), void 0);
  static #_2 = dt7948.g(this.prototype, "navigationMenu", [service]);
  #navigationMenu = (dt7948.i(this, "navigationMenu"), void 0);
  moreSectionButtonAction() {
    return this.modal.show(SidebarSectionForm, {
      model: this
    });
  }
  static #_3 = dt7948.n(this.prototype, "moreSectionButtonAction", [action]);
  get moreSectionButtonText() {
    return I18n.t(`sidebar.sections.community.edit_section.${this.navigationMenu.isDesktopDropdownMode ? "header_dropdown" : "sidebar"}`);
  }
  get moreSectionButtonIcon() {
    return "pencil-alt";
  }
}