import { tracked } from "@glimmer/tracking";
import Service from "@ember/service";
export default class AdminCustomUserFields extends Service {
  static #_ = dt7948.g(this.prototype, "additionalProperties", [tracked], function () {
    return [];
  });
  #additionalProperties = (dt7948.i(this, "additionalProperties"), void 0);
  addProperty(property) {
    this.additionalProperties.push(property);
  }
}