/* import __COLOCATED_TEMPLATE__ from './section-form-link.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import discourseLater from "discourse-common/lib/later";
import TEMPLATE from "./section-form-link.hbs";
import { setComponentTemplate } from "@ember/component";
export default class SectionFormLink extends Component {
  static #_ = dt7948.g(this.prototype, "site", [service]);
  #site = (dt7948.i(this, "site"), void 0);
  static #_2 = dt7948.g(this.prototype, "dragCssClass", [tracked]);
  #dragCssClass = (dt7948.i(this, "dragCssClass"), void 0);
  dragCount = 0;
  isAboveElement(event) {
    event.preventDefault();
    const target = event.currentTarget;
    const domRect = target.getBoundingClientRect();
    return event.offsetY < domRect.height / 2;
  }
  dragHasStarted(event) {
    event.dataTransfer.effectAllowed = "move";
    this.args.setDraggedLinkCallback(this.args.link);
    this.dragCssClass = "dragging";
  }
  static #_3 = dt7948.n(this.prototype, "dragHasStarted", [action]);
  dragOver(event) {
    event.preventDefault();
    if (this.dragCssClass !== "dragging") {
      if (this.isAboveElement(event)) {
        this.dragCssClass = "drag-above";
      } else {
        this.dragCssClass = "drag-below";
      }
    }
  }
  static #_4 = dt7948.n(this.prototype, "dragOver", [action]);
  dragEnter() {
    this.dragCount++;
  }
  static #_5 = dt7948.n(this.prototype, "dragEnter", [action]);
  dragLeave() {
    this.dragCount--;
    if (this.dragCount === 0 && (this.dragCssClass === "drag-above" || this.dragCssClass === "drag-below")) {
      discourseLater(() => {
        this.dragCssClass = null;
      }, 10);
    }
  }
  static #_6 = dt7948.n(this.prototype, "dragLeave", [action]);
  dropItem(event) {
    event.stopPropagation();
    this.dragCount = 0;
    this.args.reorderCallback(this.args.link, this.isAboveElement(event));
    this.dragCssClass = null;
  }
  static #_7 = dt7948.n(this.prototype, "dropItem", [action]);
  dragEnd() {
    this.dragCount = 0;
    this.dragCssClass = null;
  }
  static #_8 = dt7948.n(this.prototype, "dragEnd", [action]);
}
setComponentTemplate(TEMPLATE, SectionFormLink);