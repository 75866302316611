/* import __COLOCATED_TEMPLATE__ from './switch-panel-buttons.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { defaultHomepage } from "discourse/lib/utilities";
import getURL from "discourse-common/lib/get-url";
import TEMPLATE from "./switch-panel-buttons.hbs";
import { setComponentTemplate } from "@ember/component";
export default class SwitchPanelButtons extends Component {
  static #_ = dt7948.g(this.prototype, "router", [service]);
  #router = (dt7948.i(this, "router"), void 0);
  static #_2 = dt7948.g(this.prototype, "sidebarState", [service]);
  #sidebarState = (dt7948.i(this, "sidebarState"), void 0);
  static #_3 = dt7948.g(this.prototype, "currentPanel", [tracked]);
  #currentPanel = (dt7948.i(this, "currentPanel"), void 0);
  static #_4 = dt7948.g(this.prototype, "isSwitching", [tracked], function () {
    return false;
  });
  #isSwitching = (dt7948.i(this, "isSwitching"), void 0);
  get destination() {
    if (this.currentPanel) {
      const url = this.currentPanel.switchButtonDefaultUrl || this.currentPanel.lastKnownURL;
      return url === "/" ? `discovery.${defaultHomepage()}` : getURL(url);
    }
    return null;
  }
  async switchPanel(panel) {
    this.isSwitching = true;
    this.currentPanel = panel;
    this.sidebarState.currentPanel.lastKnownURL = this.router.currentURL;
    if (this.destination) {
      try {
        await this.router.transitionTo(this.destination).followRedirects();
        this.sidebarState.setPanel(this.currentPanel.key);
      } catch (e) {
        if (e.name !== "TransitionAborted") {
          throw e;
        }
      } finally {
        this.isSwitching = false;
      }
    }
  }
  static #_5 = dt7948.n(this.prototype, "switchPanel", [action]);
}
setComponentTemplate(TEMPLATE, SwitchPanelButtons);