import Component from "@glimmer/component";
import { array } from "@ember/helper";
import { action } from "@ember/object";
import didInsert from "@ember/render-modifiers/modifiers/did-insert";
import { service } from "@ember/service";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class ModalContainer extends Component {
  static #_ = dt7948.g(this.prototype, "modal", [service]);
  #modal = (dt7948.i(this, "modal"), void 0);
  closeModal(data1) {
    this.modal.close(data1);
  }
  static #_2 = dt7948.n(this.prototype, "closeModal", [action]);
  static #_3 = setComponentTemplate(createTemplateFactory(
  /*
    
      <div
        {{didInsert this.modal.setContainerElement}}
        class="modal-container"
      ></div>
  
      {{#if this.modal.activeModal}}
        {{#each (array this.modal.activeModal) as |activeModal|}}
          {{! #each ensures that the activeModal component/model are updated atomically }}
          <activeModal.component
            @model={{activeModal.opts.model}}
            @closeModal={{this.closeModal}}
          />
        {{/each}}
      {{/if}}
    
  */
  {
    "id": "RMgTCABg",
    "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"modal-container\"],[4,[32,0],[[30,0,[\"modal\",\"setContainerElement\"]]],null],[12],[13],[1,\"\\n\\n\"],[41,[30,0,[\"modal\",\"activeModal\"]],[[[42,[28,[31,2],[[28,[31,2],[[28,[32,1],[[30,0,[\"modal\",\"activeModal\"]]],null]],null]],null],null,[[[1,\"        \"],[8,[30,1,[\"component\"]],null,[[\"@model\",\"@closeModal\"],[[30,1,[\"opts\",\"model\"]],[30,0,[\"closeModal\"]]]],null],[1,\"\\n\"]],[1]],null]],[]],null],[1,\"  \"]],[\"activeModal\"],false,[\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/modal-container.js",
    "scope": () => [didInsert, array],
    "isStrictMode": true
  }), this);
}