/* import __COLOCATED_TEMPLATE__ from './ignore-duration-with-username.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { popupAjaxError } from "discourse/lib/ajax-error";
import { timeShortcuts } from "discourse/lib/time-shortcut";
import User from "discourse/models/user";
import I18n from "discourse-i18n";
import TEMPLATE from "./ignore-duration-with-username.hbs";
import { setComponentTemplate } from "@ember/component";
export default class IgnoreDurationModal extends Component {
  static #_ = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_2 = dt7948.g(this.prototype, "flash", [tracked]);
  #flash = (dt7948.i(this, "flash"), void 0);
  static #_3 = dt7948.g(this.prototype, "loading", [tracked], function () {
    return false;
  });
  #loading = (dt7948.i(this, "loading"), void 0);
  static #_4 = dt7948.g(this.prototype, "ignoredUntil", [tracked], function () {
    return null;
  });
  #ignoredUntil = (dt7948.i(this, "ignoredUntil"), void 0);
  static #_5 = dt7948.g(this.prototype, "ignoredUsername", [tracked], function () {
    return this.args.model.ignoredUsername;
  });
  #ignoredUsername = (dt7948.i(this, "ignoredUsername"), void 0);
  enableSelection = this.args.model.enableSelection ?? true;
  get timeShortcuts() {
    const timezone = this.currentUser.user_option.timezone;
    const shortcuts = timeShortcuts(timezone);
    return [shortcuts.laterToday(), shortcuts.tomorrow(), shortcuts.laterThisWeek(), shortcuts.thisWeekend(), shortcuts.monday(), shortcuts.twoWeeks(), shortcuts.nextMonth(), shortcuts.twoMonths(), shortcuts.threeMonths(), shortcuts.fourMonths(), shortcuts.sixMonths(), shortcuts.oneYear(), shortcuts.forever()];
  }
  ignore() {
    if (!this.ignoredUntil || !this.ignoredUsername) {
      this.flash = I18n.t("user.user_notifications.ignore_duration_time_frame_required");
      return;
    }
    this.loading = true;
    User.findByUsername(this.ignoredUsername).then(user => {
      user.updateNotificationLevel({
        level: "ignore",
        expiringAt: this.ignoredUntil,
        actingUser: this.args.model.actingUser
      }).then(() => {
        this.args.model.onUserIgnored?.(this.ignoredUsername);
        this.args.closeModal();
      }).catch(popupAjaxError).finally(() => this.loading = false);
    });
  }
  static #_6 = dt7948.n(this.prototype, "ignore", [action]);
  updateIgnoredUsername(selected) {
    this.ignoredUsername = selected.firstObject;
  }
  static #_7 = dt7948.n(this.prototype, "updateIgnoredUsername", [action]);
}
setComponentTemplate(TEMPLATE, IgnoreDurationModal);