/* import __COLOCATED_TEMPLATE__ from './highlighted-search.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import highlightSearch from "discourse/lib/highlight-search";
import TEMPLATE from "./highlighted-search.hbs";
import { setComponentTemplate } from "@ember/component";
export default class HighlightedSearch extends Component {
  static #_ = dt7948.g(this.prototype, "search", [service]);
  #search = (dt7948.i(this, "search"), void 0);
  highlight(element) {
    highlightSearch(element, this.search.activeGlobalSearchTerm);
  }
  static #_2 = dt7948.n(this.prototype, "highlight", [action]);
}
setComponentTemplate(TEMPLATE, HighlightedSearch);