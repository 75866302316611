import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import DButton from "discourse/components/d-button";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class UserTipContainer extends Component {
  static #_ = dt7948.g(this.prototype, "userTips", [service]);
  #userTips = (dt7948.i(this, "userTips"), void 0);
  get safeHtmlContent() {
    return htmlSafe(this.args.data.contentHtml);
  }
  get showSkipButton() {
    return this.args.data.showSkipButton;
  }
  handleDismiss(_1, event1) {
    event1.preventDefault();
    this.args.close();
    this.userTips.hideUserTipForever(this.args.data.id);
  }
  static #_2 = dt7948.n(this.prototype, "handleDismiss", [action]);
  handleSkip(_1, event1) {
    event1.preventDefault();
    this.args.close();
    this.userTips.skipTips();
  }
  static #_3 = dt7948.n(this.prototype, "handleSkip", [action]);
  onClick(e1) {
    if (e1.target.nodeName === "A") {
      // Close tip if user clicks on a link in its description
      this.args.close();
    }
  }
  static #_4 = dt7948.n(this.prototype, "onClick", [action]);
  static #_5 = setComponentTemplate(createTemplateFactory(
  /*
    
      <div class="user-tip__container">
        <div class="user-tip__title">{{@data.titleText}}</div>
        {{! template-lint-disable no-invalid-interactive }}
        <div class="user-tip__content" {{on "click" this.onClick}}>
          {{#if @data.contentHtml}}
            {{this.safeHtmlContent}}
          {{else}}
            {{@data.contentText}}
          {{/if}}
        </div>
        <div class="user-tip__buttons">
          <DButton
            class="btn-primary"
            @translatedLabel={{@data.buttonText}}
            @action={{this.handleDismiss}}
            @forwardEvent={{true}}
          />
  
          {{#if this.showSkipButton}}
            <DButton
              class="btn-flat btn-text"
              @translatedLabel={{@data.buttonSkipText}}
              @action={{this.handleSkip}}
              @forwardEvent={{true}}
            />
          {{/if}}
        </div>
      </div>
    
  */
  {
    "id": "L+Hs1ZMu",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"user-tip__container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"user-tip__title\"],[12],[1,[30,1,[\"titleText\"]]],[13],[1,\"\\n\"],[1,\"      \"],[11,0],[24,0,\"user-tip__content\"],[4,[32,0],[\"click\",[30,0,[\"onClick\"]]],null],[12],[1,\"\\n\"],[41,[30,1,[\"contentHtml\"]],[[[1,\"          \"],[1,[30,0,[\"safeHtmlContent\"]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[30,1,[\"contentText\"]]],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"user-tip__buttons\"],[12],[1,\"\\n        \"],[8,[32,1],[[24,0,\"btn-primary\"]],[[\"@translatedLabel\",\"@action\",\"@forwardEvent\"],[[30,1,[\"buttonText\"]],[30,0,[\"handleDismiss\"]],true]],null],[1,\"\\n\\n\"],[41,[30,0,[\"showSkipButton\"]],[[[1,\"          \"],[8,[32,1],[[24,0,\"btn-flat btn-text\"]],[[\"@translatedLabel\",\"@action\",\"@forwardEvent\"],[[30,1,[\"buttonSkipText\"]],[30,0,[\"handleSkip\"]],true]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@data\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/user-tip-container.js",
    "scope": () => [on, DButton],
    "isStrictMode": true
  }), this);
}