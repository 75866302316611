import { tracked } from "@glimmer/tracking";
import BaseTagSectionLink from "discourse/lib/sidebar/user/tags-section/base-tag-section-link";
import { bind } from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default class TagSectionLink extends BaseTagSectionLink {
  static #_ = dt7948.g(this.prototype, "totalUnread", [tracked], function () {
    return 0;
  });
  #totalUnread = (dt7948.i(this, "totalUnread"), void 0);
  static #_2 = dt7948.g(this.prototype, "totalNew", [tracked], function () {
    return 0;
  });
  #totalNew = (dt7948.i(this, "totalNew"), void 0);
  constructor(_ref) {
    let {
      topicTrackingState
    } = _ref;
    super(...arguments);
    this.topicTrackingState = topicTrackingState;
    this.refreshCounts();
  }
  refreshCounts() {
    this.totalUnread = this.topicTrackingState.countUnread({
      tagId: this.tagName
    });
    if (this.totalUnread === 0 || this.#newNewViewEnabled) {
      this.totalNew = this.topicTrackingState.countNew({
        tagId: this.tagName
      });
    }
  }
  static #_3 = dt7948.n(this.prototype, "refreshCounts", [bind]);
  get showCount() {
    return this.currentUser?.sidebarShowCountOfNewItems;
  }
  get models() {
    return [this.tagName];
  }
  get route() {
    if (this.currentUser?.sidebarLinkToFilteredList) {
      if (this.#newNewViewEnabled && this.#unreadAndNewCount > 0) {
        return "tag.showNew";
      } else if (this.totalUnread > 0) {
        return "tag.showUnread";
      } else if (this.totalNew > 0) {
        return "tag.showNew";
      }
    }
    return "tag.show";
  }
  get currentWhen() {
    return "tag.show tag.showNew tag.showUnread tag.showTop";
  }
  get badgeText() {
    if (!this.showCount) {
      return;
    }
    if (this.#newNewViewEnabled && this.#unreadAndNewCount > 0) {
      return this.#unreadAndNewCount.toString();
    } else if (this.totalUnread > 0) {
      return I18n.t("sidebar.unread_count", {
        count: this.totalUnread
      });
    } else if (this.totalNew > 0) {
      return I18n.t("sidebar.new_count", {
        count: this.totalNew
      });
    }
  }
  get suffixCSSClass() {
    return "unread";
  }
  get suffixType() {
    return "icon";
  }
  get suffixValue() {
    if (!this.showCount && (this.totalUnread || this.totalNew)) {
      return "circle";
    }
  }
  get #unreadAndNewCount() {
    return this.totalUnread + this.totalNew;
  }
  get #newNewViewEnabled() {
    return !!this.currentUser?.new_new_view_enabled;
  }
}