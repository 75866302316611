import { next } from "@ember/runloop";
import { service } from "@ember/service";
import { defaultHomepage } from "discourse/lib/utilities";
import StaticPage from "discourse/models/static-page";
import DiscourseRoute from "discourse/routes/discourse";
export default class LoginRoute extends DiscourseRoute {
  static #_ = dt7948.g(this.prototype, "siteSettings", [service]);
  #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
  static #_2 = dt7948.g(this.prototype, "router", [service]);
  #router = (dt7948.i(this, "router"), void 0);
  // `login-page` because `login` controller is the one for
  // the login modal
  controllerName = "login-page";
  beforeModel() {
    if (!this.siteSettings.login_required) {
      this.router.replaceWith(`/${defaultHomepage()}`).followRedirects().then(e => next(() => e.send("showLogin")));
    }
  }
  model() {
    return StaticPage.find("login");
  }
}