/* import __COLOCATED_TEMPLATE__ from './do-not-disturb.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { extractError } from "discourse/lib/ajax-error";
import TEMPLATE from "./do-not-disturb.hbs";
import { setComponentTemplate } from "@ember/component";
export default class DoNotDisturb extends Component {
  static #_ = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_2 = dt7948.g(this.prototype, "router", [service]);
  #router = (dt7948.i(this, "router"), void 0);
  static #_3 = dt7948.g(this.prototype, "flash", [tracked]);
  #flash = (dt7948.i(this, "flash"), void 0);
  async saveDuration(duration) {
    try {
      await this.currentUser.enterDoNotDisturbFor(duration);
      this.args.closeModal();
    } catch (e) {
      this.flash = extractError(e);
    }
  }
  static #_4 = dt7948.n(this.prototype, "saveDuration", [action]);
  navigateToNotificationSchedule() {
    this.router.transitionTo("preferences.notifications", this.currentUser);
    this.args.closeModal();
  }
  static #_5 = dt7948.n(this.prototype, "navigateToNotificationSchedule", [action]);
}
setComponentTemplate(TEMPLATE, DoNotDisturb);