/* import __COLOCATED_TEMPLATE__ from './custom-sections.hbs'; */
import Component from "@glimmer/component";
import { service } from "@ember/service";
import TEMPLATE from "./custom-sections.hbs";
import { setComponentTemplate } from "@ember/component";
export default class SidebarCustomSection extends Component {
  static #_ = dt7948.g(this.prototype, "currentUser", [service]);
  #currentUser = (dt7948.i(this, "currentUser"), void 0);
  static #_2 = dt7948.g(this.prototype, "router", [service]);
  #router = (dt7948.i(this, "router"), void 0);
  static #_3 = dt7948.g(this.prototype, "messageBus", [service]);
  #messageBus = (dt7948.i(this, "messageBus"), void 0);
  static #_4 = dt7948.g(this.prototype, "appEvents", [service]);
  #appEvents = (dt7948.i(this, "appEvents"), void 0);
  static #_5 = dt7948.g(this.prototype, "topicTrackingState", [service]);
  #topicTrackingState = (dt7948.i(this, "topicTrackingState"), void 0);
  static #_6 = dt7948.g(this.prototype, "site", [service]);
  #site = (dt7948.i(this, "site"), void 0);
  static #_7 = dt7948.g(this.prototype, "siteSettings", [service]);
  #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
  anonymous = false;
  get sections() {
    if (this.anonymous) {
      return this.site.anonymous_sidebar_sections;
    } else {
      return this.currentUser.sidebarSections;
    }
  }
}
setComponentTemplate(TEMPLATE, SidebarCustomSection);