import { i18n } from "discourse/lib/computed";
import RestModel from "discourse/models/rest";
import UserAction from "discourse/models/user-action";
import discourseComputed from "discourse-common/utils/decorators";
export default class UserActionStat extends RestModel {
  static #_ = dt7948.g(this.prototype, "description", [i18n("action_type", "user_action_groups.%@")]);
  #description = (dt7948.i(this, "description"), void 0);
  isPM(actionType) {
    return actionType === UserAction.TYPES.messages_sent || actionType === UserAction.TYPES.messages_received;
  }
  static #_2 = dt7948.n(this.prototype, "isPM", [discourseComputed("action_type")]);
  isResponse(actionType) {
    return actionType === UserAction.TYPES.replies || actionType === UserAction.TYPES.quotes;
  }
  static #_3 = dt7948.n(this.prototype, "isResponse", [discourseComputed("action_type")]);
}