/* import __COLOCATED_TEMPLATE__ from './second-factor-form.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { SECOND_FACTOR_METHODS } from "discourse/models/user";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./second-factor-form.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  secondFactorTitle(secondFactorMethod) {
    switch (secondFactorMethod) {
      case SECOND_FACTOR_METHODS.TOTP:
        return I18n.t("login.second_factor_title");
      case SECOND_FACTOR_METHODS.SECURITY_KEY:
        return I18n.t("login.second_factor_title");
      case SECOND_FACTOR_METHODS.BACKUP_CODE:
        return I18n.t("login.second_factor_backup_title");
    }
  },
  secondFactorDescription(secondFactorMethod) {
    switch (secondFactorMethod) {
      case SECOND_FACTOR_METHODS.TOTP:
        return I18n.t("login.second_factor_description");
      case SECOND_FACTOR_METHODS.SECURITY_KEY:
        return I18n.t("login.security_key_description");
      case SECOND_FACTOR_METHODS.BACKUP_CODE:
        return I18n.t("login.second_factor_backup_description");
    }
  },
  linkText(secondFactorMethod, isLogin) {
    if (isLogin) {
      return secondFactorMethod === SECOND_FACTOR_METHODS.TOTP ? "login.second_factor_backup" : "login.second_factor";
    } else {
      return secondFactorMethod === SECOND_FACTOR_METHODS.TOTP ? "user.second_factor_backup.use" : "user.second_factor.use";
    }
  },
  showToggleMethodLink(backupEnabled, secondFactorMethod) {
    return backupEnabled && secondFactorMethod !== SECOND_FACTOR_METHODS.SECURITY_KEY;
  },
  toggleSecondFactorMethod(event) {
    event?.preventDefault();
    const secondFactorMethod = this.secondFactorMethod;
    this.set("secondFactorToken", "");
    if (secondFactorMethod === SECOND_FACTOR_METHODS.TOTP) {
      this.set("secondFactorMethod", SECOND_FACTOR_METHODS.BACKUP_CODE);
    } else {
      this.set("secondFactorMethod", SECOND_FACTOR_METHODS.TOTP);
    }
  }
}, [["method", "secondFactorTitle", [discourseComputed("secondFactorMethod")]], ["method", "secondFactorDescription", [discourseComputed("secondFactorMethod")]], ["method", "linkText", [discourseComputed("secondFactorMethod", "isLogin")]], ["method", "showToggleMethodLink", [discourseComputed("backupEnabled", "secondFactorMethod")]], ["method", "toggleSecondFactorMethod", [action]]])));