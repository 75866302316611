/* import __COLOCATED_TEMPLATE__ from './reviewable-item.hbs'; */
import { getOwner } from "@ember/application";
import Component, { setComponentTemplate } from "@ember/component";
import { action, set } from "@ember/object";
import { service } from "@ember/service";
import { classify, dasherize } from "@ember/string";
import ExplainReviewableModal from "discourse/components/modal/explain-reviewable";
import RejectReasonReviewableModal from "discourse/components/modal/reject-reason-reviewable";
import ReviseAndRejectPostReviewable from "discourse/components/modal/revise-and-reject-post-reviewable";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import optionalService from "discourse/lib/optional-service";
import Category from "discourse/models/category";
import discourseComputed, { bind } from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./reviewable-item.hbs";
let _components = {};
const pluginReviewableParams = {};

// The mappings defined here are default core mappings, and cannot be overridden
// by plugins.
const defaultActionModalClassMap = {
  revise_and_reject_post: ReviseAndRejectPostReviewable
};
const actionModalClassMap = {
  ...defaultActionModalClassMap
};
export function addPluginReviewableParam(reviewableType, param) {
  pluginReviewableParams[reviewableType] ? pluginReviewableParams[reviewableType].push(param) : pluginReviewableParams[reviewableType] = [param];
}
export function registerReviewableActionModal(actionName, modalClass) {
  if (Object.keys(defaultActionModalClassMap).includes(actionName)) {
    throw new Error(`Cannot override default action modal class for ${actionName} (mapped to ${defaultActionModalClassMap[actionName].name})!`);
  }
  actionModalClassMap[actionName] = modalClass;
}
export default setComponentTemplate(TEMPLATE, Component.extend(dt7948.p({
  adminTools: optionalService(),
  dialog: service(),
  modal: service(),
  siteSettings: service(),
  currentUser: service(),
  tagName: "",
  updating: null,
  editing: false,
  _updates: null,
  customClasses(type, lastPerformingUsername, blurEnabled, trustLevel) {
    let classes = dasherize(type);
    if (lastPerformingUsername) {
      classes = `${classes} reviewable-stale`;
    }
    if (blurEnabled && trustLevel === 0) {
      classes = `${classes} blur-images`;
    }
    return classes;
  },
  displayContextQuestion(createdFromFlag, status) {
    return createdFromFlag && status === 0;
  },
  topicId(topic, topicId, removedTopicId) {
    return topic && topic.id || topicId || removedTopicId;
  },
  claimEnabled(claimMode, topicId) {
    return claimMode !== "disabled" && !!topicId;
  },
  canPerform(claimEnabled, claimMode, claimedBy) {
    if (!claimEnabled) {
      return true;
    }
    if (claimedBy) {
      return claimedBy.id === this.currentUser.id;
    }
    return claimMode !== "required";
  },
  claimHelp(claimMode, claimedBy) {
    if (claimedBy) {
      return claimedBy.id === this.currentUser.id ? I18n.t("review.claim_help.claimed_by_you") : I18n.t("review.claim_help.claimed_by_other", {
        username: claimedBy.username
      });
    }
    return claimMode === "optional" ? I18n.t("review.claim_help.optional") : I18n.t("review.claim_help.required");
  },
  reviewableComponent(type) {
    if (_components[type] !== undefined) {
      return _components[type];
    }
    const dasherized = dasherize(type);
    const owner = getOwner(this);
    const componentExists = owner.hasRegistration(`component:${dasherized}`) || owner.hasRegistration(`template:components/${dasherized}`);
    _components[type] = componentExists ? dasherized : null;
    return _components[type];
  },
  tagCategoryId(updatedCategoryId, categoryId) {
    return updatedCategoryId || categoryId;
  },
  _performConfirmed(performableAction) {
    let additionalData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let reviewable = this.reviewable;
    let performAction = () => {
      let version = reviewable.get("version");
      this.set("updating", true);
      const data = {
        send_email: reviewable.sendEmail,
        reject_reason: reviewable.rejectReason,
        ...additionalData
      };
      (pluginReviewableParams[reviewable.type] || []).forEach(param => {
        if (reviewable[param]) {
          data[param] = reviewable[param];
        }
      });
      return ajax(`/review/${reviewable.id}/perform/${performableAction.server_action}?version=${version}`, {
        type: "PUT",
        data
      }).then(result => {
        let performResult = result.reviewable_perform_result;

        // "fast track" to update the current user's reviewable count before the message bus finds out.
        if (performResult.reviewable_count !== undefined) {
          this.currentUser.updateReviewableCount(performResult.reviewable_count);
        }
        if (performResult.unseen_reviewable_count !== undefined) {
          this.currentUser.set("unseen_reviewable_count", performResult.unseen_reviewable_count);
        }
        if (this.remove) {
          this.remove(performResult.remove_reviewable_ids);
        } else {
          return this.store.find("reviewable", reviewable.id);
        }
      }).catch(popupAjaxError).finally(() => this.set("updating", false));
    };
    if (performableAction.client_action) {
      let actionMethod = this[`client${classify(performableAction.client_action)}`];
      if (actionMethod) {
        return actionMethod.call(this, reviewable, performAction);
      } else {
        // eslint-disable-next-line no-console
        console.error(`No handler for ${performableAction.client_action} found`);
        return;
      }
    } else {
      return performAction();
    }
  },
  clientSuspend(reviewable, performAction) {
    this._penalize("showSuspendModal", reviewable, performAction);
  },
  clientSilence(reviewable, performAction) {
    this._penalize("showSilenceModal", reviewable, performAction);
  },
  _penalize(adminToolMethod, reviewable, performAction) {
    let adminTools = this.adminTools;
    if (adminTools) {
      let createdBy = reviewable.get("target_created_by");
      let postId = reviewable.get("post_id");
      let postEdit = reviewable.get("raw");
      return adminTools[adminToolMethod](createdBy, {
        postId,
        postEdit,
        before: performAction
      });
    }
  },
  explainReviewable(reviewable, event) {
    event.preventDefault();
    this.modal.show(ExplainReviewableModal, {
      model: {
        reviewable
      }
    });
  },
  actions: {
    edit() {
      this.set("editing", true);
      this.set("_updates", {
        payload: {}
      });
    },
    cancelEdit() {
      this.set("editing", false);
    },
    saveEdit() {
      let updates = this._updates;

      // Remove empty objects
      Object.keys(updates).forEach(name => {
        let attr = updates[name];
        if (typeof attr === "object" && Object.keys(attr).length === 0) {
          delete updates[name];
        }
      });
      this.set("updating", true);
      return this.reviewable.update(updates).then(() => this.set("editing", false)).catch(popupAjaxError).finally(() => this.set("updating", false));
    },
    categoryChanged(categoryId) {
      let category = Category.findById(categoryId);
      if (!category) {
        category = Category.findUncategorized();
      }
      set(this._updates, "category_id", category.id);
    },
    valueChanged(fieldId, event) {
      set(this._updates, fieldId, event.target.value);
    },
    perform(performableAction) {
      if (this.updating) {
        return;
      }
      const message = performableAction.get("confirm_message");
      const requireRejectReason = performableAction.get("require_reject_reason");
      const actionModalClass = requireRejectReason ? RejectReasonReviewableModal : actionModalClassMap[performableAction.server_action];
      if (message) {
        this.dialog.confirm({
          message,
          didConfirm: () => this._performConfirmed(performableAction)
        });
      } else if (actionModalClass) {
        this.modal.show(actionModalClass, {
          model: {
            reviewable: this.reviewable,
            performConfirmed: this._performConfirmed,
            action: performableAction
          }
        });
      } else {
        return this._performConfirmed(performableAction);
      }
    }
  }
}, [["method", "customClasses", [discourseComputed("reviewable.type", "reviewable.last_performing_username", "siteSettings.blur_tl0_flagged_posts_media", "reviewable.target_created_by_trust_level")]], ["method", "displayContextQuestion", [discourseComputed("reviewable.created_from_flag", "reviewable.status")]], ["method", "topicId", [discourseComputed("reviewable.topic", "reviewable.topic_id", "reviewable.removed_topic_id")]], ["method", "claimEnabled", [discourseComputed("siteSettings.reviewable_claiming", "topicId")]], ["method", "canPerform", [discourseComputed("claimEnabled", "siteSettings.reviewable_claiming", "reviewable.claimed_by")]], ["method", "claimHelp", [discourseComputed("siteSettings.reviewable_claiming", "reviewable.claimed_by")]], ["method", "reviewableComponent", [discourseComputed("reviewable.type")]], ["method", "tagCategoryId", [discourseComputed("_updates.category_id", "reviewable.category.id")]], ["method", "_performConfirmed", [bind]], ["method", "explainReviewable", [action]]])));